/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { HeadlessFileExplorer, } from "@enfusion-ui/core";
import { createTestId } from "@enfusion-ui/utils";
import { AppLogging, styled } from "@enfusion-ui/web-core";
import { faFolder, faFolderOpen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isObject } from "lodash";
import * as React from "react";
import { ErrorAlert } from "../../display/Alert";
import { Spinner } from "../../display/Spinner";
import { Checkbox } from "../../inputs/Checkbox";
import { Accordion } from "../../layout/Accordion";
import { CenterContent } from "../../Styled";
import { FileNode } from "./FileNode";
const CenterContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;
`;
const CheckContainer = styled.div `
  display: inline-block;
  margin-right: var(--spacing);
`;
const FolderError = ({ message }) => (React.createElement(CenterContent, { fillHeight: false },
    React.createElement(ErrorAlert, { error: message })));
const FolderLoading = () => (React.createElement(CenterContainer, { style: { minHeight: 30 } },
    React.createElement(Spinner, { size: "lg", dataTestId: "subfolder-loading-icon" })));
const DirectoryAccordionCore = styled(Accordion) `
  height: 23px;
  font-size: 14px;
  padding: 1px 0px 2px 10px;
  ${({ selected }) => selected ? "background-color: var(--background-accent);" : ""}

  :hover {
    background-color: var(--background-accent);
    opacity: 0.9;
  }
`;
const FileDateText = styled.div `
  margin-left: var(--spacing);
  margin-right: var(--spacing);
  font-size: 12px;
  overflow: hidden;
  width: 100px;
  color: var(--text-muted);
  white-space: nowrap;
  text-align: right;
  font-variant-numeric: tabular-nums lining-nums;
  min-width: 0;
`;
const minHeightAuto = { minHeight: "auto", paddingLeft: 10 };
const ScrollContainer = styled.div `
  width: 100%;
  min-height: 0;
  max-height: 100%;
  overflow: auto;
`;
const MainContentContainer = styled.div `
  min-width: 100%;
  display: flex;
  flex-direction: column;
`;
const MainContentContainerComponent = React.forwardRef(({ children, onContextMenu, className, style }, ref) => (React.createElement(ScrollContainer, { className: className, style: style, ref: ref, onContextMenu: onContextMenu },
    React.createElement(MainContentContainer, null, children))));
function getFolderIcon(open) {
    return open ? (React.createElement(FontAwesomeIcon, { icon: faFolderOpen })) : (React.createElement(FontAwesomeIcon, { icon: faFolder }));
}
const DirectoryAccordion = ({ selected, title, leftContent, rightContent, onClick, onContext, contentComponent, contentContainerStyle, open, defaultOpen, }) => {
    return (React.createElement(DirectoryAccordionCore, { open: open, selected: selected, title: title, icon: getFolderIcon, leftIcon: leftContent, rightContent: rightContent, onClick: onClick, onContext: onContext, contentComponent: contentComponent, contentContainerStyle: contentContainerStyle, defaultOpen: defaultOpen, dataTestId: createTestId("directory-node", title) }));
};
const RightDirectoryContent = ({ node, getFileDate }) => {
    const date = getFileDate?.(node);
    return (React.createElement(React.Fragment, null, date ? (React.createElement(FileDateText, { title: isObject(date) ? date?.title : "" }, isObject(date) ? date.label : date)) : null));
};
const DirectoryCheckbox = ({ checked, onChange, }) => {
    return (React.createElement(CheckContainer, null,
        React.createElement(Checkbox, { noContainer: true, checked: checked, onChange: onChange, iconSize: "1x", iconStyle: {} })));
};
export const FileExplorer = React.forwardRef(function FileExplorer({ style, className, tutorial, getFileIcon, getFileName, getFileDate, getTooltipText, ...props }, ref) {
    const FileNodeProps = React.useMemo(() => ({
        tutorial,
        getFileIcon,
        getFileName,
        getFileDate,
        getTooltipText,
    }), [tutorial, getFileIcon, getFileName, getFileDate, getTooltipText]);
    return (React.createElement(HeadlessFileExplorer, { ...props, ref: ref, AppLogging: AppLogging, MainContentContainer: MainContentContainerComponent, MainContentContainerProps: { style, className }, FileNode: FileNode, FileNodeProps: FileNodeProps, DirectoryAccordion: DirectoryAccordion, DirectoryAccordionProps: { contentContainerStyle: minHeightAuto }, Checkbox: DirectoryCheckbox, RightContent: RightDirectoryContent, RightContentProps: FileNodeProps, FolderError: FolderError, FolderLoadingIndicator: FolderLoading }));
});
