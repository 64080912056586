/* eslint-disable @typescript-eslint/no-explicit-any */
/** Select Options */
import { createSelectOptions } from "@enfusion-ui/utils";
export const INTERNAL_PO_IDS = {
    Enfusion: 64320,
    Demo: 449926,
    DemoAM: 633431,
    QA: 539240,
    QAWebAutomation: 789313,
};
export const INTERNAL_PO_IDS_LIST = Object.values(INTERNAL_PO_IDS);
export const EMPTY_ARRAY = [];
export const EMPTY_RECORD = {};
export const LANDING_REDIRECT_KEY = "es-lrk";
export const FACTSET_LOADED_KEY = "es-fsl";
export const BSSO_TOKEN_KEY = "es-bsso-t";
export const BSSO_REDIRECT_KEY = "es-bsso-r";
export const THEME_KEY = "es-theme";
export const APP_TYPE_COOKIE_NAME = "XAPPTYPE";
export const DASHBOARD_LATEST_CONFIG_VERSION = 2;
export const PORTFOLIO_LATEST_CONFIG_VERSION = 2;
export const SEPARATOR = "|-|";
export const DAYS_UNTIL_PASSWORD_RESET_WARNING = 5;
export var GoogleAnalyticsCustomFields;
(function (GoogleAnalyticsCustomFields) {
    GoogleAnalyticsCustomFields["UserId"] = "dimension1";
    GoogleAnalyticsCustomFields["PO"] = "dimension2";
    GoogleAnalyticsCustomFields["AppId"] = "dimension3";
    GoogleAnalyticsCustomFields["AppVersion"] = "dimension4";
})(GoogleAnalyticsCustomFields || (GoogleAnalyticsCustomFields = {}));
export const UNKNOWN_INSTRUMENT_ID = 1195120;
export const UNKNOWN_INSTRUMENT_SEARCH_RESULT = {
    exchangeId: 2,
    ticker: "_UNKNOWN",
    countryCode: "Unknown",
    quotationFormat: "Price4",
    description: "Unknown",
    exchangeShortname: "Unknown",
    subType: "Equity",
    exchangeName: "Unknown",
    id: 1195120,
    currencyId: 231,
    currencyCode: "USD",
    countryId: 0,
};
export const UNKNOWN_INSTRUMENT_INFO = {
    id: 1195120,
    processingOrgId: 1,
    description: "Unknown",
    financialSubType: {
        description: "Equity",
        parent: "Equity",
    },
    priceDivisor: 1,
    restricted: false,
    quoteType: "Price",
    quotationFormat: "Price4",
    keywords: [
        {
            name: "TICKER",
            value: "_UNKNOWN",
        },
    ],
    tickSize: 0.01,
    lotSize: 0,
    currency: {
        id: 231,
        code: "USD",
        description: "US Dollar",
        deliverable: true,
        crypto: false,
        parentCurrencyId: 0,
        parentConversionFactor: 1,
    },
    exchange: {
        id: 2,
        shortName: "Unknown",
        name: "Unknown",
        country: {
            id: 0,
            code: "Unknown",
        },
        keywords: [
            {
                name: "MARKIT_ENTITY_NAME",
                value: "IOI Corp BHD",
            },
            {
                name: "UK_TAKOVER_OFFEREE",
                value: "true",
            },
            {
                name: "PIABLACKROCK_BROKER_CODE",
                value: "Unspecified",
            },
            {
                name: "REF_ENTITY_RED",
                value: "YC58D9",
            },
            {
                name: "CITCO_BROKER_CODE",
                value: "ZZ-INTRL",
            },
            {
                name: "BARCLAYS_BROKER_CODE",
                value: "Unknown",
            },
            {
                name: "MIC_CODE",
                value: "UNKW",
            },
        ],
        Links: [],
    },
    Links: [],
};
export const cashLadderSourcesOptions = [
    { value: "CashBalance", label: "Cash Balance" },
    { value: "CashActivity", label: "Cash Activity" },
    { value: "PositionCashFlow", label: "Position Cash Flow" },
    { value: "ActiveOrder", label: "Active Order" },
];
export const cashMode = [
    { value: "CashBasis", label: "Settle Date" },
    { value: "AccrualBasis", label: "Trade Date" },
];
export const CashBalanceOptions = [
    { value: "GL", label: "General Ledger" },
    { value: "Position", label: "Position" },
];
export const averageDailyVolumeOptions = createSelectOptions(["5", "10", "30", "60"], undefined, (i) => `Reuters ${i} Days`, (i) => `_${i}Days`);
export const orderParticipationRateUsageOptions = [
    { value: "NoOrder", label: "None" },
    { value: "AllOrders", label: "All Orders" },
    { value: "BuyAndCoverOrders", label: "Buy And Cover Orders Only" },
    { value: "SellShortOrders", label: "Sell Short Orders Only" },
    {
        value: "OrdersWithDefinedRate",
        label: "Only Orders with a Defined Participation Rate",
    },
];
export const orderTransactionTypeOptions = [
    { value: "AllOrder", label: "All Orders" },
    { value: "BuysAndCoversOnly", label: "Buy And Covers Only" },
    { value: "SellsAndShortsOnly", label: "Sell And Short Only" },
];
export const NAVSourceOptions = [
    { label: "General Ledger NAV", value: "GLNAV" },
    { label: "Manual Historical NAV", value: "FundNAV" },
];
