import * as React from "react";
export const LocatesContext = React.createContext({
    locateRows: [],
    enquiryRows: [],
    isConnected: false,
    isSubscribed: false,
});
export const PositionReportContext = React.createContext({
    columnDefs: [],
    rowData: [],
});
export const useLocates = () => {
    const context = React.useContext(LocatesContext);
    if (context === undefined) {
        throw new Error("useLocates must be used within a LocatesProvider");
    }
    return context;
};
export const usePositionReportData = (instrumentId) => {
    const context = React.useContext(PositionReportContext);
    if (context === undefined) {
        throw new Error("usePositionReportData must be used within a PositionReportDataProvider");
    }
    const rowData = React.useMemo(() => {
        if (typeof instrumentId !== "undefined") {
            return context.rowData.filter((e) => e?.positionInstrumentId?.value === instrumentId);
        }
        return context.rowData;
    }, [instrumentId, context.rowData]);
    return { ...context, rowData };
};
