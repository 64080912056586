import { useEverTrue } from "@enfusion-ui/hooks";
import { createTestId } from "@enfusion-ui/utils";
import { css, styled, useIsContentMobile } from "@enfusion-ui/web-core";
import { faAngleDown, faAngleRight, faInfoCircle, } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { IconButton, TextButton } from "../control";
export const FormContainer = styled.div `
  overflow: auto;
  padding: var(--spacing);
  padding-top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const FormContent = styled.div `
  padding: ${({ isMobile }) => isMobile ? "var(--spacing)" : "var(--spacing-l)"};
  max-width: 75rem;
  width: 100%;

  > div {
    margin-bottom: var(--spacing-l);
  }
  > div:last-child {
    margin-bottom: 0;
  }
`;
export const ButtonContainer = styled(FormContent) `
  display: flex;
  width: 100%;
  align-self: center;
  background-color: var(--background-color-0);
  gap: ${({ isMobile }) => isMobile ? "var(--spacing-l)" : "var(--spacing-xl)"};
`;
export const TopAlertContainer = styled.div `
  padding: var(--spacing-l);
  position: sticky;
  top: 0;
  background-color: var(--background-color-2);
  display: grid;
  row-gap: var(--spacing);
  z-index: 1;
`;
export const ContentContainer = styled.div `
  height: 100%;
  background-color: var(--background-color-1-hover);
  padding: var(--spacing-xl);
  border-radius: var(--radius-l);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
`;
export const Title = styled.div `
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
`;
export const AdvancedPanel = styled.div `
  display: flex;
  margin: 10px 0px;
`;
const PanelRowCore = styled.div `
  margin-top: ${({ separateOnMobile }) => separateOnMobile ? "var(--spacing)" : "0px"};
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-wrap: ${({ isMobile }) => (isMobile ? "wrap" : "nowrap")};
  gap: ${({ isMobile }) => (isMobile ? "var(--spacing)" : "var(--spacing-xl)")};

  ${({ grow = false }) => grow
    ? css `
          > div {
            flex: 1;
          }
        `
    : ""}
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PanelRow = React.forwardRef(({ children, ...props }, ref) => {
    const isMobile = useIsContentMobile();
    return (React.createElement(PanelRowCore, { ...props, isMobile: isMobile, ref: ref }, children));
});
export const Panel = styled.div `
  display: flex;
  flex-direction: column;
`;
const CollapsedPanel = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing);
  width: 100%;
  cursor: pointer;
`;
export const PanelTitle = styled.h5 `
  padding: 0 var(--spacing-l) var(--spacing) var(--spacing-l);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const panelContentStyles = css `
  padding: var(--spacing-xl);
  background-color: var(--background-color-0);
  border-radius: var(--radius-l);
`;
export const thinPanelContentStyles = css `
  padding: var(--spacing);
  background-color: var(--background-color-0);
  border-radius: var(--radius);
`;
export const ColumnGrid = styled.div `
  display: grid;
  grid-template-columns: ${({ isMobile, numColumns }) => isMobile ? "auto" : `repeat(${numColumns}, 1fr)`};
  ${({ gapSize = "l" }) => gapSize === "none"
    ? ""
    : css `
          gap: var(--${gapSize === "m" ? "spacing" : `spacing-${gapSize}`});
        `}

  ${({ visible = true }) => visible
    ? css `
          height: auto;
        `
    : css `
          padding: 0px !important;
          height: 0px !important;
          max-height: 0px !important;
          overflow: hidden !important;
        `}
`;
export const ToolTip = styled(IconButton) `
  background: var(--background-color-1);
  padding: 4px;
  position: relative;
  top: -4px;
`;
export const PanelContent = styled(ColumnGrid) `
  ${({ pop, thin }) => pop ? (thin ? thinPanelContentStyles : panelContentStyles) : ""}
`;
const HeaderHorizontalRule = styled.hr `
  border: unset;
  border-top: 1px solid ${({ dividerColor }) => dividerColor};
  flex: 1;
`;
const HeaderText = styled.div `
  line-height: 21px;
  ${({ hrStyle }) => hrStyle ? "padding-right: var(--spacing-xl);" : "flex: 1;"}
`;
export const FormPanel = ({ title = "", popStyle = true, thinContainer = false, children, collapsible = false, defaultOpen = false, lineStyle: lineStyleBase = false, hrStyle = false, hrDividerColor = "var(--background-color-1)", info, defaultRendered = false, keepRendered = false, numColumns = 2, contentStyle, titleStyle, collapsibleContentStyle, gapSize, tabFocusOutline, componentAlwaysShown = null, showTextCollapseButton = false, render, ...props }) => {
    const [open, setOpen] = React.useState(defaultOpen);
    const isMobile = useIsContentMobile();
    const everOpen = useEverTrue(keepRendered ? defaultRendered || open : false);
    const lineStyle = hrStyle || lineStyleBase;
    if (collapsible) {
        return (React.createElement(Panel, { ...props, "data-testid": createTestId(props.dataTestId ? `${props.dataTestId}` : `${title}-e2eId`) },
            title && lineStyle && (React.createElement(PanelTitle, { style: titleStyle, role: "button", onClick: () => setOpen((prev) => !prev) },
                React.createElement(HeaderText, { hrStyle: hrStyle }, title),
                hrStyle && React.createElement(HeaderHorizontalRule, { dividerColor: hrDividerColor }),
                isMobile || !showTextCollapseButton ? (React.createElement(IconButton, { tabFocusOutline: tabFocusOutline, icon: open ? faAngleDown : faAngleRight, style: {
                        height: "21px",
                        padding: "0px 8px",
                        marginRight: "-8px",
                    } })) : (React.createElement(TextButton, { hoverEffect: false, labelPlacement: "right", defaultColor: "var(--text-color)", icon: open ? faAngleDown : faAngleRight, style: {
                        height: "21px",
                        padding: "0px 8px",
                        marginRight: "-8px",
                    } }, open ? "Show Less" : "Show More")))),
            React.createElement(PanelContent, { visible: (lineStyle && open) ||
                    (lineStyle && !!componentAlwaysShown) ||
                    !lineStyle, numColumns: 1, pop: popStyle, thin: thinContainer, style: contentStyle, isMobile: isMobile, gapSize: open ? gapSize : "none" },
                title && !lineStyle && (React.createElement(CollapsedPanel, { role: "button", onClick: () => setOpen((prev) => !prev) },
                    React.createElement(PanelTitle, { style: { padding: 0 } }, title),
                    React.createElement(IconButton, { icon: open ? faAngleDown : faAngleRight }))),
                componentAlwaysShown,
                (keepRendered && everOpen) || (!keepRendered && open) ? (React.createElement(ColumnGrid, { visible: open, style: collapsibleContentStyle, isMobile: isMobile, numColumns: numColumns },
                    render?.(),
                    children)) : null)));
    }
    return (React.createElement(Panel, { ...props },
        React.createElement(PanelRow, null,
            title && React.createElement(PanelTitle, null,
                title,
                " "),
            info && React.createElement(ToolTip, { title: info, icon: faInfoCircle })),
        React.createElement(PanelContent, { style: contentStyle, numColumns: numColumns, pop: popStyle, gapSize: gapSize, isMobile: isMobile }, children)));
};
export const SingleRowFormPanel = ({ children, ...rest }) => {
    return (React.createElement(FormPanel, { ...rest },
        React.createElement(PanelRow, null, children)));
};
