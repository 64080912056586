/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit, pick } from "lodash";
import * as React from "react";
import { useFormContext, } from "react-hook-form";
const keys = ["name", "rules", "onFocus", "defaultValue", "control"];
export function useControllerProps(props, thirdResArgs = []) {
    return React.useMemo(() => [
        pick(props, [...keys]),
        omit(props, [...keys, ...thirdResArgs]),
        pick(props, [...thirdResArgs]),
    ], [props, keys, thirdResArgs]);
}
const FIELD_REQ_ERROR_MESSAGE = "Field Required";
export function useFormInputValidation(args) {
    const { errors } = useFormContext();
    return React.useMemo(() => {
        const rules = args.rules || {};
        let required = rules.required || args.required || false;
        if (required === true)
            required = FIELD_REQ_ERROR_MESSAGE;
        return { rules: { ...rules, required }, errors };
    }, [args.rules, args.required, errors]);
}
