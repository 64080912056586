import * as React from "react";
import { calculateGridSizing } from "../core/utils";
import { useGridDimensions } from "./useGridDimensions";
export const useGridSizing = (tabWidth, tabHeight, scrollBarWidth, editMode, mainGridProps, numOfCols) => {
    const mainGridDimensions = useGridDimensions(mainGridProps);
    return React.useMemo(() => ({
        ...calculateGridSizing(mainGridDimensions, tabWidth, tabHeight, editMode, editMode ? numOfCols : null, scrollBarWidth),
        dimensions: {
            main: mainGridDimensions,
        },
    }), [tabWidth, tabHeight, mainGridDimensions, editMode, numOfCols]);
};
