import { debounce } from "lodash";
import * as React from "react";
import { useRefCallback } from "./useRefCallback";
// simple mouse over handling
export function useMouseOverSimple(debounceDelay = 50, handleEnter, handleLeave) {
    const [over, setOver] = React.useState(false);
    const handleSetOver = useRefCallback(debounce((newOver) => {
        setOver(newOver);
        if (newOver)
            handleEnter?.();
        else
            handleLeave?.();
    }, debounceDelay), [handleEnter, handleLeave]);
    const onMouseEnter = React.useCallback(() => {
        handleSetOver(true);
    }, [handleSetOver]);
    const onMouseLeave = React.useCallback(() => {
        handleSetOver(false);
    }, [handleSetOver]);
    return { onMouseEnter, onMouseLeave, over };
}
