// name alpha sort
export const oemsColumnMeta = {
    CommittedAmtBookCurrency: {
        key: "CommittedAmtBookCurrency",
        name: "$ Committed Amount",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: true,
        category: "Order",
    },
    NotionalFilledAmountBookCurrency: {
        key: "NotionalFilledAmountBookCurrency",
        name: "$ Notional Filled Amount",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    NotionalFilledAmountAvgPxBookCurrency: {
        key: "NotionalFilledAmountAvgPxBookCurrency",
        name: "$ Notional Filled Amt - Avg Px",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    NotionalTradeAmountBookCurrency: {
        key: "NotionalTradeAmountBookCurrency",
        name: "$ Notional Trade Amount",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    NotionalUnfilledAmountBookCurrency: {
        key: "NotionalUnfilledAmountBookCurrency",
        name: "$ Notional Unfilled Amount",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    SODNotionalTradeAmtBookCcy: {
        key: "SODNotionalTradeAmtBookCcy",
        name: "$ SOD Notional Trade Amount (Global)",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    TodayNotionalFilledAmtBookCcy: {
        key: "TodayNotionalFilledAmtBookCcy",
        name: "$ Today Notional Filled Amount (Global)",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    _20DAvgVol: {
        key: "_20DAvgVol",
        name: "20D Avg Vol",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    _30DAvgVol: {
        key: "_30DAvgVol",
        name: "30D Avg Vol",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    _3MAvgVol: {
        key: "_3MAvgVol",
        name: "3M Avg Vol",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    _5DAvgVol: {
        key: "_5DAvgVol",
        name: "5D Avg Vol",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    AccountNumber: {
        key: "AccountNumber",
        name: "Account Number",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Allocations",
    },
    _Control_: {
        key: "_Control_",
        name: "Actions",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    AllocationInstructions: {
        key: "AllocationInstructions",
        name: "Alloc Instr",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Allocations",
    },
    AllocationReason: {
        key: "AllocationReason",
        name: "Allocation Reason",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    ShortLocateAmountApproved: {
        key: "ShortLocateAmountApproved",
        name: "Apr SL Qty",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Order",
    },
    ArrivalPctChange: {
        key: "ArrivalPctChange",
        name: "Arrival % Change",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Order",
    },
    ArrivalPrice: {
        key: "ArrivalPrice",
        name: "Arrival Price",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Order",
    },
    ArrivalVWAP: {
        key: "ArrivalVWAP",
        name: "Arrival VWAP",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Order",
    },
    Ask: {
        key: "Ask",
        name: "Ask",
        columnTypeCategory: "Quotation",
        sideSensitive: false,
        category: "Related Market Data",
    },
    AssignedCpty: {
        key: "AssignedCpty",
        name: "Assigned Cpty",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    AssignedSLID: {
        key: "AssignedSLID",
        name: "Assigned SL ID",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    ShortLocateAmountAssigned: {
        key: "ShortLocateAmountAssigned",
        name: "Assigned SL Qty",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Order",
    },
    AuthorizedBy: {
        key: "AuthorizedBy",
        name: "Authorized By",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    AvgPx: {
        key: "AvgPx",
        name: "Avg Px",
        columnTypeCategory: "AveragePrice",
        sideSensitive: false,
        category: "Fill Info",
    },
    BBFutureRoot: {
        key: "BBFutureRoot",
        name: "BB Future Root",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    BBTicker: {
        key: "BBTicker",
        name: "BB Ticker",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    Bid: {
        key: "Bid",
        name: "Bid",
        columnTypeCategory: "Quotation",
        sideSensitive: false,
        category: "Related Market Data",
    },
    BidAskDelta: {
        key: "BidAskDelta",
        name: "Bid/Ask Delta",
        columnTypeCategory: "SignSensitiveNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    BilateralCleared: {
        key: "BilateralCleared",
        name: "Bilateral/Cleared",
        columnTypeCategory: "Enum",
        sideSensitive: false,
        category: "Order",
    },
    BookCS01: {
        key: "BookCS01",
        name: "Book CS01",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Order",
    },
    BookDV01: {
        key: "BookDV01",
        name: "Book DV01",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Order",
    },
    BookName: {
        key: "BookName",
        name: "Book Name",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Allocations",
    },
    Broker: {
        key: "Broker",
        name: "Broker",
        columnTypeCategory: "Standard",
        sideSensitive: true,
        category: "Execution Route",
    },
    BrokerAccount: {
        key: "BrokerAccount",
        name: "Broker Account",
        columnTypeCategory: "Standard",
        sideSensitive: true,
        category: "Execution Route",
    },
    CCP: {
        key: "CCP",
        name: "CCP",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    CalculatorType: {
        key: "CalculatorType",
        name: "Calculator Type",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    Cap: {
        key: "Cap",
        name: "Cap?",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Security Master",
    },
    Capacity: {
        key: "Capacity",
        name: "Capacity",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    CashLadderParticipationRate: {
        key: "CashLadderParticipationRate",
        name: "Cash Ladder Participation Rate",
        columnTypeCategory: "Percent",
        sideSensitive: false,
        category: "Order",
    },
    Ccy: {
        key: "Ccy",
        name: "Ccy",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    Change: {
        key: "Change",
        name: "Change",
        columnTypeCategory: "SignSensitiveNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    ChangePct: {
        key: "ChangePct",
        name: "Change(%)",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    ChangesToAcknowledge: {
        key: "ChangesToAcknowledge",
        name: "Changes to Ack",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    ClearingAccount: {
        key: "ClearingAccount",
        name: "Clearing Account",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Allocations",
    },
    CommissionRate: {
        key: "CommissionRate",
        name: "Comm Rate",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    CommissionRateType: {
        key: "CommissionRateType",
        name: "Comm Rate Type",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Proceeds",
    },
    CommissionTotal: {
        key: "CommissionTotal",
        name: "Comm Total",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    Committed: {
        key: "Committed",
        name: "Committed",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Order",
    },
    CommittedPct: {
        key: "CommittedPct",
        name: "Committed %",
        columnTypeCategory: "PercentFilled",
        sideSensitive: false,
        category: "Order",
    },
    CommittedAmt: {
        key: "CommittedAmt",
        name: "Committed Amount",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: true,
        category: "Order",
    },
    completed: {
        key: "completed",
        name: "Completed",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    CompletedDate: {
        key: "CompletedDate",
        name: "Completed Dt",
        columnTypeCategory: "DateTime",
        sideSensitive: false,
        category: "Order",
    },
    CompletionStatus: {
        key: "CompletionStatus",
        name: "Completion Status",
        columnTypeCategory: "Enum",
        sideSensitive: false,
        category: "Order",
    },
    Compliance: {
        key: "Compliance",
        name: "Compliance",
        columnTypeCategory: "Compliance",
        sideSensitive: false,
        category: "Order",
    },
    ComplianceNotes: {
        key: "ComplianceNotes",
        name: "Compliance Notes",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    ContraCcy: {
        key: "ContraCcy",
        name: "Contra Ccy",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    Contract: {
        key: "Contract",
        name: "Contract",
        columnTypeCategory: "Standard",
        sideSensitive: true,
        category: "Security Master",
    },
    ContractSize: {
        key: "ContractSize",
        name: "Contract Size",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Security Master",
    },
    Counterparty: {
        key: "Counterparty",
        name: "Counterparty",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Execution Route",
    },
    CounterpartyMemo: {
        key: "CounterpartyMemo",
        name: "Cpty Memo",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Execution Route",
    },
    CreationPercentChange: {
        key: "CreationPercentChange",
        name: "Creation % Change",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Order",
    },
    CreationDate: {
        key: "CreationDate",
        name: "Creation Date",
        columnTypeCategory: "DateTime",
        sideSensitive: false,
        category: "Order",
    },
    OrderCreationMethod: {
        key: "OrderCreationMethod",
        name: "Creation Method",
        columnTypeCategory: "Enum",
        sideSensitive: false,
        category: "Order",
    },
    CreationPrice: {
        key: "CreationPrice",
        name: "Creation Price",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Order",
    },
    CreationSlippage: {
        key: "CreationSlippage",
        name: "Creation Slippage",
        columnTypeCategory: "SignSensitivePrice",
        sideSensitive: false,
        category: "Order",
    },
    OrderCreationUser: {
        key: "OrderCreationUser",
        name: "Creation User",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    CreditDirection: {
        key: "CreditDirection",
        name: "Credit Direction",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    DayParticipationRate: {
        key: "DayParticipationRate",
        name: "Day Participation Rate",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    DayParticipationShares: {
        key: "DayParticipationShares",
        name: "Day Participation Shares",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    DealId: {
        key: "DealId",
        name: "Deal Id",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Allocations",
    },
    DealSpread: {
        key: "DealSpread",
        name: "Deal Spread",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Order",
    },
    DealTerms: {
        key: "DealTerms",
        name: "Deal Terms",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Program Trading",
    },
    Delta: {
        key: "Delta",
        name: "Delta",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Related Market Data",
    },
    DeltaAdjExposure: {
        key: "DeltaAdjExposure",
        name: "Delta Adjusted Exposure",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Order",
    },
    Destination: {
        key: "Destination",
        name: "Destination",
        columnTypeCategory: "Standard",
        sideSensitive: true,
        category: "Execution Route",
    },
    DirectedBrokerageOrder: {
        key: "DirectedBrokerageOrder",
        name: "Directed Brokerage Order",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    EffectiveDate: {
        key: "EffectiveDate",
        name: "Effective Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Security Master",
    },
    Proceeds: {
        key: "Proceeds",
        name: "Estimated Proceeds",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    ExchangeCountryCode: {
        key: "ExchangeCountryCode",
        name: "Exch Country Code",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    ExchangeCountryName: {
        key: "ExchangeCountryName",
        name: "Exch Country Name",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    Exchange: {
        key: "Exchange",
        name: "Exchange",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    ExchangeMIC: {
        key: "ExchangeMIC",
        name: "Exchange MIC Code",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    ExecVal_NAV: {
        key: "ExecVal_NAV",
        name: "Exec Val / NAV",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Proceeds",
    },
    ExecutedExposureBPS: {
        key: "ExecutedExposureBPS",
        name: "Executed Exposure (BPS)",
        columnTypeCategory: "SignSensitiveNumber",
        sideSensitive: false,
        category: "Order",
    },
    Exposure: {
        key: "Exposure",
        name: "Exposure",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Position",
    },
    ExposureChange: {
        key: "ExposureChange",
        name: "Exposure Change",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Position",
    },
    FCM: {
        key: "FCM",
        name: "FCM",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    FIXOrderId: {
        key: "FIXOrderId",
        name: "FIX Order Id",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Execution Route",
    },
    SettleToBookFXRate: {
        key: "SettleToBookFXRate",
        name: "FX Rate",
        columnTypeCategory: "Quotation",
        sideSensitive: false,
        category: "Related Market Data",
    },
    FarAmount: {
        key: "FarAmount",
        name: "Far Amount",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Order",
    },
    FXFarFixingDate: {
        key: "FXFarFixingDate",
        name: "Far Fixing Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Security Master",
    },
    FXFarForwardDate: {
        key: "FXFarForwardDate",
        name: "Far Forward Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Security Master",
    },
    FillPriceDeviation: {
        key: "FillPriceDeviation",
        name: "Fill Px % Deviation",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    Filled: {
        key: "Filled",
        name: "Filled",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Fill Info",
    },
    FilledPctOf30D_ADV: {
        key: "FilledPctOf30D_ADV",
        name: "Filled  % of 30D ADV",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    FilledPct: {
        key: "FilledPct",
        name: "Filled %",
        columnTypeCategory: "PercentFilled",
        sideSensitive: false,
        category: "Fill Info",
    },
    FilledPctOf3M_ADV: {
        key: "FilledPctOf3M_ADV",
        name: "Filled % of 3M ADV",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    FirstCounterparty: {
        key: "FirstCounterparty",
        name: "First Counterparty",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    FirstFilledDate: {
        key: "FirstFilledDate",
        name: "First Filled Dt",
        columnTypeCategory: "DateTime",
        sideSensitive: false,
        category: "Order",
    },
    FirstTransmittedDate: {
        key: "FirstTransmittedDate",
        name: "First Transmit Dt",
        columnTypeCategory: "DateTime",
        sideSensitive: false,
        category: "Order",
    },
    FixedRate: {
        key: "FixedRate",
        name: "Fixed Rate",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    FXFixingDate: {
        key: "FXFixingDate",
        name: "Fixing Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Security Master",
    },
    ForwardDate: {
        key: "ForwardDate",
        name: "Forward Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Order",
    },
    FullyAdjustedPrevLastPx: {
        key: "FullyAdjustedPrevLastPx",
        name: "Fully Adj Prev Last Px",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Related Market Data",
    },
    FundFullName: {
        key: "FundFullName",
        name: "Fund Full Name",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    FundName: {
        key: "FundName",
        name: "Fund Name",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Allocations",
    },
    GICGroup: {
        key: "GICGroup",
        name: "GIC Group",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    GICIndustry: {
        key: "GICIndustry",
        name: "GIC Industry",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    GICSector: {
        key: "GICSector",
        name: "GIC Sector",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    GICSubIndustry: {
        key: "GICSubIndustry",
        name: "GIC Sub Industry",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    ISIN: {
        key: "ISIN",
        name: "ISIN",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Security Master",
    },
    Idle: {
        key: "Idle",
        name: "Idle",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Order",
    },
    ImpactVWAP: {
        key: "ImpactVWAP",
        name: "Impact VWAP",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Related Market Data",
    },
    FilledPctOfImpactVWAPShares: {
        key: "FilledPctOfImpactVWAPShares",
        name: "Impact VWAP %",
        columnTypeCategory: "Percent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    ImpactVWAPAvgPxDelta: {
        key: "ImpactVWAPAvgPxDelta",
        name: "Impact VWAP / Avg Px Delta",
        columnTypeCategory: "SignSensitiveNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    ImpactVWAPShares: {
        key: "ImpactVWAPShares",
        name: "Impact VWAP Shares",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    Instructions: {
        key: "Instructions",
        name: "Instructions",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    InstrumentId: {
        key: "InstrumentId",
        name: "Instrument Id",
        columnTypeCategory: "IdNumber",
        sideSensitive: false,
        category: "Security Master",
    },
    InstrumentSubtype: {
        key: "InstrumentSubtype",
        name: "Instrument Subtype",
        columnTypeCategory: "Enum",
        sideSensitive: false,
        category: "Security Master",
    },
    InstrumentType: {
        key: "InstrumentType",
        name: "Instrument Type",
        columnTypeCategory: "Enum",
        sideSensitive: false,
        category: "Security Master",
    },
    IsActiveParent: {
        key: "IsActiveParent",
        name: "Is Active Parent",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    IsCurrentlyScheduled: {
        key: "IsCurrentlyScheduled",
        name: "Is Currently Scheduled",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    isExecutionRoute: {
        key: "isExecutionRoute",
        name: "Is Execution Route",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    IsForceCancel: {
        key: "IsForceCancel",
        name: "Is Force Cancel",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    IsStale: {
        key: "IsStale",
        name: "Is Stale",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    Undecided: {
        key: "Undecided",
        name: "Is Undecided",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    IsUnsolicitedCancel: {
        key: "IsUnsolicitedCancel",
        name: "Is Unsolicited Cancel",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    Issuer: {
        key: "Issuer",
        name: "Issuer",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    Last: {
        key: "Last",
        name: "Last",
        columnTypeCategory: "Quotation",
        sideSensitive: false,
        category: "Related Market Data",
    },
    LastFillPx: {
        key: "LastFillPx",
        name: "Last Fill Px",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Fill Info",
    },
    LastFillQty: {
        key: "LastFillQty",
        name: "Last Fill Quantity",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Fill Info",
    },
    LastFilledDate: {
        key: "LastFilledDate",
        name: "Last Filled Dt",
        columnTypeCategory: "DateTime",
        sideSensitive: false,
        category: "Order",
    },
    LastModifiedAt: {
        key: "LastModifiedAt",
        name: "Last Modified At",
        columnTypeCategory: "DateTime",
        sideSensitive: false,
        category: "Order",
    },
    LastUpdateUser: {
        key: "LastUpdateUser",
        name: "Last Update User",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    Limit: {
        key: "Limit",
        name: "Limit",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Order",
    },
    ListAlias: {
        key: "ListAlias",
        name: "List Alias",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Program Trading",
    },
    ListId: {
        key: "ListId",
        name: "List Id",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Program Trading",
    },
    ListSeqNo: {
        key: "ListSeqNo",
        name: "List Seq No",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Program Trading",
    },
    ListTotalNo: {
        key: "ListTotalNo",
        name: "List Total No",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Program Trading",
    },
    MarketLimitDelta: {
        key: "MarketLimitDelta",
        name: "Market/Limit Delta",
        columnTypeCategory: "SignSensitiveNumber",
        sideSensitive: false,
        category: "Order",
    },
    MarketLimitDeltaPct: {
        key: "MarketLimitDeltaPct",
        name: "Market/Limit Delta %",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Order",
    },
    MaturityDate: {
        key: "MaturityDate",
        name: "Maturity Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Security Master",
    },
    MaxDarkPoolQuantity: {
        key: "MaxDarkPoolQuantity",
        name: "Max Dark Qty",
        columnTypeCategory: "BuySellQuantity",
        sideSensitive: false,
        category: "Execution Route",
    },
    Method: {
        key: "Method",
        name: "Method",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    NDF: {
        key: "NDF",
        name: "NDF",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    NPV: {
        key: "NPV",
        name: "NPV",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Order",
    },
    NativeCS01: {
        key: "NativeCS01",
        name: "Native CS01",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Order",
    },
    NativeDV01: {
        key: "NativeDV01",
        name: "Native DV01",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Order",
    },
    NearAmount: {
        key: "NearAmount",
        name: "Near Amount",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Order",
    },
    NotionalFilledAmount: {
        key: "NotionalFilledAmount",
        name: "Notional Filled Amount",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    NotionalFilledAmountAvgPx: {
        key: "NotionalFilledAmountAvgPx",
        name: "Notional Filled Amt - Avg Px",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    NotionalTradeAmount: {
        key: "NotionalTradeAmount",
        name: "Notional Trade Amount",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    NotionalTradeAmountNtnlCurrency: {
        key: "NotionalTradeAmountNtnlCurrency",
        name: "Notional Trade Amount - Ntnl Ccy",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: true,
        category: "Proceeds",
    },
    NotionalUnfilledAmount: {
        key: "NotionalUnfilledAmount",
        name: "Notional Unfilled Amount",
        columnTypeCategory: "SignedIntegerQuantity",
        sideSensitive: false,
        category: "Proceeds",
    },
    OfferingType: {
        key: "OfferingType",
        name: "Offering Type",
        columnTypeCategory: "Enum",
        sideSensitive: false,
        category: "Order",
    },
    Open: {
        key: "Open",
        name: "Open",
        columnTypeCategory: "BuySellQuantity",
        sideSensitive: false,
        category: "Order",
    },
    OpenPctOf30D_ADV: {
        key: "OpenPctOf30D_ADV",
        name: "Open % of 30D ADV",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    OpenPx: {
        key: "OpenPx",
        name: "Open Px",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Related Market Data",
    },
    OpenVal_NAV: {
        key: "OpenVal_NAV",
        name: "Open Val / NAV",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Proceeds",
    },
    OrderPctOf30D_ADV: {
        key: "OrderPctOf30D_ADV",
        name: "Order % of 30D ADV",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    OrderPctOf3M_ADV: {
        key: "OrderPctOf3M_ADV",
        name: "Order % of 3M ADV",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    OrderDate: {
        key: "OrderDate",
        name: "Order Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Order",
    },
    OrderDays: {
        key: "OrderDays",
        name: "Order Days",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Order",
    },
    OrderId: {
        key: "OrderId",
        name: "Order Id",
        columnTypeCategory: "IdNumber",
        sideSensitive: false,
        category: "Order",
    },
    OrderLifecycleStatus: {
        key: "OrderLifecycleStatus",
        name: "Order Lifecycle Status",
        columnTypeCategory: "Enum",
        sideSensitive: false,
        category: "Order",
    },
    OrderVal_NAV: {
        key: "OrderVal_NAV",
        name: "Order Val / NAV",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Proceeds",
    },
    PBDExposure: {
        key: "PBDExposure",
        name: "PBD Exposure",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Position",
    },
    PBDFilledQty: {
        key: "PBDFilledQty",
        name: "PBD Filled Qty",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Fill Info",
    },
    PBDFilledQtyGlobal: {
        key: "PBDFilledQtyGlobal",
        name: "PBD Filled Qty (Global)",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Fill Info",
    },
    PackageId: {
        key: "PackageId",
        name: "Package Id",
        columnTypeCategory: "IdNumber",
        sideSensitive: false,
        category: "Order",
    },
    PackageIdentifier: {
        key: "PackageIdentifier",
        name: "Package Identifier",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    PackageName: {
        key: "PackageName",
        name: "Package Name",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    PackageSize: {
        key: "PackageSize",
        name: "Package Size",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Order",
    },
    PairsIsAutoAddNameToPositionBlock: {
        key: "PairsIsAutoAddNameToPositionBlock",
        name: "Pairs Auto Add Name To Position Block",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Pairs",
    },
    PairsId: {
        key: "PairsId",
        name: "Pairs Id",
        columnTypeCategory: "IdNumber",
        sideSensitive: false,
        category: "Pairs",
    },
    PairsIdentifier: {
        key: "PairsIdentifier",
        name: "Pairs Identifier",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Pairs",
    },
    PairsIsLongShortPair: {
        key: "PairsIsLongShortPair",
        name: "Pairs LongShort Pair",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Pairs",
    },
    PairsIsMaintainRatio: {
        key: "PairsIsMaintainRatio",
        name: "Pairs Maintain Ratio",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Pairs",
    },
    PairsName: {
        key: "PairsName",
        name: "Pairs Name",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Pairs",
    },
    PairsOrderDate: {
        key: "PairsOrderDate",
        name: "Pairs Order Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Pairs",
    },
    PairsSpreadLimit: {
        key: "PairsSpreadLimit",
        name: "Pairs Spread Limit",
        columnTypeCategory: "SignSensitiveNumber",
        sideSensitive: false,
        category: "Pairs",
    },
    ParentOrderId: {
        key: "ParentOrderId",
        name: "Parent Order Id",
        columnTypeCategory: "IdNumber",
        sideSensitive: false,
        category: "Order",
    },
    PayLegInfo: {
        key: "PayLegInfo",
        name: "Pay Leg Info",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    Placed: {
        key: "Placed",
        name: "Placed",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    PortfolioManager: {
        key: "PortfolioManager",
        name: "Portfolio Manager",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Allocations",
    },
    Position: {
        key: "Position",
        name: "Position",
        columnTypeCategory: "SignSensitiveNumber",
        sideSensitive: false,
        category: "Position",
    },
    PositionPercentNAV: {
        key: "PositionPercentNAV",
        name: "Position % of NAV",
        columnTypeCategory: "Percent",
        sideSensitive: false,
        category: "Order",
    },
    PositionFirmwide: {
        key: "PositionFirmwide",
        name: "Position - Firmwide",
        columnTypeCategory: "SignSensitiveNumber",
        sideSensitive: false,
        category: "Position",
    },
    PositionBlock: {
        key: "PositionBlock",
        name: "Position Block",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Allocations",
    },
    PreviousClosePx: {
        key: "PreviousClosePx",
        name: "Prev Close Px",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Related Market Data",
    },
    PrimaryBroker: {
        key: "PrimaryBroker",
        name: "Primary Broker",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    ProgramId: {
        key: "ProgramId",
        name: "Program Id",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Program Trading",
    },
    ProgramName: {
        key: "ProgramName",
        name: "Program Name",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Program Trading",
    },
    ProgramSeqNo: {
        key: "ProgramSeqNo",
        name: "Program Seq No",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Program Trading",
    },
    ProgramTotalNo: {
        key: "ProgramTotalNo",
        name: "Program Total No",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Program Trading",
    },
    Quantity: {
        key: "Quantity",
        name: "Quantity",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Order",
    },
    QuotingConvention: {
        key: "QuotingConvention",
        name: "Quoting Convention",
        columnTypeCategory: "Enum",
        sideSensitive: false,
        category: "Order",
    },
    RIC: {
        key: "RIC",
        name: "RIC",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    Reconciled: {
        key: "Reconciled",
        name: "Reconciled",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    RecvLegInfo: {
        key: "RecvLegInfo",
        name: "Recv Leg Info",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    ReferenceInstrumentDescription: {
        key: "ReferenceInstrumentDescription",
        name: "Reference Instrument Description",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    ReferenceInstrumentPrice: {
        key: "ReferenceInstrumentPrice",
        name: "Reference Instrument Price",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    ReferenceInstrumentTicker: {
        key: "ReferenceInstrumentTicker",
        name: "Reference Instrument Ticker",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    ReleaseDateTime: {
        key: "ReleaseDateTime",
        name: "Release Date/Time",
        columnTypeCategory: "DateTime",
        sideSensitive: false,
        category: "Order",
    },
    RemainingVolumeOf5DAvg: {
        key: "RemainingVolumeOf5DAvg",
        name: "Remaining Vol of 5D Avg",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    ShortLocateAmountRequested: {
        key: "ShortLocateAmountRequested",
        name: "Req SL Qty",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Order",
    },
    ResultingPercentNAV: {
        key: "ResultingPercentNAV",
        name: "Resulting % NAV",
        columnTypeCategory: "Percent",
        sideSensitive: false,
        category: "Order",
    },
    Resumed: {
        key: "Resumed",
        name: "Resumed",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    SODOpenQuantity: {
        key: "SODOpenQuantity",
        name: "SOD Open Quantity",
        columnTypeCategory: "BuySellQuantity",
        sideSensitive: false,
        category: "Order",
    },
    SODOpenQuantityGlobal: {
        key: "SODOpenQuantityGlobal",
        name: "SOD Open Quantity (Global)",
        columnTypeCategory: "BuySellQuantity",
        sideSensitive: false,
        category: "Order",
    },
    DarkPools: {
        key: "DarkPools",
        name: "Selected Dark Pools",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Execution Route",
    },
    SettleCcy: {
        key: "SettleCcy",
        name: "Settle Ccy",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    SettleDate: {
        key: "SettleDate",
        name: "Settle Dt",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Order",
    },
    ShortInterest: {
        key: "ShortInterest",
        name: "Short Interest",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Related Market Data",
    },
    ShortInterestPctEquityFloat: {
        key: "ShortInterestPctEquityFloat",
        name: "Short Interest % Equity Float",
        columnTypeCategory: "Percent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    SpreadLimit: {
        key: "SpreadLimit",
        name: "Spread Limit",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Program Trading",
    },
    SpreadRatio: {
        key: "SpreadRatio",
        name: "Spread Ratio",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Program Trading",
    },
    StartDate: {
        key: "StartDate",
        name: "Start Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "Order",
    },
    Status: {
        key: "Status",
        name: "Status",
        columnTypeCategory: "Enum",
        sideSensitive: true,
        category: "Order",
    },
    StatusText: {
        key: "StatusText",
        name: "Status Text",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    Stop: {
        key: "Stop",
        name: "Stop",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Order",
    },
    Strategy: {
        key: "Strategy",
        name: "Strategy",
        columnTypeCategory: "Standard",
        sideSensitive: true,
        category: "Execution Route",
    },
    StrategyBook: {
        key: "StrategyBook",
        name: "Strategy Book",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    Strike: {
        key: "Strike",
        name: "Strike",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Security Master",
    },
    SubmissionStatus: {
        key: "SubmissionStatus",
        name: "Submission Status",
        columnTypeCategory: "Enum",
        sideSensitive: true,
        category: "Order",
    },
    SwapOrder: {
        key: "SwapOrder",
        name: "Swap Order",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    SwapType: {
        key: "SwapType",
        name: "Swap Type",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    TIF: {
        key: "TIF",
        name: "TIF",
        columnTypeCategory: "Enum",
        sideSensitive: true,
        category: "Order",
    },
    TRSId: {
        key: "TRSId",
        name: "TRS Id",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Allocations",
    },
    TRSOverridesBenchmarkBBYELLOW: {
        key: "TRSOverridesBenchmarkBBYELLOW",
        name: "TRS Overrides Benchmark BBYELLOW",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesBenchmarkId: {
        key: "TRSOverridesBenchmarkId",
        name: "TRS Overrides Benchmark Id",
        columnTypeCategory: "IdNumber",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesBenchmarkTicker: {
        key: "TRSOverridesBenchmarkTicker",
        name: "TRS Overrides Benchmark Ticker",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesEndDate: {
        key: "TRSOverridesEndDate",
        name: "TRS Overrides End Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesPayLegFirstResetDate: {
        key: "TRSOverridesPayLegFirstResetDate",
        name: "TRS Overrides Pay Leg First Reset Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesPayLegLastResetDate: {
        key: "TRSOverridesPayLegLastResetDate",
        name: "TRS Overrides Pay Leg Last Reset Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesPayLegResetFrequency: {
        key: "TRSOverridesPayLegResetFrequency",
        name: "TRS Overrides Pay Leg Reset Frequency",
        columnTypeCategory: "Enum",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesRate: {
        key: "TRSOverridesRate",
        name: "TRS Overrides Rate",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesRateEffectiveDate: {
        key: "TRSOverridesRateEffectiveDate",
        name: "TRS Overrides Rate Effective Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesRecLegFirstResetDate: {
        key: "TRSOverridesRecLegFirstResetDate",
        name: "TRS Overrides Rec Leg First Reset Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesRecLegLastResetDate: {
        key: "TRSOverridesRecLegLastResetDate",
        name: "TRS Overrides Rec Leg Last Reset Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    TRSOverridesStartDate: {
        key: "TRSOverridesStartDate",
        name: "TRS Overrides Start Date",
        columnTypeCategory: "Date",
        sideSensitive: false,
        category: "TRS Overrides",
    },
    Tenor: {
        key: "Tenor",
        name: "Tenor",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    Ticker: {
        key: "Ticker",
        name: "Ticker",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    TodayAvgPx: {
        key: "TodayAvgPx",
        name: "Today Avg Px",
        columnTypeCategory: "AveragePrice",
        sideSensitive: false,
        category: "Fill Info",
    },
    TodayAvgPxGlobal: {
        key: "TodayAvgPxGlobal",
        name: "Today Avg Px (Global)",
        columnTypeCategory: "AveragePrice",
        sideSensitive: false,
        category: "Fill Info",
    },
    TodayCommitted: {
        key: "TodayCommitted",
        name: "Today Committed",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Order",
    },
    TodayCommittedGlobal: {
        key: "TodayCommittedGlobal",
        name: "Today Committed (Global)",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Order",
    },
    TodayFilledPct: {
        key: "TodayFilledPct",
        name: "Today Filled %",
        columnTypeCategory: "PercentFilled",
        sideSensitive: false,
        category: "Fill Info",
    },
    TodayFilledQty: {
        key: "TodayFilledQty",
        name: "Today Filled Qty",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Fill Info",
    },
    TodayFilledQtyGlobal: {
        key: "TodayFilledQtyGlobal",
        name: "Today Filled Qty (Global)",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Fill Info",
    },
    TodayFilledQtyPctNav: {
        key: "TodayFilledQtyPctNav",
        name: "Today Filled Qty as % NAV",
        columnTypeCategory: "Percent",
        sideSensitive: false,
        category: "Fill Info",
    },
    TradeReason: {
        key: "TradeReason",
        name: "Trade Reason",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    TradeSpread: {
        key: "TradeSpread",
        name: "Trade Spread",
        columnTypeCategory: "SignedQuantity",
        sideSensitive: false,
        category: "Order",
    },
    Trader: {
        key: "Trader",
        name: "Trader",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    TraderDiscretion: {
        key: "TraderDiscretion",
        name: "Trader Discretion",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    TradingDesk: {
        key: "TradingDesk",
        name: "Trading Desk",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    Txn: {
        key: "Txn",
        name: "Txn",
        columnTypeCategory: "Standard",
        sideSensitive: true,
        category: "Order",
    },
    Type: {
        key: "Type",
        name: "Type",
        columnTypeCategory: "Enum",
        sideSensitive: true,
        category: "Order",
    },
    Underlier: {
        key: "Underlier",
        name: "Underlier",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Security Master",
    },
    UnderlyingAsk: {
        key: "UnderlyingAsk",
        name: "Underlying Ask",
        columnTypeCategory: "Quotation",
        sideSensitive: false,
        category: "Related Market Data",
    },
    UnderlyingBid: {
        key: "UnderlyingBid",
        name: "Underlying Bid",
        columnTypeCategory: "Quotation",
        sideSensitive: false,
        category: "Related Market Data",
    },
    UnderlyingLast: {
        key: "UnderlyingLast",
        name: "Underlying Last",
        columnTypeCategory: "Quotation",
        sideSensitive: false,
        category: "Related Market Data",
    },
    UnderlyingNotionalAmt: {
        key: "UnderlyingNotionalAmt",
        name: "Underlying Notional Amt",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Order",
    },
    UndisclosedShort: {
        key: "UndisclosedShort",
        name: "Undisclosed Short",
        columnTypeCategory: "Boolean",
        sideSensitive: false,
        category: "Order",
    },
    UnFilledPct: {
        key: "UnFilledPct",
        name: "Unfilled %",
        columnTypeCategory: "PercentFilled",
        sideSensitive: false,
        category: "Fill Info",
    },
    UnfilledPctOf3M_ADV: {
        key: "UnfilledPctOf3M_ADV",
        name: "Unfilled % of 3M ADV",
        columnTypeCategory: "SignSensitivePercent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    UnfilledPercentNAV: {
        key: "UnfilledPercentNAV",
        name: "Unfilled % of NAV",
        columnTypeCategory: "Percent",
        sideSensitive: false,
        category: "Order",
    },
    UnfilledQty: {
        key: "UnfilledQty",
        name: "Unfilled Qty",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Fill Info",
    },
    VWAP: {
        key: "VWAP",
        name: "VWAP",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Related Market Data",
    },
    FilledPctOfVWAPShares: {
        key: "FilledPctOfVWAPShares",
        name: "VWAP %",
        columnTypeCategory: "Percent",
        sideSensitive: false,
        category: "Related Market Data",
    },
    VWAPShares: {
        key: "VWAPShares",
        name: "VWAP Shares",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    VarianceUnits: {
        key: "VarianceUnits",
        name: "Variance Units",
        columnTypeCategory: "MarketPrice",
        sideSensitive: false,
        category: "Related Market Data",
    },
    Volume: {
        key: "Volume",
        name: "Volume",
        columnTypeCategory: "IntegerNumber",
        sideSensitive: false,
        category: "Related Market Data",
    },
    WorkflowState: {
        key: "WorkflowState",
        name: "Workflow State",
        columnTypeCategory: "Standard",
        sideSensitive: false,
        category: "Order",
    },
    Working: {
        key: "Working",
        name: "Working",
        columnTypeCategory: "Number",
        sideSensitive: false,
        category: "Order",
    },
};
export const oemsBlotterColumns = [
    {
        f: "_Control_",
        n: "Actions",
        t: "standardColumn",
        w: 150,
        c: "Other",
        suppressHeaderMenuButton: true,
        sortable: false,
        filter: false,
        pinned: "right",
        p: "Contains the icons that are used to cancel (red X) and transmit (beacon) individual orders",
    },
    {
        f: "completed",
        n: "Completed",
        t: "standardColumn",
        c: "Order",
        hide: true,
        lockVisible: true,
    },
    {
        f: "columnValues.CommittedAmtBookCurrency",
        n: "$ Committed Amount",
        t: "numericColumn",
        c: "Order",
        p: "Committed amount of an order, based on the last price and the order's committed quantity with the prevailing FX rate to account currency",
    },
    {
        f: "columnValues.NotionalFilledAmountBookCurrency",
        n: "$ Notional Filled Amount",
        t: "numericColumn",
        c: "Proceeds",
        p: "Notional value of the filled shares for this order, in book currency.",
    },
    {
        f: "columnValues.NotionalFilledAmountAvgPxBookCurrency",
        n: "$ Notional Filled Amt - Avg Px",
        t: "numericColumn",
        c: "Proceeds",
        p: "Notional value of the filled shares for this order in book currency, using avg Px",
    },
    {
        f: "columnValues.NotionalTradeAmountBookCurrency",
        n: "$ Notional Trade Amount",
        t: "numericColumn",
        c: "Proceeds",
        p: "Notional amount of the order, in book currency",
    },
    {
        f: "columnValues.NotionalUnfilledAmountBookCurrency",
        n: "$ Notional Unfilled Amount",
        t: "numericColumn",
        c: "Proceeds",
        p: "Notional value of the unfilled shares for this order, in book currency",
    },
    {
        f: "columnValues.SODNotionalTradeAmtBookCcy",
        n: "$ SOD Notional Trade Amount (Global)",
        t: "numericColumn",
        c: "Proceeds",
        p: "Notional value of open shares at of the start of the trading day in book currency, adjusted for market environment time zone",
    },
    {
        f: "columnValues.TodayNotionalFilledAmtBookCcy",
        n: "$ Today Notional Filled Amount (Global)",
        t: "numericColumn",
        c: "Proceeds",
        p: "Notional value of today's filled shares for this order in book currency, adjusted for market environment time zone",
    },
    {
        f: "columnValues._20DAvgVol",
        n: "20D Avg Vol",
        t: "numericColumn",
        w: 110,
        c: "Related Market Data",
        p: "20-day average trading volume",
    },
    {
        f: "columnValues._30DAvgVol",
        n: "30D Avg Vol",
        t: "numericColumn",
        w: 110,
        c: "Related Market Data",
        p: "Security's moving average trading volume over the last 30 days",
    },
    {
        f: "columnValues._3MAvgVol",
        n: "3M Avg Vol",
        t: "numericColumn",
        w: 105,
        c: "Related Market Data",
        p: "Security's moving average trading volume over the last 3 months",
    },
    {
        f: "columnValues._5DAvgVol",
        n: "5D Avg Vol",
        t: "numericColumn",
        w: 104,
        c: "Related Market Data",
        p: "Security's moving average trading volume over the last 5 days",
    },
    {
        f: "columnValues.AccountNumber",
        n: "Account Number",
        t: "standardColumn",
        c: "Allocations",
        p: "Trade custodian account number",
    },
    {
        f: "columnValues.AllocationInstructions",
        n: "Alloc Instr",
        t: "standardColumn",
        w: 197,
        c: "Allocations",
        p: "Strings entered in order ticket's Allocation Instructions freeform text field",
    },
    {
        f: "columnValues.AllocationReason",
        n: "Allocation Reason",
        t: "standardColumn",
        w: 142,
        c: "Order",
        p: "Allocation reason assigned in the General tab of the order",
    },
    {
        f: "columnValues.ShortLocateAmountApproved",
        n: "Apr SL Qty",
        t: "numericColumn",
        w: 102,
        c: "Order",
        p: "Total approved Sell Short locate quantity for an instrument on the order",
    },
    {
        f: "columnValues.ArrivalPctChange",
        n: "Arrival % Change",
        t: "numericColumn",
        w: 137,
        c: "Order",
        p: "% change between the current market price to the market price when the order was first transmitted",
    },
    {
        f: "columnValues.ArrivalPrice",
        n: "Arrival Price",
        t: "numericColumn",
        w: 110,
        c: "Order",
        p: "Market price when an order is transmitted",
    },
    {
        f: "columnValues.ArrivalVWAP",
        n: "Arrival VWAP",
        t: "numericColumn",
        w: 117,
        c: "Order",
        p: "VWAP at the time of order/route transmission",
    },
    {
        f: "columnValues.Ask",
        n: "Ask",
        t: "numericColumn",
        w: 105,
        c: "Related Market Data",
        p: "Difference in percentage between the last price and the previous day's closing price of the security",
    },
    {
        f: "columnValues.AssignedCpty",
        n: "Assigned Cpty",
        t: "standardColumn",
        c: "Order",
        p: "Counterparty assigned to the order via counterparty matrix",
    },
    {
        f: "columnValues.AssignedSLID",
        n: "Assigned SL ID",
        t: "standardColumn",
        c: "Order",
        p: "Unique identifier of assigned short locates (SL ID) stored within the Borrow Locate Id(s) for the order",
    },
    {
        f: "columnValues.ShortLocateAmountAssigned",
        n: "Assigned SL Qty",
        t: "numericColumn",
        w: 132,
        c: "Order",
        p: "Assigned quantity of the locate attached to a Sell Short order",
    },
    {
        f: "columnValues.AuthorizedBy",
        n: "Authorized By",
        t: "standardColumn",
        c: "Order",
        p: "User who updated workflow state to Reviewed",
    },
    {
        f: "columnValues.AvgPx",
        n: "Avg Px",
        t: "numericColumn",
        w: 81,
        c: "Fill Info",
        p: "Average price of all fills of the order.",
    },
    {
        f: "columnValues.BBFutureRoot",
        n: "BB Future Root",
        t: "standardColumn",
        c: "Security Master",
        p: "BB ROOT for the future contract",
    },
    {
        f: "columnValues.BBTicker",
        n: "BB Ticker",
        t: "standardColumn",
        w: 135,
        c: "Security Master",
        p: "Bloomberg Yellow Key identifier for the security (e.g., IBM US Equity)",
    },
    {
        f: "columnValues.Bid",
        n: "Bid",
        t: "numericColumn",
        w: 105,
        c: "Related Market Data",
        p: "Current bid price of the security",
    },
    {
        f: "columnValues.BidAskDelta",
        n: "Bid/Ask Delta",
        t: "numericColumn",
        w: 118,
        c: "Related Market Data",
        p: "Difference between an order's limit price and the Bid/Ask",
    },
    {
        f: "columnValues.BilateralCleared",
        n: "Bilateral/Cleared",
        t: "standardColumn",
        c: "Order",
        p: "Clearing Type for IRS/CDX contract",
    },
    {
        f: "columnValues.BookCS01",
        n: "Book CS01",
        t: "numericColumn",
        c: "Order",
        p: "CS01 exposure in Book currency",
    },
    {
        f: "columnValues.BookDV01",
        n: "Book DV01",
        t: "numericColumn",
        c: "Order",
        p: "DV01 exposure in Book currency",
    },
    {
        f: "columnValues.BookName",
        n: "Book Name",
        t: "standardColumn",
        w: 107,
        c: "Allocations",
        p: "Book that the order is allocated to. Will show ...' for multiple books'",
    },
    {
        f: "columnValues.Broker",
        n: "Broker",
        t: "standardColumn",
        w: 183,
        c: "Execution Route",
        p: "Target designated on the order ticket for electronic order to be executed",
    },
    {
        f: "columnValues.BrokerAccount",
        n: "Broker Account",
        t: "standardColumn",
        w: 128,
        c: "Execution Route",
        p: "Informational field that a broker may require to correspond to an execution account on their end",
    },
    {
        f: "columnValues.CCP",
        n: "CCP",
        t: "standardColumn",
        c: "Order",
        p: "Central counterparty clearing",
    },
    {
        f: "columnValues.CalculatorType",
        n: "Calculator Type",
        t: "standardColumn",
        w: 158,
        c: "Order",
        p: "Calculator type selected to generate the order (e.g., Final Quantity, Incremental Quantity)",
    },
    {
        f: "columnValues.Cap",
        n: "Cap?",
        t: "standardColumn",
        c: "Security Master",
        p: "Indicates if upper or lower cap is present for the variance swap",
    },
    {
        f: "columnValues.Capacity",
        n: "Capacity",
        t: "standardColumn",
        c: "Order",
        p: "Capacity of the electronic order",
    },
    {
        f: "columnValues.CashLadderParticipationRate",
        n: "Cash Ladder Participation Rate",
        t: "numericColumn",
        c: "Order",
        p: "Cash Ladder Participation Rate to be used in Cash Ladder Reporting",
    },
    {
        f: "columnValues.Ccy",
        n: "Ccy",
        t: "standardColumn",
        w: 68,
        c: "Security Master",
        p: "Trading currency of the order instrument",
    },
    {
        f: "columnValues.Change",
        n: "Change",
        t: "numericColumn",
        w: 86,
        c: "Related Market Data",
        p: "Difference between the last price and the previous day's closing price of the security",
    },
    {
        f: "columnValues.ChangePct",
        n: "Change(%)",
        t: "numericColumn",
        w: 104,
        c: "Related Market Data",
        p: "Difference between the last price and the previous day's closing price of the security",
    },
    {
        f: "columnValues.ChangesToAcknowledge",
        n: "Changes to Ack",
        t: "standardColumn",
        w: 128,
        c: "Order",
        p: "Displays true or false depending on whether or not the trader assigned to the order has new changes to acknowledge",
    },
    {
        f: "columnValues.ClearingAccount",
        n: "Clearing Account",
        t: "standardColumn",
        w: 175,
        c: "Allocations",
        p: "Account that the order is allocated to. Will show ...' for multiple allocated accounts'",
    },
    {
        f: "columnValues.CommissionRate",
        n: "Comm Rate",
        t: "numericColumn",
        w: 108,
        c: "Proceeds",
        p: "Estimated commission rate of the counterparty selected on the order",
    },
    {
        f: "columnValues.CommissionRateType",
        n: "Comm Rate Type",
        t: "standardColumn",
        w: 137,
        c: "Proceeds",
        p: "Type of commission that the Comm Rate applies to",
    },
    {
        f: "columnValues.CommissionTotal",
        n: "Comm Total",
        t: "numericColumn",
        w: 110,
        c: "Proceeds",
        p: "Total estimated commissions to be paid",
    },
    {
        f: "columnValues.Committed",
        n: "Committed",
        t: "numericColumn",
        w: 106,
        c: "Order",
        p: "Number of shares that have been committed to a specified broker or route",
    },
    {
        f: "columnValues.CommittedPct",
        n: "Committed %",
        t: "numericColumn",
        c: "Order",
        p: "Committed % of an order based on the quantity of the order's committed quantity",
    },
    {
        f: "columnValues.CommittedAmt",
        n: "Committed Amount",
        t: "numericColumn",
        c: "Order",
        p: "Committed amount of an order based on the last price and the order's committed quantity",
    },
    {
        f: "columnValues.CompletedDate",
        n: "Completed Dt",
        t: "dateColumn",
        w: 175,
        c: "Order",
        p: "Date and time that order was completed",
    },
    {
        f: "columnValues.CompletionStatus",
        n: "Completion Status",
        t: "standardColumn",
        w: 145,
        c: "Order",
        p: "Displays New, Partial, or Done, depending on the quantity filled on the order",
    },
    {
        f: "columnValues.Compliance",
        n: "Compliance",
        t: "standardColumn",
        w: 122,
        c: "Order",
        p: "Compliance state of the order",
    },
    {
        f: "columnValues.ComplianceNotes",
        n: "Compliance Notes",
        t: "standardColumn",
        c: "Order",
        p: "Compliance note from the order ticket",
    },
    {
        f: "columnValues.ContraCcy",
        n: "Contra Ccy",
        t: "standardColumn",
        c: "Security Master",
        p: "Non-notional currency of a currency pair",
    },
    {
        f: "columnValues.Contract",
        n: "Contract",
        t: "standardColumn",
        w: 244,
        c: "Security Master",
        p: "Full description of the security being traded",
    },
    {
        f: "columnValues.ContractSize",
        n: "Contract Size",
        t: "numericColumn",
        c: "Security Master",
        p: "Contract size for the derivative",
    },
    {
        f: "columnValues.Counterparty",
        n: "Counterparty",
        t: "standardColumn",
        w: 117,
        c: "Execution Route",
        p: "Executing Counterparty designated on the order",
    },
    {
        f: "columnValues.CounterpartyMemo",
        n: "Cpty Memo",
        t: "standardColumn",
        w: 107,
        c: "Execution Route",
        p: "Executing Counterparty Memo designated on the order",
    },
    {
        f: "columnValues.CreationPercentChange",
        n: "Creation % Change",
        t: "numericColumn",
        c: "Order",
        p: "Difference in percentage between and order's creation price and the last price of the security",
    },
    {
        f: "columnValues.CreationDate",
        n: "Creation Date",
        t: "dateColumn",
        c: "Order",
        p: "Order creation date and time",
    },
    {
        f: "columnValues.OrderCreationMethod",
        n: "Creation Method",
        t: "standardColumn",
        w: 200,
        c: "Order",
        p: "Creation method of the order (e.g., New Order, Split Order, Systematic API)",
    },
    {
        f: "columnValues.CreationPrice",
        n: "Creation Price",
        t: "numericColumn",
        w: 121,
        c: "Order",
        p: "Last price when the order is first saved",
    },
    {
        f: "columnValues.CreationSlippage",
        n: "Creation Slippage",
        t: "numericColumn",
        c: "Order",
        p: "Difference between and order's creation price and the last price of the security",
    },
    {
        f: "columnValues.OrderCreationUser",
        n: "Creation User",
        t: "standardColumn",
        c: "Order",
        p: "Order Creation User",
    },
    {
        f: "columnValues.CreditDirection",
        n: "Credit Direction",
        t: "standardColumn",
        c: "Order",
        p: "Risk direction for CDS/CDX orders",
    },
    {
        f: "columnValues.DayParticipationRate",
        n: "Day Participation Rate",
        t: "numericColumn",
        c: "Related Market Data",
        p: "Rate of participation calculated by today's filled quantity divided by the Day Participation Shares",
    },
    {
        f: "columnValues.DayParticipationShares",
        n: "Day Participation Shares",
        t: "numericColumn",
        c: "Related Market Data",
        p: "Amount of shares that participated within the market today for security, between the time of first transmission to the last execution report received",
    },
    {
        f: "columnValues.DealId",
        n: "Deal Id",
        t: "standardColumn",
        c: "Allocations",
        p: "Deal Id designated on the allocation(s) on the order",
    },
    {
        f: "columnValues.DealSpread",
        n: "Deal Spread",
        t: "numericColumn",
        c: "Order",
        p: "Deal spread (in BPS) for the CDX instrument",
    },
    {
        f: "columnValues.DealTerms",
        n: "Deal Terms",
        t: "standardColumn",
        c: "Program Trading",
        p: "Deal Terms for grouped orders",
    },
    {
        f: "columnValues.Delta",
        n: "Delta",
        t: "numericColumn",
        c: "Related Market Data",
        p: "Delta of the derivative",
    },
    {
        f: "columnValues.DeltaAdjExposure",
        n: "Delta Adjusted Exposure",
        t: "numericColumn",
        c: "Order",
        p: "Delta Adjusted Exposure of the order",
    },
    {
        f: "columnValues.Destination",
        n: "Destination",
        t: "standardColumn",
        w: 212,
        c: "Execution Route",
        p: "Destination where the electronic order will be routed",
    },
    {
        f: "columnValues.DirectedBrokerageOrder",
        n: "Directed Brokerage Order",
        t: "standardColumn",
        c: "Order",
        p: 'Displays "True" or "False" according to whether or not the order is an electronic order created automatically by the Directed Brokerage workflow',
    },
    {
        f: "columnValues.EffectiveDate",
        n: "Effective Date",
        t: "dateColumn",
        c: "Security Master",
        p: "Date that variance swap is effective on the contract",
    },
    {
        f: "columnValues.Proceeds",
        n: "Estimated Proceeds",
        t: "numericColumn",
        w: 153,
        c: "Proceeds",
        p: "Proceeds estimated on the order upon acquisition or sale of the asset",
    },
    {
        f: "columnValues.ExchangeCountryCode",
        n: "Exch Country Code",
        t: "standardColumn",
        w: 147,
        c: "Security Master",
        p: "Exchange country code of the security",
    },
    {
        f: "columnValues.ExchangeCountryName",
        n: "Exch Country Name",
        t: "standardColumn",
        w: 151,
        c: "Security Master",
        p: "Exchange country name of the security",
    },
    {
        f: "columnValues.Exchange",
        n: "Exchange",
        t: "standardColumn",
        w: 140,
        c: "Security Master",
        p: "Exchange the security is listed on",
    },
    {
        f: "columnValues.ExchangeMIC",
        n: "Exchange MIC Code",
        t: "standardColumn",
        c: "Security Master",
        p: "Market Identifier Code (MIC) of the exchange",
    },
    {
        f: "columnValues.ExecVal_NAV",
        n: "Exec Val / NAV",
        t: "numericColumn",
        w: 123,
        c: "Proceeds",
        p: "$ Notional Filled Amount divided by NAV",
    },
    {
        f: "columnValues.ExecutedExposureBPS",
        n: "Executed Exposure (BPS)",
        t: "numericColumn",
        w: 181,
        c: "Order",
        p: "Order's executed value divided by the order's NAV represented in BPS",
    },
    {
        f: "columnValues.Exposure",
        n: "Exposure",
        t: "numericColumn",
        w: 95,
        c: "Position",
        p: "Percentage of NAV exposure for the current position held on the instrument specified in the order",
    },
    {
        f: "columnValues.ExposureChange",
        n: "Exposure Change",
        t: "numericColumn",
        w: 139,
        c: "Position",
        p: "Change in exposure on that instrument since the previous business day",
    },
    {
        f: "columnValues.FCM",
        n: "FCM",
        t: "standardColumn",
        c: "Order",
        p: "Futures commission merchant",
    },
    {
        f: "columnValues.FIXOrderId",
        n: "FIX Order Id",
        t: "standardColumn",
        w: 220,
        c: "Execution Route",
        p: "Enfusion Order ID transmitted on the FIX message (i.e., the unique identifier for each order). This is typically for Enfusion's internal use only",
    },
    {
        f: "columnValues.SettleToBookFXRate",
        n: "FX Rate",
        t: "numericColumn",
        w: 87,
        c: "Related Market Data",
        p: "FX rate from the Instrument settlement currency to the Fund currency",
    },
    {
        f: "columnValues.FarAmount",
        n: "Far Amount",
        t: "numericColumn",
        c: "Order",
        p: "Quantity of far leg of FX swap order",
    },
    {
        f: "columnValues.FXFarFixingDate",
        n: "Far Fixing Date",
        t: "dateColumn",
        w: 125,
        c: "Security Master",
        p: "Far fixing date set on the FX Swap",
    },
    {
        f: "columnValues.FXFarForwardDate",
        n: "Far Forward Date",
        t: "dateColumn",
        w: 138,
        c: "Security Master",
        p: "Far forward date set on the FX Swap",
    },
    {
        f: "columnValues.FillPriceDeviation",
        n: "Fill Px % Deviation",
        t: "numericColumn",
        w: 145,
        c: "Related Market Data",
        p: "Deviation of Avg Px from Last",
    },
    {
        f: "columnValues.Filled",
        n: "Filled",
        t: "numericColumn",
        w: 81,
        c: "Fill Info",
        p: "Filled quantity of the order",
    },
    {
        f: "columnValues.FilledPctOf30D_ADV",
        n: "Filled  % of 30D ADV",
        t: "numericColumn",
        w: 154,
        c: "Related Market Data",
        p: "Order's filled quantity as a percentage of the 30D Avg Vol",
    },
    {
        f: "columnValues.FilledPct",
        n: "Filled %",
        t: "numericColumn",
        w: 87,
        c: "Fill Info",
        p: "Percentage of the order that has been filled",
    },
    {
        f: "columnValues.FilledPctOf3M_ADV",
        n: "Filled % of 3M ADV",
        t: "numericColumn",
        w: 149,
        c: "Related Market Data",
        p: "Order's filled quantity as a percentage of the 3M Avg Vol",
    },
    {
        f: "columnValues.FirstCounterparty",
        n: "First Counterparty",
        t: "standardColumn",
        c: "Order",
        p: "First counterparty of the existing position for order",
    },
    {
        f: "columnValues.FirstFilledDate",
        n: "First Filled Dt",
        t: "dateColumn",
        w: 181,
        c: "Order",
        p: "Timestamp of the first fill message received for the order",
    },
    {
        f: "columnValues.FirstTransmittedDate",
        n: "First Transmit Dt",
        t: "dateColumn",
        w: 181,
        c: "Order",
        p: "Date and time that order was first transmitted",
    },
    {
        f: "columnValues.FixedRate",
        n: "Fixed Rate",
        t: "standardColumn",
        c: "Order",
        p: "Fixed Rate of the IRS order",
    },
    {
        f: "columnValues.FXFixingDate",
        n: "Fixing Date",
        t: "dateColumn",
        w: 106,
        c: "Security Master",
        p: "Fixing date set on the FX order",
    },
    {
        f: "columnValues.ForwardDate",
        n: "Forward Date",
        t: "dateColumn",
        c: "Order",
        p: "Forward Date/Spot Date for the FX order",
    },
    {
        f: "columnValues.FullyAdjustedPrevLastPx",
        n: "Fully Adj Prev Last Px",
        t: "numericColumn",
        c: "Related Market Data",
        p: "Last Price adjusted for cash dividends",
    },
    {
        f: "columnValues.FundFullName",
        n: "Fund Full Name",
        t: "standardColumn",
        c: "Order",
        p: "Full name of fund that the order is allocated to",
    },
    {
        f: "columnValues.FundName",
        n: "Fund Name",
        t: "standardColumn",
        w: 106,
        c: "Allocations",
        p: "Fund that the order is allocated to. Will show ...' for multiple allocated funds'",
    },
    {
        f: "columnValues.GICGroup",
        n: "GIC Group",
        t: "standardColumn",
        c: "Security Master",
        p: "Group of the Global Industry Classification the instrument belongs to",
    },
    {
        f: "columnValues.GICIndustry",
        n: "GIC Industry",
        t: "standardColumn",
        c: "Security Master",
        p: "Industry of the Global Industry Classification the instrument belongs to",
    },
    {
        f: "columnValues.GICSector",
        n: "GIC Sector",
        t: "standardColumn",
        c: "Security Master",
        p: "Sector of the Global Industry Classification the instrument belongs to",
    },
    {
        f: "columnValues.GICSubIndustry",
        n: "GIC Sub Industry",
        t: "standardColumn",
        c: "Security Master",
        p: "Sub-industry of the Global Industry Classification the instrument belongs to",
    },
    {
        f: "columnValues.ISIN",
        n: "ISIN",
        t: "standardColumn",
        w: 133,
        c: "Security Master",
        p: "ISIN of the instrument on the order",
    },
    {
        f: "columnValues.Idle",
        n: "Idle",
        t: "numericColumn",
        w: 81,
        c: "Order",
        p: "For Parent (Undecided) orders, this is the number of shares that are currently not routed out on a child order (i.e., they are available to be routed)",
    },
    {
        f: "columnValues.ImpactVWAP",
        n: "Impact VWAP",
        t: "numericColumn",
        w: 119,
        c: "Related Market Data",
        p: "VWAP from the time the order was first transmitted",
    },
    {
        f: "columnValues.FilledPctOfImpactVWAPShares",
        n: "Impact VWAP %",
        t: "numericColumn",
        w: 132,
        c: "Related Market Data",
        p: "Orders filled quantity as a percent of Impact VWAP Shares",
    },
    {
        f: "columnValues.ImpactVWAPAvgPxDelta",
        n: "Impact VWAP / Avg Px Delta",
        t: "numericColumn",
        w: 198,
        c: "Related Market Data",
        p: "Impact VWAP as a fraction of the Average Price Delta",
    },
    {
        f: "columnValues.ImpactVWAPShares",
        n: "Impact VWAP Shares",
        t: "numericColumn",
        w: 158,
        c: "Related Market Data",
        p: "Shares used in the Impact VWAP calculation",
    },
    {
        f: "columnValues.Instructions",
        n: "Instructions",
        t: "standardColumn",
        w: 109,
        c: "Order",
        p: "Instructions on the General tab of the order ticket",
    },
    {
        f: "columnValues.InstrumentId",
        n: "Instrument Id",
        t: "numericColumn",
        c: "Security Master",
        p: "Enfusion internal instrument ID associated with the instrument traded",
    },
    {
        f: "columnValues.InstrumentSubtype",
        n: "Instrument Subtype",
        t: "standardColumn",
        w: 167,
        c: "Security Master",
        p: "Subtype of the instrument",
    },
    {
        f: "columnValues.InstrumentType",
        n: "Instrument Type",
        t: "standardColumn",
        c: "Security Master",
        p: "Instrument type of the order",
    },
    {
        f: "columnValues.IsActiveParent",
        n: "Is Active Parent",
        t: "standardColumn",
        w: 129,
        c: "Order",
        p: "Displays true or false depending whether or not the parent order is active (i.e. status other than Filled, Cancelled, and Rejected)",
    },
    {
        f: "columnValues.IsCurrentlyScheduled",
        n: "Is Currently Scheduled",
        t: "standardColumn",
        w: 166,
        c: "Order",
        p: "Displays true or false depending on whether or not the order has been scheduled to be transmitted at a specified time in the next 24 hours",
    },
    {
        f: "columnValues.isExecutionRoute",
        n: "Is Execution Route",
        t: "standardColumn",
        w: 145,
        c: "Order",
        p: "Displays true or false depending on whether or not the order is NOT an undecided parent order",
    },
    {
        f: "columnValues.IsForceCancel",
        n: "Is Force Cancel",
        t: "standardColumn",
        w: 125,
        c: "Order",
        p: "Displays true or false depending on whether or not the order is eligible to be force canceled",
    },
    {
        f: "columnValues.IsStale",
        n: "Is Stale",
        t: "standardColumn",
        w: 83,
        c: "Order",
        p: "Indicates if this is a Day Order from a prior day",
    },
    {
        f: "columnValues.Undecided",
        n: "Is Undecided",
        t: "standardColumn",
        w: 114,
        c: "Order",
        p: "Displays true or false depending on whether or not the order is an undecided parent order",
    },
    {
        f: "columnValues.IsUnsolicitedCancel",
        n: "Is Unsolicited Cancel",
        t: "standardColumn",
        w: 156,
        c: "Order",
        p: "Displays true or false depending on whether or not the order was cancelled by the broker",
    },
    {
        f: "columnValues.Issuer",
        n: "Issuer",
        t: "standardColumn",
        c: "Security Master",
        p: "Name of the legal entity that issued the instrument",
    },
    {
        f: "columnValues.Last",
        n: "Last",
        t: "numericColumn",
        w: 105,
        c: "Related Market Data",
        p: "Last price of the security on the market",
    },
    {
        f: "columnValues.LastFillPx",
        n: "Last Fill Px",
        t: "numericColumn",
        w: 102,
        c: "Fill Info",
        p: "Price of the most recent fill on the order",
    },
    {
        f: "columnValues.LastFillQty",
        n: "Last Fill Quantity",
        t: "numericColumn",
        c: "Fill Info",
        p: "Quantity of the lastest fill of the order",
    },
    {
        f: "columnValues.LastFilledDate",
        n: "Last Filled Dt",
        t: "dateColumn",
        w: 181,
        c: "Order",
        p: "Timestamp of the last fill message received for the order",
    },
    {
        f: "columnValues.LastModifiedAt",
        n: "Last Modified At",
        t: "dateColumn",
        w: 181,
        c: "Order",
        p: "Time that order was last modified",
    },
    {
        f: "columnValues.LastUpdateUser",
        n: "Last Update User",
        t: "standardColumn",
        c: "Order",
        p: "Most recent user to update the order",
    },
    {
        f: "columnValues.Limit",
        n: "Limit",
        t: "numericColumn",
        w: 84,
        c: "Order",
        p: "Limit price of the order for Limit and Stop Limit order types",
    },
    {
        f: "columnValues.ListAlias",
        n: "List Alias",
        t: "standardColumn",
        w: 93,
        c: "Program Trading",
        p: "Client-defined name for orders that have been grouped together to be transmitted as a cohesive group to a program trading desk",
    },
    {
        f: "columnValues.ListId",
        n: "List Id",
        t: "standardColumn",
        w: 77,
        c: "Program Trading",
        p: "Unique identification number for a group of orders",
    },
    {
        f: "columnValues.ListSeqNo",
        n: "List Seq No",
        t: "numericColumn",
        w: 105,
        c: "Program Trading",
        p: "When a user groups a set of orders, the List Seq No column displays a number in ascending order for each individual order within the group",
    },
    {
        f: "columnValues.ListTotalNo",
        n: "List Total No",
        t: "numericColumn",
        w: 113,
        c: "Program Trading",
        p: "Total number of orders displayed in a group",
    },
    {
        f: "columnValues.MarketLimitDelta",
        n: "Market/Limit Delta",
        t: "numericColumn",
        w: 148,
        c: "Order",
        p: "Difference between the limit price of the order and the last price the security traded at",
    },
    {
        f: "columnValues.MarketLimitDeltaPct",
        n: "Market/Limit Delta %",
        t: "numericColumn",
        w: 161,
        c: "Order",
        p: "Nominal amount by which the limit price is away from the last price the security traded at, divided by the last price",
    },
    {
        f: "columnValues.MaturityDate",
        n: "Maturity Date",
        t: "dateColumn",
        w: 120,
        c: "Security Master",
        p: "Maturity date or termination date of the instrument on the order",
    },
    {
        f: "columnValues.MaxDarkPoolQuantity",
        n: "Max Dark Qty",
        t: "numericColumn",
        c: "Execution Route",
        p: "Maximum quantity exposed to dark pools",
    },
    {
        f: "columnValues.Method",
        n: "Method",
        t: "standardColumn",
        w: 103,
        c: "Order",
        p: "Method used for execution. There are three possible values: Undecided, Electronic, and Voice",
    },
    {
        f: "columnValues.NDF",
        n: "NDF",
        t: "standardColumn",
        c: "Order",
        p: "Indicates whether or not the FX order is deliverable",
    },
    {
        f: "columnValues.NPV",
        n: "NPV",
        t: "numericColumn",
        c: "Order",
        p: "Net present value",
    },
    {
        f: "columnValues.NativeCS01",
        n: "Native CS01",
        t: "numericColumn",
        c: "Order",
        p: "CS01 exposure in Native currency",
    },
    {
        f: "columnValues.NativeDV01",
        n: "Native DV01",
        t: "numericColumn",
        c: "Order",
        p: "DV01 exposure in Native currency",
    },
    {
        f: "columnValues.NearAmount",
        n: "Near Amount",
        t: "numericColumn",
        c: "Order",
        p: "Quantity of FX Orders (near-leg quantity of FX swap order)",
    },
    {
        f: "columnValues.NotionalFilledAmount",
        n: "Notional Filled Amount",
        t: "numericColumn",
        w: 171,
        c: "Proceeds",
        p: "Notional value of the filled shares for this order.",
    },
    {
        f: "columnValues.NotionalFilledAmountAvgPx",
        n: "Notional Filled Amt - Avg Px",
        t: "numericColumn",
        c: "Proceeds",
        p: "Notional value of the filled shares for this order, using av Px",
    },
    {
        f: "columnValues.NotionalTradeAmount",
        n: "Notional Trade Amount",
        t: "numericColumn",
        w: 172,
        c: "Proceeds",
        p: "Notional amount of the order calculated using the Last Price (i.e., Last Price * Quantity)",
    },
    {
        f: "columnValues.NotionalTradeAmountNtnlCurrency",
        n: "Notional Trade Amount - Ntnl Ccy",
        t: "numericColumn",
        c: "Proceeds",
        p: "Notional value of the order converted to the currency set in the Notional Currency user setting",
    },
    {
        f: "columnValues.NotionalUnfilledAmount",
        n: "Notional Unfilled Amount",
        t: "numericColumn",
        w: 184,
        c: "Proceeds",
        p: "Notional value of the unfilled shares for this order",
    },
    {
        f: "columnValues.OfferingType",
        n: "Offering Type",
        t: "standardColumn",
        c: "Order",
        p: "Type of share offering (e.g., IPO (Primary), Secondary)",
    },
    {
        f: "columnValues.Open",
        n: "Open",
        t: "numericColumn",
        w: 81,
        c: "Order",
        p: "Remaining quantity left to be filled",
    },
    {
        f: "columnValues.OpenPctOf30D_ADV",
        n: "Open % of 30D ADV",
        t: "numericColumn",
        w: 154,
        c: "Related Market Data",
        p: "Order's open quantity as a percentage of of the 30D Avg Vol",
    },
    {
        f: "columnValues.OpenPx",
        n: "Open Px",
        t: "numericColumn",
        w: 91,
        c: "Related Market Data",
        p: "Open price of the day",
    },
    {
        f: "columnValues.OpenVal_NAV",
        n: "Open Val / NAV",
        t: "numericColumn",
        w: 128,
        c: "Proceeds",
        p: "$ Notional Unfilled Amount divided by NAV",
    },
    {
        f: "columnValues.OrderPctOf30D_ADV",
        n: "Order % of 30D ADV",
        t: "numericColumn",
        w: 156,
        c: "Related Market Data",
        p: "Order quantity as a percentage of of the 30D Avg Vol",
    },
    {
        f: "columnValues.OrderPctOf3M_ADV",
        n: "Order % of 3M ADV",
        t: "numericColumn",
        w: 151,
        c: "Related Market Data",
        p: "Order quantity as a percentage of the 3M Avg Vol",
    },
    {
        f: "columnValues.OrderDate",
        n: "Order Date",
        t: "dateColumn",
        w: 108,
        c: "Order",
        p: "Date that the order was created in Enfusion",
    },
    {
        f: "columnValues.OrderDays",
        n: "Order Days",
        t: "numericColumn",
        w: 105,
        c: "Order",
        p: "Number of days since the order was created",
    },
    {
        f: "columnValues.OrderId",
        n: "Order Id",
        t: "numericColumn",
        w: 92,
        c: "Order",
        p: "Order’s unique identification number within Enfusion",
    },
    {
        f: "columnValues.OrderLifecycleStatus",
        n: "Order Lifecycle Status",
        t: "standardColumn",
        c: "Order",
        p: "Lifecycle status of the order",
    },
    {
        f: "columnValues.OrderVal_NAV",
        n: "Order Val / NAV",
        t: "numericColumn",
        w: 131,
        c: "Proceeds",
        p: "$ Notional Trade Amount divided by NAV",
    },
    {
        f: "columnValues.PBDExposure",
        n: "PBD Exposure",
        t: "numericColumn",
        w: 122,
        c: "Position",
        p: "Value of the Exposure column of the instrument as of the previous business day",
    },
    {
        f: "columnValues.PBDFilledQty",
        n: "PBD Filled Qty",
        t: "numericColumn",
        w: 124,
        c: "Fill Info",
        p: "Number of shares that were filled on the order on the previous business day",
    },
    {
        f: "columnValues.PBDFilledQtyGlobal",
        n: "PBD Filled Qty (Global)",
        t: "numericColumn",
        c: "Fill Info",
        p: "Number of shares that were filled on the order on the previous business day, adjusted for market environment time zone",
    },
    {
        f: "columnValues.PackageId",
        n: "Package Id",
        t: "numericColumn",
        c: "Order",
        p: "Unique ID of orders grouped into the same package",
    },
    {
        f: "columnValues.PackageIdentifier",
        n: "Package Identifier",
        t: "standardColumn",
        c: "Order",
        p: "ID of the package under which the order has been packaged",
    },
    {
        f: "columnValues.PackageName",
        n: "Package Name",
        t: "standardColumn",
        c: "Order",
        p: "Name of a group of packaged orders",
    },
    {
        f: "columnValues.PackageSize",
        n: "Package Size",
        t: "numericColumn",
        c: "Order",
        p: "Count of individual orders in a package",
    },
    {
        f: "columnValues.PairsIsAutoAddNameToPositionBlock",
        n: "Pairs Auto Add Name To Position Block",
        t: "standardColumn",
        c: "Pairs",
        p: "Indicates whether or not the the Add Name To Position Block box is checked for pairs orders",
    },
    {
        f: "columnValues.PairsId",
        n: "Pairs Id",
        t: "numericColumn",
        c: "Pairs",
        p: "Unique Identifier for the pairs order",
    },
    {
        f: "columnValues.PairsIdentifier",
        n: "Pairs Identifier",
        t: "standardColumn",
        c: "Pairs",
        p: "Unique identifier generated when the pairs is created",
    },
    {
        f: "columnValues.PairsIsLongShortPair",
        n: "Pairs LongShort Pair",
        t: "standardColumn",
        c: "Pairs",
        p: "Indicates whether the pairs order is a long-short pairs order",
    },
    {
        f: "columnValues.PairsIsMaintainRatio",
        n: "Pairs Maintain Ratio",
        t: "standardColumn",
        c: "Pairs",
        p: "Indicates whether or not the the Maintain Ratio box is checked for pairs orders",
    },
    {
        f: "columnValues.PairsName",
        n: "Pairs Name",
        t: "standardColumn",
        c: "Pairs",
        p: "Client-defined name for pairs orders",
    },
    {
        f: "columnValues.PairsOrderDate",
        n: "Pairs Order Date",
        t: "dateColumn",
        c: "Pairs",
        p: "Creation date of the pairs order",
    },
    {
        f: "columnValues.PairsSpreadLimit",
        n: "Pairs Spread Limit",
        t: "numericColumn",
        c: "Pairs",
        p: "Unique identifier generated when the pairs order is created",
    },
    {
        f: "columnValues.ParentOrderId",
        n: "Parent Order Id",
        t: "numericColumn",
        w: 129,
        c: "Order",
        p: "Parent order ID of child electronic or child voice orders",
    },
    {
        f: "columnValues.PayLegInfo",
        n: "Pay Leg Info",
        t: "standardColumn",
        c: "Order",
        p: "Pay leg info of IRS order, including rate and frequency",
    },
    {
        f: "columnValues.Placed",
        n: "Placed",
        t: "standardColumn",
        c: "Order",
        p: "Placement status of the order",
    },
    {
        f: "columnValues.PortfolioManager",
        n: "Portfolio Manager",
        t: "standardColumn",
        w: 138,
        c: "Allocations",
        p: "Portfolio Manager assigned on the General tab of the order",
    },
    {
        f: "columnValues.Position",
        n: "Position",
        t: "numericColumn",
        w: 92,
        c: "Position",
        p: "Position of the order security",
    },
    {
        f: "columnValues.PositionPercentNAV",
        n: "Position % of NAV",
        t: "numericColumn",
        c: "Order",
        p: "Position % of NAV at the order level. Formula is (Position * Rebalancing Exposure Type per quantity unit / Sum[Allocated Fund NAV])",
    },
    {
        f: "columnValues.PositionFirmwide",
        n: "Position - Firmwide",
        t: "numericColumn",
        c: "Position",
        p: "Sum of the quantity of the positions from all OEMS funds",
    },
    {
        f: "columnValues.PositionBlock",
        n: "Position Block",
        t: "standardColumn",
        c: "Allocations",
        p: "Position Block designated on the allocation(s) on the order",
    },
    {
        f: "columnValues.PreviousClosePx",
        n: "Prev Close Px",
        t: "numericColumn",
        w: 118,
        c: "Related Market Data",
        p: "Close price from previous day",
    },
    {
        f: "columnValues.PrimaryBroker",
        n: "Primary Broker",
        t: "standardColumn",
        c: "Order",
        p: "Allowed counterparty that is marked as “Default” on OMS Placeholder Accounts",
    },
    {
        f: "columnValues.ProgramId",
        n: "Program Id",
        t: "standardColumn",
        c: "Program Trading",
        p: "Program grouping ID",
    },
    {
        f: "columnValues.ProgramName",
        n: "Program Name",
        t: "standardColumn",
        c: "Program Trading",
        p: "Name of program grouping",
    },
    {
        f: "columnValues.ProgramSeqNo",
        n: "Program Seq No",
        t: "numericColumn",
        c: "Program Trading",
        p: "Sequence number within program grouping",
    },
    {
        f: "columnValues.ProgramTotalNo",
        n: "Program Total No",
        t: "numericColumn",
        c: "Program Trading",
        p: "Total number of orders in program grouping",
    },
    {
        f: "columnValues.Quantity",
        n: "Quantity",
        t: "numericColumn",
        w: 93,
        c: "Order",
        p: "Total amount of shares designated on the order",
    },
    {
        f: "columnValues.QuotingConvention",
        n: "Quoting Convention",
        t: "standardColumn",
        c: "Order",
        p: "Quotation type for the CDS/CDX instrument",
    },
    {
        f: "columnValues.RIC",
        n: "RIC",
        t: "standardColumn",
        w: 95,
        c: "Security Master",
        p: "Reuters Identification Code (RIC) of the instrument on the order",
    },
    {
        f: "columnValues.Reconciled",
        n: "Reconciled",
        t: "standardColumn",
        c: "Order",
        p: "Displays true or false depending on whether or not the order has been marked reconciled",
    },
    {
        f: "columnValues.RecvLegInfo",
        n: "Recv Leg Info",
        t: "standardColumn",
        c: "Order",
        p: "Receive leg info of IRS order, including rate and frequency",
    },
    {
        f: "columnValues.ReferenceInstrumentDescription",
        n: "Reference Instrument Description",
        t: "standardColumn",
        c: "Security Master",
        p: "Description of the reference instrument in its valuation section for the variance swap order",
    },
    {
        f: "columnValues.ReferenceInstrumentPrice",
        n: "Reference Instrument Price",
        t: "standardColumn",
        c: "Order",
        p: "Price of the reference instrument, in its valuation section, for the variance swap order",
    },
    {
        f: "columnValues.ReferenceInstrumentTicker",
        n: "Reference Instrument Ticker",
        t: "standardColumn",
        c: "Security Master",
        p: "Ticker of the reference instrument, in its valuation section, for the variance swap order.",
    },
    {
        f: "columnValues.ReleaseDateTime",
        n: "Release Date/Time",
        t: "dateColumn",
        w: 148,
        c: "Order",
        p: "Scheduled release date and time of the order",
    },
    {
        f: "columnValues.RemainingVolumeOf5DAvg",
        n: "Remaining Vol of 5D Avg",
        t: "numericColumn",
        w: 178,
        c: "Related Market Data",
        p: "Remaining volume of the past five-day average trading volume",
    },
    {
        f: "columnValues.ShortLocateAmountRequested",
        n: "Req SL Qty",
        t: "numericColumn",
        w: 104,
        c: "Order",
        p: "Total requested Sell Short locate quantity for an instrument on the order",
    },
    {
        f: "columnValues.ResultingPercentNAV",
        n: "Resulting % NAV",
        t: "numericColumn",
        c: "Order",
        p: "Resulting % NAV at the order level. Sum of Position % of NAV + Unfilled % of NAV",
    },
    {
        f: "columnValues.Resumed",
        n: "Resumed",
        t: "standardColumn",
        w: 95,
        c: "Order",
        p: "Displays true or false depending on whether or not the order has been resumed",
    },
    {
        f: "columnValues.SODOpenQuantity",
        n: "SOD Open Quantity",
        t: "numericColumn",
        w: 152,
        c: "Order",
        p: "Open quantity at the start of the trading day",
    },
    {
        f: "columnValues.SODOpenQuantityGlobal",
        n: "SOD Open Quantity (Global)",
        t: "numericColumn",
        c: "Order",
        p: "Quantity that was open as of the start of the trading day, adjusted for market environment time zone",
    },
    {
        f: "columnValues.DarkPools",
        n: "Selected Dark Pools",
        t: "standardColumn",
        w: 153,
        c: "Execution Route",
        p: "Darkpools selected on the order",
    },
    {
        f: "columnValues.SettleCcy",
        n: "Settle Ccy",
        t: "standardColumn",
        c: "Order",
        p: "Settle currency of the order",
    },
    {
        f: "columnValues.SettleDate",
        n: "Settle Dt",
        t: "dateColumn",
        c: "Order",
        p: "Order settle date",
    },
    {
        f: "columnValues.ShortInterest",
        n: "Short Interest",
        t: "numericColumn",
        w: 120,
        c: "Related Market Data",
        p: "Short Interest of the instrument on the order (SHORT_INT value from Bloomberg)",
    },
    {
        f: "columnValues.ShortInterestPctEquityFloat",
        n: "Short Interest % Equity Float",
        t: "numericColumn",
        w: 201,
        c: "Related Market Data",
        p: "% of shares shorted against the number of shares available for trading (SI_PERCENT_EQUITY_FLOAT value from Bloomberg)",
    },
    {
        f: "columnValues.SpreadLimit",
        n: "Spread Limit",
        t: "standardColumn",
        c: "Program Trading",
        p: "Spread Limit for grouped orders",
    },
    {
        f: "columnValues.SpreadRatio",
        n: "Spread Ratio",
        t: "standardColumn",
        c: "Program Trading",
        p: "Spread Ratio for grouped orders",
    },
    {
        f: "columnValues.StartDate",
        n: "Start Date",
        t: "dateColumn",
        c: "Order",
        p: "Start Date of the CDX/IRS order",
    },
    {
        f: "columnValues.Status",
        n: "Status",
        t: "standardColumn",
        w: 125,
        c: "Order",
        p: "State of the order within its lifecycle (e.g., Acknowledged, Partially Filled, Done For Day)",
    },
    {
        f: "columnValues.StatusText",
        n: "Status Text",
        t: "standardColumn",
        w: 878,
        c: "Order",
        p: "Text field that is received from the executing broker on the FIX message",
    },
    {
        f: "columnValues.Stop",
        n: "Stop",
        t: "numericColumn",
        w: 70,
        c: "Order",
        p: "Stop price of the order for Stop and Stop Limit order types",
    },
    {
        f: "columnValues.Strategy",
        n: "Strategy",
        t: "standardColumn",
        w: 110,
        c: "Execution Route",
        p: "Algo strategy of the electronic order",
    },
    {
        f: "columnValues.StrategyBook",
        n: "Strategy Book",
        t: "standardColumn",
        w: 122,
        c: "Order",
        p: "Strategy book designated on the order",
    },
    {
        f: "columnValues.Strike",
        n: "Strike",
        t: "numericColumn",
        c: "Security Master",
        p: "Strike of the variance swap contract",
    },
    {
        f: "columnValues.SubmissionStatus",
        n: "Submission Status",
        t: "standardColumn",
        w: 143,
        c: "Order",
        p: "The order's submission state",
    },
    {
        f: "columnValues.SwapOrder",
        n: "Swap Order",
        t: "standardColumn",
        w: 109,
        c: "Order",
        p: "Displays “true” or “false” according to whether or not the order has the Route To Swap Desk checkbox checked",
    },
    {
        f: "columnValues.SwapType",
        n: "Swap Type",
        t: "standardColumn",
        c: "Order",
        p: "Swap type for of CDX/IRS order",
    },
    {
        f: "columnValues.TIF",
        n: "TIF",
        t: "standardColumn",
        w: 66,
        c: "Order",
        p: "Time in force",
    },
    {
        f: "columnValues.TRSId",
        n: "TRS Id",
        t: "standardColumn",
        c: "Allocations",
        p: "TRS ID of the TRS Financing Agreement",
    },
    {
        f: "columnValues.TRSOverridesBenchmarkBBYELLOW",
        n: "TRS Overrides Benchmark BBYELLOW",
        t: "standardColumn",
        c: "TRS Overrides",
        p: "Bloomberg yellow key of the benchmark instrument in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesBenchmarkId",
        n: "TRS Overrides Benchmark Id",
        t: "numericColumn",
        c: "TRS Overrides",
        p: "Unique Enfusion ID of the benchmark instrument in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesBenchmarkTicker",
        n: "TRS Overrides Benchmark Ticker",
        t: "standardColumn",
        c: "TRS Overrides",
        p: "Ticker of the benchmark instrument in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesEndDate",
        n: "TRS Overrides End Date",
        t: "dateColumn",
        c: "TRS Overrides",
        p: "End date of the general terms in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesPayLegFirstResetDate",
        n: "TRS Overrides Pay Leg First Reset Date",
        t: "dateColumn",
        c: "TRS Overrides",
        p: "First reset date of the payment leg in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesPayLegLastResetDate",
        n: "TRS Overrides Pay Leg Last Reset Date",
        t: "dateColumn",
        c: "TRS Overrides",
        p: "Last reset date of the payment leg in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesPayLegResetFrequency",
        n: "TRS Overrides Pay Leg Reset Frequency",
        t: "standardColumn",
        c: "TRS Overrides",
        p: "Reset frequency of the payment leg in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesRate",
        n: "TRS Overrides Rate",
        t: "standardColumn",
        c: "TRS Overrides",
        p: "Current TRS override rate in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesRateEffectiveDate",
        n: "TRS Overrides Rate Effective Date",
        t: "dateColumn",
        c: "TRS Overrides",
        p: "Effective date for the current TRS override rate in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesRecLegFirstResetDate",
        n: "TRS Overrides Rec Leg First Reset Date",
        t: "dateColumn",
        c: "TRS Overrides",
        p: "First reset date of the return leg in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesRecLegLastResetDate",
        n: "TRS Overrides Rec Leg Last Reset Date",
        t: "dateColumn",
        c: "TRS Overrides",
        p: "Last reset date of the return leg in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.TRSOverridesStartDate",
        n: "TRS Overrides Start Date",
        t: "dateColumn",
        c: "TRS Overrides",
        p: "Start date of the general terms in the TRS Financing Overrides attached to the swap order",
    },
    {
        f: "columnValues.Tenor",
        n: "Tenor",
        t: "standardColumn",
        c: "Order",
        p: "Tenor for the CDX/IRS order",
    },
    {
        f: "columnValues.Ticker",
        n: "Ticker",
        t: "standardColumn",
        w: 80,
        c: "Security Master",
        p: "Ticker of the security (e.g., IBM)",
    },
    {
        f: "columnValues.TodayAvgPx",
        n: "Today Avg Px",
        t: "numericColumn",
        w: 116,
        c: "Fill Info",
        p: "Weighted average price of today's executed shares",
    },
    {
        f: "columnValues.TodayAvgPxGlobal",
        n: "Today Avg Px (Global)",
        t: "numericColumn",
        c: "Fill Info",
        p: "Weighted average price of today's executed shares, adjusted for market environment time zone",
    },
    {
        f: "columnValues.TodayCommitted",
        n: "Today Committed",
        t: "numericColumn",
        w: 141,
        c: "Order",
        p: "Quantity that was split or routed today",
    },
    {
        f: "columnValues.TodayCommittedGlobal",
        n: "Today Committed (Global)",
        t: "numericColumn",
        c: "Order",
        p: "Quantity that was split or routed today, adjusted for market environment time zone",
    },
    {
        f: "columnValues.TodayFilledPct",
        n: "Today Filled %",
        t: "numericColumn",
        w: 122,
        c: "Fill Info",
        p: "Today Filled Qty / SOD Open Quantity",
    },
    {
        f: "columnValues.TodayFilledQty",
        n: "Today Filled Qty",
        t: "numericColumn",
        w: 132,
        c: "Fill Info",
        p: "Number of shares that were filled on the order today",
    },
    {
        f: "columnValues.TodayFilledQtyGlobal",
        n: "Today Filled Qty (Global)",
        t: "numericColumn",
        c: "Fill Info",
        p: "Number of shares that were filled on the order today, adjusted for market environment time zone",
    },
    {
        f: "columnValues.TodayFilledQtyPctNav",
        n: "Today Filled Qty as % NAV",
        t: "numericColumn",
        w: 186,
        c: "Fill Info",
        p: "Order's filled quantity today as % of NAV",
    },
    {
        f: "columnValues.TradeReason",
        n: "Trade Reason",
        t: "standardColumn",
        w: 118,
        c: "Order",
        p: "Trade reason assigned in the General tab of the order",
    },
    {
        f: "columnValues.TradeSpread",
        n: "Trade Spread",
        t: "numericColumn",
        c: "Order",
        p: "Trade spread (in BPS) on the last fill of the CDS/CDX",
    },
    {
        f: "columnValues.Trader",
        n: "Trader",
        t: "standardColumn",
        w: 105,
        c: "Order",
        p: "Assigned Trader listed on the order",
    },
    {
        f: "columnValues.TraderDiscretion",
        n: "Trader Discretion",
        t: "standardColumn",
        c: "Order",
        p: "Displays true or false depending on whether or not the listed equity options are eligible to be converted to OTC options",
    },
    {
        f: "columnValues.TradingDesk",
        n: "Trading Desk",
        t: "standardColumn",
        w: 115,
        c: "Order",
        p: "Trading desk assigned in the General tab of the order",
    },
    {
        f: "columnValues.Txn",
        n: "Txn",
        t: "standardColumn",
        w: 97,
        c: "Order",
        p: "Order side",
    },
    {
        f: "columnValues.Type",
        n: "Type",
        t: "standardColumn",
        w: 83,
        c: "Order",
        p: "Type of order to be placed",
    },
    {
        f: "columnValues.Underlier",
        n: "Underlier",
        t: "standardColumn",
        w: 96,
        c: "Security Master",
        p: "Ticker of the underlying instrument",
    },
    {
        f: "columnValues.UnderlyingAsk",
        n: "Underlying Ask",
        t: "numericColumn",
        c: "Related Market Data",
        p: "Ask price of the underlying instrument",
    },
    {
        f: "columnValues.UnderlyingBid",
        n: "Underlying Bid",
        t: "numericColumn",
        c: "Related Market Data",
        p: "Bid price of the underlying instrument",
    },
    {
        f: "columnValues.UnderlyingLast",
        n: "Underlying Last",
        t: "numericColumn",
        c: "Related Market Data",
        p: "Last price of the underlying instrument",
    },
    {
        f: "columnValues.UnderlyingNotionalAmt",
        n: "Underlying Notional Amt",
        t: "numericColumn",
        c: "Order",
        p: "Notional Amount of the underlying instrument",
    },
    {
        f: "columnValues.UndisclosedShort",
        n: "Undisclosed Short",
        t: "standardColumn",
        w: 143,
        c: "Order",
        p: "Displays true or false depending on whether or not sells are disclosed to the market as short",
    },
    {
        f: "columnValues.UnFilledPct",
        n: "Unfilled %",
        t: "numericColumn",
        c: "Fill Info",
        p: "Unfilled % of the order",
    },
    {
        f: "columnValues.UnfilledPctOf3M_ADV",
        n: "Unfilled % of 3M ADV",
        t: "numericColumn",
        w: 162,
        c: "Related Market Data",
        p: "Order's unfilled quantity as a percentage of the 3M Avg Vol",
    },
    {
        f: "columnValues.UnfilledPercentNAV",
        n: "Unfilled % of NAV",
        t: "numericColumn",
        c: "Order",
        p: "Unfilled % of NAV at the order level. Formula is (Position * Rebalancing Exposure Type per quantity unit / Sum[Allocated Fund NAV])offer",
    },
    {
        f: "columnValues.UnfilledQty",
        n: "Unfilled Qty",
        t: "numericColumn",
        c: "Fill Info",
        p: "Number of shares that have not been filled on the order",
    },
    {
        f: "columnValues.VWAP",
        n: "VWAP",
        t: "numericColumn",
        w: 79,
        c: "Related Market Data",
        p: "Volume-weighted average price of the security being traded",
    },
    {
        f: "columnValues.FilledPctOfVWAPShares",
        n: "VWAP %",
        t: "numericColumn",
        w: 92,
        c: "Related Market Data",
        p: "Orders filled quantity as a percent of VWAP Shares",
    },
    {
        f: "columnValues.VWAPShares",
        n: "VWAP Shares",
        t: "numericColumn",
        w: 118,
        c: "Related Market Data",
        p: "Shares in the market to be used in the VWAP calculation",
    },
    {
        f: "columnValues.VarianceUnits",
        n: "Variance Units",
        t: "numericColumn",
        c: "Related Market Data",
        p: "Variance Units on the Variance Swap Order Ticket, rounded to two decimals",
    },
    {
        f: "columnValues.Volume",
        n: "Volume",
        t: "numericColumn",
        w: 86,
        c: "Related Market Data",
        p: "Total volume of the security being traded in the market today",
    },
    {
        f: "columnValues.WorkflowState",
        n: "Workflow State",
        t: "standardColumn",
        w: 129,
        c: "Order",
        p: "Workflow State that was selected on the General tab of the order ticket",
    },
    {
        f: "columnValues.Working",
        n: "Working",
        t: "numericColumn",
        c: "Order",
        p: "Number of shares placed but not yet executed",
    },
];
