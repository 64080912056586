import { noop } from "lodash";
import * as React from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import { isBrowser } from "../misc/utils";
import { useMounted } from "../useMounted";
const defaultState = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
};
const RO = isBrowser
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.ResizeObserver
    : undefined;
function useMeasure() {
    const frame = React.useRef(0);
    const [element, ref] = React.useState(null);
    const [rect, set] = React.useState(defaultState);
    const isMounted = useMounted();
    const setRect = React.useCallback((val) => {
        if (isMounted())
            set(val);
    }, [set]);
    const observer = React.useMemo(() => new RO((entries) => {
        if (entries[0]) {
            clearTimeout(frame.current);
            frame.current = setTimeout(() => {
                const { x, y, width, height, top, left, bottom, right } = entries[0].contentRect;
                setRect({ x, y, width, height, top, left, bottom, right });
            }, 50);
        }
    }), []);
    useIsomorphicLayoutEffect(() => {
        if (!element)
            return;
        observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [element]);
    return [ref, rect];
}
export default typeof RO !== "undefined"
    ? useMeasure
    : (() => [noop, defaultState]);
