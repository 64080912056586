/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGeneralizedAuth } from "@enfusion-ui/core";
import { useCachedMemo } from "@enfusion-ui/hooks";
import { useLiveQuery } from "dexie-react-hooks";
import * as React from "react";
import { db } from "../../db";
export const ReportsContext = React.createContext(undefined);
export const TableRowsContext = React.createContext(undefined);
export function useReports() {
    const context = React.useContext(ReportsContext);
    return context || {};
}
export function getStoredReport(reportId, tableId, username) {
    return db.core.tables.reports.fetchOne({
        reportId: reportId ?? "",
        tableId: tableId ?? reportId ?? "",
        username: username || "",
    });
}
export function useStoredReport(reportId, tableId) {
    const { user } = useGeneralizedAuth();
    const res = useLiveQuery(() => getStoredReport(reportId, tableId, user?.username), [reportId, tableId, user?.username || ""]);
    return useCachedMemo(() => res, undefined, [
        res,
    ]);
}
export const ReportsProviderPassthrough = ({ children, ...value }) => (React.createElement(ReportsContext.Provider, { value: value }, children));
