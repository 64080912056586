import { useRect, useRefCallback } from "@enfusion-ui/hooks";
import { isNotNull } from "@enfusion-ui/utils";
import * as React from "react";
import { PositionedPortal } from "./PositionedPortal";
export const Portal = ({ children, attachedRef, align = "left", watchPosition = false, minWidth = "width", open, topOffset: defaultTopOffset = 0, leftOffset: defaultLeftOffset = 0, rightOffset: defaultRightOffset = 0, mountRoot, ...props }) => {
    const scrollPos = useRect(attachedRef, open && watchPosition ? 1000 : null);
    const handleSetPlacement = useRefCallback((windowWidth, windowHeight, portalElementRef) => {
        if (attachedRef.current && portalElementRef.current) {
            const mountElm = mountRoot
                ? document.getElementById(mountRoot)?.getBoundingClientRect() || {
                    y: 0,
                    x: 0,
                }
                : { y: 0, x: 0 };
            const leftOffset = defaultLeftOffset - mountElm.x;
            const topOffset = align.startsWith("top")
                ? defaultTopOffset
                : defaultTopOffset - mountElm.y;
            // use the attachedRef to place the portal just under the selected item text
            const rect = attachedRef.current.getBoundingClientRect();
            const bottomOfAttached = rect.y + rect.height;
            const styles = {
                display: "block",
            };
            if (isNotNull(minWidth))
                styles.minWidth = `${minWidth === "width" ? rect.width : minWidth}px`;
            const { width, height } = portalElementRef.current
                ? portalElementRef.current.getBoundingClientRect()
                : { width: 0, height: 0 };
            if (align === "left" || align === "topLeft") {
                let left = rect.x;
                if (width + rect.x > windowWidth) {
                    left -= width + rect.x - windowWidth;
                }
                styles.left = `${left + leftOffset}px`;
            }
            if (align === "right" || align === "topRight") {
                styles.right = `${windowWidth - (rect.x + rect.width) + defaultRightOffset}px`;
            }
            // place under attached by default
            let top = align.startsWith("top") ? rect.y : rect.y + rect.height;
            if (!align.startsWith("top") &&
                (bottomOfAttached > windowHeight ||
                    bottomOfAttached + height > windowHeight)) {
                // place above attached
                top = rect.y - height;
            }
            // handle going under the view
            if (top + height > windowHeight) {
                top = windowHeight - height;
            }
            // handle going above the view
            if (top < 0)
                top = 0;
            styles.top = `${top + topOffset}px`;
            return styles;
        }
        return {};
    }, [
        scrollPos,
        defaultTopOffset,
        defaultLeftOffset,
        defaultRightOffset,
        mountRoot,
        align,
    ]);
    return (React.createElement(PositionedPortal, { open: open, ...props, mountRoot: mountRoot, setPlacement: handleSetPlacement, scrollPos: scrollPos }, children));
};
