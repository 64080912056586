import { abortable as abortableBase, abortableArgs, } from "@enfusion-ui/utils";
import DASHBOARD_API from "./dashboards";
import DOCUMENTS_API, { calls as documentsCalls } from "./documents";
import FUND_API, { calls as fundCalls } from "./fund";
import HOLIDAYS_API, { calls as holidaysCalls } from "./holidays";
import INSTRUMENT_API, { calls as instrumentCalls } from "./instrument";
import LEGALENTITY_API, { calls as legalCalls } from "./legalEntity";
import MARKETDATA_API, { calls as marketdataCalls } from "./marketdata";
import MSOPS_API from "./msOps";
import OEMS_API, { calls as oemsCalls } from "./oems";
import PORTFOLIO_API from "./portfolios";
import RECONCILIATION_API, { calls as reconCalls } from "./reconciliation";
import REPORTS_API, { calls as reportsCalls } from "./reports";
import SECURITY_API, { calls as securityCalls } from "./security";
import STORAGE_API, { calls as storageCalls } from "./storage";
import USER_API, { calls as userCalls } from "./user";
import UTILITY_API, { calls as utilityCalls } from "./utility";
import WATCHLIST_API, { calls as watchlistCalls } from "./watchList";
export * from "./utils/documents";
export * from "./utils/varReplace";
export const REST_API = (restServer) => {
    const abortable = (c) => abortableBase(restServer, c);
    function callCreator(c) {
        return {
            FETCH: abortable(c),
            PATH: (...args) => 
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            abortableArgs(c)(...args)[0],
        };
    }
    return {
        DASHBOARD: DASHBOARD_API(callCreator),
        DOCUMENTS: DOCUMENTS_API(callCreator),
        FUND: FUND_API(callCreator),
        INSTRUMENT: INSTRUMENT_API(callCreator),
        LEGALENTITY: LEGALENTITY_API(callCreator),
        OEMS: OEMS_API(callCreator),
        PORTFOLIO: PORTFOLIO_API(callCreator),
        REPORTS: REPORTS_API(callCreator),
        SECURITY: SECURITY_API(callCreator),
        STORAGE: STORAGE_API(callCreator),
        USER: USER_API(callCreator),
        UTILITY: UTILITY_API(callCreator),
        WATCHLIST: WATCHLIST_API(callCreator),
        HOLIDAYS: HOLIDAYS_API(callCreator),
        MARKETDATA: MARKETDATA_API(callCreator),
        RECONCILIATION: RECONCILIATION_API(callCreator),
        MSOPS: MSOPS_API(callCreator),
    };
};
export const INTERNAL_CALLS = {
    DOCUMENTS: documentsCalls,
    FUND: fundCalls,
    INSTRUMENT: instrumentCalls,
    LEGALENTITY: legalCalls,
    OEMS: oemsCalls,
    REPORTS: reportsCalls,
    SECURITY: securityCalls,
    STORAGE: storageCalls,
    UTILITY: utilityCalls,
    USER: userCalls,
    WATCHLIST: watchlistCalls,
    HOLIDAYS: holidaysCalls,
    MARKETDATA: marketdataCalls,
    RECONCILIATION: reconCalls,
};
