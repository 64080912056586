import { createTestId } from "@enfusion-ui/utils";
import { css, styled } from "@enfusion-ui/web-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
const StyledIconButton = styled.button `
  color: var(--text-normal);
  font: inherit;
  font-size: 0.875em;
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;

  :focus,
  :active {
    outline: none !important;
  }

  :disabled {
    cursor: not-allowed !important;
    opacity: 0.7;

    :hover {
      background: none;
      opacity: 0.7;
      color: var(--text-normal);
    }
  }

  ${({ tabFocusOutline = true }) => tabFocusOutline
    ? css `
          :focus-within {
            border: 1px solid var(--primary);
          }
        `
    : ""}

  ${(props) => props.hoverable &&
    css `
      padding: 4px 2px;
      border-radius: 50%;
      transition: all linear 300ms;

      :hover {
        background-color: var(--background-accent);
      }
    `}
`;
export const IconButton = React.forwardRef(({ icon, size = "1x", spin, iconStyle, color, rotation, dataTestId, ...rest }, ref) => {
    return (React.createElement(StyledIconButton, { ref: ref, type: "button", ...rest }, icon && (React.createElement(FontAwesomeIcon, { style: iconStyle, color: color, "data-testid": dataTestId ?? createTestId("icon"), fixedWidth: true, icon: icon, size: size, spin: spin, rotation: rotation }))));
});
export const SimpleIconButton = styled(IconButton) `
  padding: 0px 4px !important;
`;
