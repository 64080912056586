import { useDimensions } from "@enfusion-ui/core";
import * as React from "react";
export const TABLET_MAX_WIDTH = 900;
export const MOBILE_MAX_WIDTH = 768;
export const MOBILE_MAX_WIDTH_CONTENT = 640;
export const useMedia = (query) => {
    const [isMatch, setIsMatch] = React.useState(false);
    React.useEffect(() => {
        const onChange = () => setIsMatch(mediaQueryList.matches);
        const mediaQueryList = window.matchMedia(query);
        const useNewListener = typeof mediaQueryList.addEventListener !== "undefined";
        if (useNewListener) {
            mediaQueryList.addEventListener("change", onChange);
        }
        else {
            mediaQueryList.addListener(onChange);
        }
        setIsMatch(mediaQueryList.matches);
        return () => {
            if (useNewListener) {
                mediaQueryList.removeEventListener("change", onChange);
            }
            else {
                mediaQueryList.removeListener(onChange);
            }
        };
    }, []);
    return isMatch;
};
export const useIsContentUnderWidth = (maxWidth = TABLET_MAX_WIDTH) => {
    const { width } = useDimensions();
    return React.useMemo(() => {
        return width !== null && width <= maxWidth;
    }, [width, maxWidth]);
};
export const useIsMobileDevice = () => useMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`);
export const useIsTabletDevice = () => useMedia(`(max-width: ${TABLET_MAX_WIDTH}px)`);
export const useIsContentMobile = () => useIsContentUnderWidth(MOBILE_MAX_WIDTH_CONTENT);
export const useIsContentTablet = () => useIsContentUnderWidth(TABLET_MAX_WIDTH);
