/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useRefCallback } from "./useRefCallback";
export function useModalState({ defaultOpen = false, beforeOpen, onOpen, beforeClose, onClose, } = {}) {
    const [open, setOpen] = React.useState(defaultOpen);
    const openContentRef = React.useRef(null);
    const openModal = useRefCallback((passthrough = null) => {
        openContentRef.current = passthrough;
        beforeOpen?.(openContentRef.current);
        setOpen(true);
        onOpen?.(openContentRef.current);
    }, [onOpen, beforeOpen]);
    const closeModal = useRefCallback((passthrough = null) => {
        beforeClose?.(openContentRef.current, passthrough);
        setOpen(false);
        onClose?.(openContentRef.current, passthrough);
    }, [onClose, beforeClose]);
    const toggleModal = useRefCallback(() => {
        if (open)
            closeModal();
        else
            openModal();
    }, [open]);
    return React.useMemo(() => ({ open, openModal, closeModal, toggleModal, openContentRef }), [open, openModal, closeModal, toggleModal, openContentRef]);
}
