/* eslint-disable react/display-name */
import { createTestId } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import { useWindowSize } from "@react-hook/window-size/throttled";
import * as React from "react";
import ReactDOM from "react-dom";
import { useClickAway, useLatest } from "react-use";
const StyledPortal = styled.div `
  .ag-advanced-filter-builder-button {
    border: none;
    border-radius: 4px;
    padding: 0.25em 1em;
    background-color: var(--background-color-1);
    &:hover {
      background-color: var(--background-color-1-hover);
    }
  }
  .ag-advanced-filter-apply-button {
    border: none;
    border-radius: 4px;
    padding: 0.25em 1em;
    color: var(--text-normal);
    background-color: var(--primary);

    &:hover {
      background-color: var(--primary-hover);
      opacity: 0.8;
    }

    :disabled,
    :disabled:hover {
      cursor: default;
      background-color: var(--background-color-1);
      color: var(--text-muted);
    }
  }
`;
const stopClick = (e) => {
    e.stopPropagation();
};
const PortalContainer = React.forwardRef(({ open, children, setPlacement, scrollPos, zIndex = 100, dataTestId = "portal", mountRoot, }, ref) => {
    const setPlacementRef = useLatest(setPlacement);
    const [windowWidth, windowHeight] = useWindowSize();
    const root = React.useMemo(() => typeof document !== "undefined" ? document.createElement("div") : null, []);
    React.useEffect(() => {
        const mountElement = mountRoot
            ? window.document.getElementById(mountRoot) || window.document.body
            : window.document.body;
        if (open && root && setPlacementRef.current) {
            mountElement.appendChild(root);
            // use the attachedRef to place the portal just under the selected item text
            root.className = "not-moveable";
            root.style.position = "absolute";
            root.style.zIndex = String(zIndex);
            const styleRes = setPlacementRef.current(windowWidth, windowHeight, ref);
            for (const key of Object.keys(styleRes)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                root.style[key] = styleRes[key];
            }
        }
        return () => {
            try {
                if (root)
                    mountElement.removeChild(root);
            }
            catch (err) {
                // was not appended yet
            }
        };
    }, [open, root, setPlacementRef, windowWidth, windowHeight, mountRoot]);
    React.useEffect(() => {
        if (!!scrollPos && open && root !== null && !!setPlacementRef.current) {
            const styleRes = setPlacementRef.current(windowWidth, windowHeight, ref);
            for (const key of Object.keys(styleRes)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                root.style[key] = styleRes[key];
            }
        }
    }, [scrollPos, open, setPlacementRef, windowWidth, windowHeight]);
    if (root)
        // Use a portal so we know the menu will always be on top
        return ReactDOM.createPortal(React.createElement(StyledPortal, { ref: ref, onClick: stopClick, "data-testid": createTestId(dataTestId) }, children), root);
    return null;
});
export const PositionedPortal = React.forwardRef(({ children, onClickOutside, ...props }, ref) => {
    const newRef = React.useRef(null);
    const portalRef = ref || newRef;
    useClickAway(portalRef, (e) => onClickOutside?.(e));
    return (React.createElement(PortalContainer, { ...props, ref: portalRef }, children));
});
