/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { AppEvent, AppEventCategories, } from "@enfusion-ui/types";
import { isUndefined } from "lodash";
const LocalStatus = {
    LocalOnly: 0,
    LocalAndRemote: 1,
    RemoteOnly: 2,
    DebugOnly: 3,
};
const debugMode = false;
const createIsCheck = (list) => (l) => list.includes(l);
const isLocal = createIsCheck([
    LocalStatus.LocalOnly,
    LocalStatus.LocalAndRemote,
]);
const isDebug = createIsCheck([LocalStatus.DebugOnly]);
function logRes(local, method, args, prefix) {
    if (isLocal(local))
        method(prefix, ...args);
    if (!!debugMode && isDebug(local))
        method(`[Debug]${prefix}`, ...args);
}
const errEvent = {
    event: AppEvent.CaughtError,
    category: AppEventCategories.Navigation,
};
function createLogMethod(method) {
    return (prefix, local, message, error, event, extra) => {
        if (event)
            logEvent(local, errEvent, {
                ...(extra || {}),
                message,
                error: error?.message,
            });
        const parts = [message, error, extra].filter((i) => !isUndefined(i));
        logRes(local, method, parts, prefix);
    };
}
function logEvent(local, params, extra = {}) {
    const category = params.category || "App";
    const name = params.event || "Event";
    const label = params.gaLabel || name;
    const value = params.gaValue ?? "null";
    const other = { ...extra, category, label, value };
    logRes(local, console.info, [name, category, other], "[EVENT]");
}
function logScreenView(local, screenName) {
    logRes(local, console.info, [screenName], "[SCREEN VIEW]");
}
const logDefault = createLogMethod(console.log);
const logInfo = createLogMethod(console.info);
const logWarn = createLogMethod(console.warn);
const logError = createLogMethod(console.error);
const logSafeError = createLogMethod(console.warn);
const createBasicAppLogging = (local = LocalStatus.LocalAndRemote, prefix = "[AppLogging]") => new Proxy({}, {
    get: (target, key) => {
        if (key === "prefix")
            return (newPrefix) => createBasicAppLogging(local, newPrefix);
        if (key === "localOnly")
            return createBasicAppLogging(LocalStatus.LocalOnly, prefix);
        if (key === "remoteOnly")
            return createBasicAppLogging(LocalStatus.RemoteOnly, prefix);
        if (key === "debug")
            return createBasicAppLogging(LocalStatus.DebugOnly, prefix);
        if (key === "safeError" || key === "error")
            return (message, error, event) => {
                const method = key === "safeError" ? logSafeError : logError;
                method(prefix, local, message, error, event);
            };
        if (key === "event")
            return (event, params = {}) => {
                logEvent(local, event, params);
            };
        return (message, extra) => {
            const args = [
                prefix,
                local,
                message,
                undefined,
                undefined,
                extra,
            ];
            if (key === "log") {
                logDefault(...args);
            }
            else if (key === "info") {
                logInfo(...args);
            }
            else if (key === "warn") {
                logWarn(...args);
            }
            else if (key === "screenView") {
                logScreenView(local, message);
            }
        };
    },
});
export const BasicAppLogging = createBasicAppLogging();
