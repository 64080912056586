import { useRefCallback } from "@enfusion-ui/hooks";
import { differenceInDays } from "date-fns";
import * as React from "react";
import { DAYS_UNTIL_PASSWORD_RESET_WARNING, INTERNAL_PO_IDS, INTERNAL_PO_IDS_LIST, } from "../../constants";
import { checkUserEnabled, checkUserPermission, checkUserPo, checkUserPoId, checkUserReadOnly, checkUserType, } from "../../userChecks";
import { GeneralizedAuthContext } from "./context";
export const GeneralizedAuthProvider = ({ children, user }) => {
    // handle getting if a flag is enabled
    const isEnabled = useRefCallback((flag) => {
        if (!user)
            return false;
        return checkUserEnabled(user, flag);
    }, [user]);
    const hasPerm = useRefCallback((perm) => {
        if (!user)
            return false;
        return checkUserPermission(user, perm);
    }, [user]);
    const hasRestrictedPerm = useRefCallback((perm) => {
        if (!!user?.adminUser)
            return false;
        return hasPerm(perm);
    }, [user]);
    const isUserType = useRefCallback((userType, caseSensitive = true) => {
        if (!user)
            return false;
        return checkUserType(user, userType, caseSensitive);
    }, [user]);
    const isPo = useRefCallback((poName, caseSensitive = true) => {
        if (!user)
            return false;
        return checkUserPo(user, poName, caseSensitive);
    }, [user]);
    const isPoId = useRefCallback((poId) => {
        if (!user)
            return false;
        return checkUserPoId(user, poId);
    }, [user]);
    const isAdmin = useRefCallback(() => {
        if (!user || !user.adminUser)
            return false;
        return true;
    }, [user]);
    const isInternalPo = useRefCallback(() => {
        if (!user)
            return false;
        return INTERNAL_PO_IDS_LIST.some(isPoId);
    }, [user]);
    const isEnfusionAdmin = useRefCallback(() => {
        return isAdmin() && isPoId(INTERNAL_PO_IDS.Enfusion);
    }, [isAdmin, isPoId]);
    const isReadOnly = useRefCallback((flag) => {
        if (!user)
            return false;
        return checkUserReadOnly(user, flag);
    }, [user]);
    const passwordExpirationDate = React.useMemo(() => user?.flags?.nextRenewalDate, [user]);
    const daysUntilPasswordRenewal = React.useMemo(() => passwordExpirationDate
        ? differenceInDays(new Date(passwordExpirationDate), new Date())
        : undefined, [user]);
    const userHasUpcomingPasswordRenewal = React.useMemo(() => daysUntilPasswordRenewal
        ? daysUntilPasswordRenewal <= DAYS_UNTIL_PASSWORD_RESET_WARNING
        : false, [daysUntilPasswordRenewal]);
    const value = React.useMemo(() => ({
        user,
        isEnabled,
        hasPerm,
        hasRestrictedPerm,
        isUserType,
        isPo,
        isPoId,
        isAdmin,
        isInternalPo,
        isEnfusionAdmin,
        isReadOnly,
        daysUntilPasswordRenewal,
        userHasUpcomingPasswordRenewal,
    }), [
        user,
        isEnabled,
        hasPerm,
        hasRestrictedPerm,
        isUserType,
        isPo,
        isPoId,
        isAdmin,
        isInternalPo,
        isEnfusionAdmin,
        isReadOnly,
        daysUntilPasswordRenewal,
        userHasUpcomingPasswordRenewal,
    ]);
    return (React.createElement(GeneralizedAuthContext.Provider, { value: value }, children));
};
