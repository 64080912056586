import { split } from "lodash";
export function getWebReportQueryType(path) {
    const pathArray = split(path, ".");
    if (pathArray) {
        const reportType = pathArray[pathArray.length - 1];
        switch (reportType) {
            case "bkr":
                return "Book";
            case "acr":
                return "CustodianAccount";
            case "ppr":
                return "Position";
            case "cfr":
                return "PositionCashFlow";
            case "irb":
                return "Instrument";
            case "orb":
                return "OrderBlotter";
            case "par":
                return "TradeValuation";
            case "trb":
                return "TradeBlotter";
            case "bsr":
                return "GLBalanceSheet";
            case "cbx":
                return "GLCashBalances";
            case "cfs":
                return "GLCashFlowStatement";
            case "isr":
                return "GLIncomeStatement";
            case "ppy":
                return "GLPosition";
            case "tbr":
                return "GLTrialBalances";
            case "car":
                return "GLCashActivity";
            case "gld":
                return "GLDistributionLine";
            default:
                throw new Error("Report query type not available");
        }
    }
}
