import { isUndefined } from "lodash";
import * as React from "react";
import { DimensionsContext } from "./context";
export const DimensionsProvider = React.memo(function DimensionsProvider({ children, width, height }) {
    const ctx = React.useContext(DimensionsContext);
    const value = React.useMemo(() => ({
        width: isUndefined(width) ? ctx?.width ?? null : width,
        height: isUndefined(height) ? ctx?.height ?? null : height,
    }), [width, height, ctx?.height, ctx?.width]);
    return (React.createElement(DimensionsContext.Provider, { value: value }, children));
});
