import { styled } from "@enfusion-ui/web-core";
import { format } from "date-fns";
import { kebabCase } from "lodash";
import * as React from "react";
import DatePickerBase from "react-datepicker";
import { TextInput } from "../TextInput";
const CalendarContainer = styled.div `
  background-color: ${({ theme }) => theme.colors.backgroundColor1};
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.backgroundColor1};
  }
  .react-datepicker-time__header {
    color: ${({ theme }) => theme.colors.textNormal};
  }
  .react-datepicker__header
    .react-datepicker__header--time
    .react-datepicker__header--time--only {
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
  }
`;
const PopperContainer = styled.div `
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 101;
`;
const DEFAULT_PLACEHOLDER = "H:mm:ss";
export function TimePicker({ onChange, label, name, value, placeholder = DEFAULT_PLACEHOLDER, errors, minWidth, clearable, disabled = false, hideLabel = false, inline = false, dataTestId, ...rest }) {
    const timePickerRef = React.useRef(null);
    const [timeSelectOpen, setTimeSelectOpen] = React.useState(timePickerRef.current?.isCalendarOpen() ?? false);
    return (React.createElement(DatePickerBase, { popperContainer: ({ children }) => {
            return timeSelectOpen ? (React.createElement(PopperContainer, null, children)) : null;
        }, customInput: React.createElement(TextInput, { name: name, label: label, hideLabel: hideLabel, inline: inline, errors: errors, clearable: clearable, onClearValue: () => onChange(""), minWidth: minWidth, disabled: disabled, dataTestId: dataTestId ??
                `date-picker-${typeof label === "string" ? kebabCase(label) : kebabCase(name)}` }), value: value, showTimeSelect: true, ref: timePickerRef, name: name, timeFormat: "H:mm:ss", showTimeSelectOnly: true, onChange: (date) => {
            const formattedDate = format(date, "HH:mm:ss");
            onChange(formattedDate);
            setTimeSelectOpen(false);
        }, placeholderText: placeholder, ...rest, disabled: disabled, calendarContainer: CalendarContainer, onBlur: () => {
            setTimeSelectOpen(false);
        }, onFocus: () => {
            setTimeSelectOpen(true);
        } }));
}
