import { useGeneralizedAuth } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { createTestId, getFileIcon, getFileName, isNotNull, } from "@enfusion-ui/utils";
import { AppLogging, errorToast, REST_API, styled, } from "@enfusion-ui/web-core";
import { debounce } from "lodash";
import * as React from "react";
import { Spinner } from "../display/Spinner";
import { FileExplorer } from "../files/FileExplorer/FileExplorer";
import { Portal } from "../portal/Portal";
import { CenterContent } from "../Styled";
import { TextInput } from "./TextInput";
const ROOT_USER = "user";
const ROOT_SHARED = "shared";
const ROOT_GLOBAL = "global";
const ROOT_FIXED = "fixed";
const OptionsContainer = styled.div `
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--background-color-1);
  max-height: 150px;
`;
const SingleRowContainer = styled.div `
  display: flex;
  gap: var(--spacing-l);
`;
const retrieveNodes = REST_API.REPORTS.GET_ROOT_NODE_FACTORY();
function createRootFolder(name, nodes, path) {
    if (nodes.length > 0)
        return {
            id: `root-${path}`,
            name,
            path,
            file: false,
            nodes,
        };
    return null;
}
const fixedReportList = [
    {
        id: "pnl",
        name: "Fixed P&L",
        path: "fixed/pnl",
        pathParams: {
            groupingType: null,
        },
        file: true,
    },
    {
        id: "trade",
        name: "Fixed Trade Report",
        path: "fixed/trade",
        pathParams: {
            groupingType: "PurchasesSales",
            startDate: { dateSelectionType: "Today", asOfDate: null },
            endDate: { dateSelectionType: "Latest", asOfDate: null },
        },
        file: true,
    },
    {
        id: "taxlots",
        name: "Fixed Tax Lot Report",
        path: "fixed/taxlots",
        pathParams: {
            groupingType: "Open",
        },
        file: true,
    },
    {
        id: "cptycommission",
        name: "Fixed Commission Counterparty Report",
        path: "fixed/cptycommission",
        pathParams: {
            groupingType: "Open",
        },
        file: true,
    },
    {
        id: "position",
        name: "Fixed Position Report",
        path: "fixed/position",
        pathParams: {
            groupingType: "PnL",
        },
        file: true,
    },
];
export const ReportSelect = ({ value, onSelect, iconContainer, menuOpen = false, setMenuOpen = () => null, showFixedReports = false, showMyReports = false, showSharedReports = false, showOptionalKey = true, diffReport = false, dataTestId, name, ...rest }) => {
    const { hasPerm, hasRestrictedPerm } = useGeneralizedAuth();
    const [searchText, setSearchText] = React.useState("");
    const [term, setTerm] = React.useState(getFileName(value));
    const [key, setKey] = React.useState((value?.key ?? ""));
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const ref = React.useRef(null);
    const signalRef = React.useRef(new AbortController());
    const allowReportsViewing = !hasRestrictedPerm?.("DisallowAllReportViewing");
    const getOptions = useRefCallback(debounce(async (newTerm) => {
        setLoading(true);
        signalRef.current.abort();
        signalRef.current = new AbortController();
        let userTerms = [];
        let sharedTerms = [];
        let globalTerms = [];
        try {
            userTerms = showMyReports
                ? await REST_API.REPORTS.GET_SEARCH_ROOT_NODE(ROOT_USER, newTerm, diffReport, signalRef.current)
                : [];
        }
        catch (err) {
            errorToast("Failed to retrieve saved user reports", err.message);
        }
        try {
            sharedTerms =
                showSharedReports && allowReportsViewing
                    ? await REST_API.REPORTS.GET_SEARCH_ROOT_NODE(ROOT_SHARED, newTerm, diffReport, signalRef.current)
                    : [];
        }
        catch (err) {
            errorToast("Failed to retrieve shared reports", err.message);
        }
        try {
            globalTerms = hasPerm("DashboardEditor")
                ? await REST_API.REPORTS.GET_SEARCH_ROOT_NODE(ROOT_GLOBAL, newTerm, diffReport, signalRef.current)
                : [];
        }
        catch (err) {
            errorToast("Failed to retrieve global reports", err.message);
        }
        setOptions([
            createRootFolder("Fixed Reports", showFixedReports ? fixedReportList : [], ROOT_FIXED),
            createRootFolder("My Reports", userTerms, ROOT_USER),
            createRootFolder("Shared Reports", sharedTerms, ROOT_SHARED),
            createRootFolder("Template Reports", globalTerms, ROOT_GLOBAL),
        ].filter(isNotNull));
        setLoading(false);
    }, 400), [showFixedReports, showMyReports, showSharedReports, allowReportsViewing]);
    const setReportList = useRefCallback(async () => {
        setLoading(true);
        const ac = new AbortController();
        try {
            signalRef.current?.abort();
            signalRef.current = ac;
            const userReportList = showMyReports
                ? await REST_API.REPORTS.GET_ROOT_NODE(ROOT_USER, diffReport, signalRef.current)
                : [];
            const sharedReportList = showSharedReports && allowReportsViewing
                ? await REST_API.REPORTS.GET_ROOT_NODE(ROOT_SHARED, diffReport, signalRef.current)
                : [];
            const globalReportList = hasPerm("DashboardEditor")
                ? await REST_API.REPORTS.GET_ROOT_NODE(ROOT_GLOBAL, diffReport, signalRef.current)
                : [];
            setOptions([
                createRootFolder("Fixed Reports", showFixedReports ? fixedReportList : [], ROOT_FIXED),
                createRootFolder("My Reports", userReportList, ROOT_USER),
                createRootFolder("Shared Reports", sharedReportList, ROOT_SHARED),
                createRootFolder("Template Reports", globalReportList, ROOT_GLOBAL),
            ].filter((i) => i !== null));
        }
        catch (error) {
            AppLogging.safeError("Failed to get report nodes", error);
        }
        finally {
            if (!ac.signal.aborted)
                setLoading(false);
        }
    }, [signalRef, diffReport]);
    const selected = React.useMemo(() => {
        if (value)
            return [value.id];
    }, [value]);
    const handleInputChange = async (e) => {
        const newTerm = e.target.value;
        setSearchText(newTerm);
        await getOptions(newTerm);
    };
    const handleClear = async () => {
        if (menuOpen) {
            setSearchText("");
            await setReportList();
        }
        else {
            setTerm("");
            onSelect?.(null);
        }
    };
    const handleInputFocus = () => {
        setMenuOpen(true);
    };
    React.useEffect(() => {
        if (menuOpen) {
            if (!searchText) {
                setReportList();
            }
            else {
                getOptions(searchText);
            }
        }
    }, [menuOpen, getOptions, searchText, setReportList]);
    const closeMenu = React.useCallback(() => {
        setMenuOpen(false);
        setOptions([]);
    }, []);
    const handleClick = useRefCallback((node) => {
        if (node.file) {
            setTerm(getFileName(node));
            setSearchText("");
            closeMenu();
            onSelect?.(node, key);
        }
    }, [onSelect, closeMenu, key]);
    const handleKeyChangeCallback = useRefCallback(debounce((key) => {
        if (value)
            onSelect?.(value, key);
    }, 400), [value, onSelect]);
    const handleKeyChange = useRefCallback((e) => {
        const newKey = e.target.value;
        setKey(newKey);
        handleKeyChangeCallback(newKey);
    }, [value]);
    React.useEffect(() => {
        setTerm(getFileName(value));
    }, [value]);
    return (React.createElement(SingleRowContainer, { "data-testid": dataTestId || name },
        React.createElement(TextInput, { ref: ref, value: menuOpen ? searchText : term, onChange: handleInputChange, iconContainer: iconContainer, onFocus: handleInputFocus, onClearValue: handleClear, clearable: !menuOpen, ...rest }),
        showOptionalKey && (React.createElement(TextInput, { label: "Optional Key", value: key, onChange: handleKeyChange, dataTestId: createTestId("optional-key") })),
        React.createElement(Portal, { open: menuOpen, attachedRef: ref, onClickOutside: closeMenu, dataTestId: createTestId((dataTestId || name) ?? "", "portal") },
            React.createElement(OptionsContainer, null,
                loading ? (React.createElement(CenterContent, { style: { minHeight: 30 } },
                    React.createElement(Spinner, { size: "lg", dataTestId: "report-select" }))) : null,
                !loading ? (options.length > 0 ? (React.createElement(FileExplorer, { nodes: options, getFileIcon: getFileIcon, getFileName: getFileName, onEntryClick: handleClick, retrieveNodes: (node) => retrieveNodes(node, diffReport, undefined), multiSelect: false, values: selected })) : (React.createElement(CenterContent, { style: { minHeight: 30 } }, "No options found"))) : null))));
};
