import { ReconStatusTypeMap } from "@enfusion-ui/types";
import { faAlarmExclamation, faCheckDouble, faEmptySet, faSiren, faSpinner, faTriangleExclamation, faXmark, } from "@fortawesome/pro-solid-svg-icons";
import { getFileExtensionIcon } from "./getFileExtensionIcon";
export const fileExtensionRegex = /^(.+)\.([^.]+)$/;
export const getFileParts = (fileName) => {
    const matches = fileExtensionRegex.exec(fileName);
    const name = matches ? matches[1] : fileName;
    const extension = matches ? matches[2] : "";
    return { name, extension };
};
export const getFileExt = (fileName) => getFileParts(fileName).extension;
export const getFileNameDirect = (fileName) => getFileParts(fileName).name;
export const getFileName = (node) => {
    if (!node)
        return "";
    return getFileNameDirect(node.name);
};
export const getFileIconByStatus = (status) => {
    switch (status) {
        case ReconStatusTypeMap.Queued:
        case ReconStatusTypeMap.Subscribed:
        case ReconStatusTypeMap.Running:
            return faSpinner;
        case ReconStatusTypeMap.TimedOut:
        case ReconStatusTypeMap.Failed:
            return faXmark;
        case ReconStatusTypeMap.Success:
            return faCheckDouble;
        case ReconStatusTypeMap.CloseToOverdue:
            return faSiren;
        case ReconStatusTypeMap.Overdue:
            return faAlarmExclamation;
        case ReconStatusTypeMap.InvalidConfiguration:
            return faTriangleExclamation;
        case ReconStatusTypeMap.NeverRun:
        default:
            return faEmptySet;
    }
};
export const getFileIcon = (node) => {
    if (!node)
        return getFileExtensionIcon("");
    const extension = getFileExt(node.name);
    return getFileExtensionIcon(extension);
};
export const getMSOpsFileIcon = (node) => {
    if (!node)
        return getFileExtensionIcon("");
    if (node?.status)
        return getFileIconByStatus(node.status);
    return getFileExtensionIcon("");
};
export const getIconTooltipText = (status) => {
    switch (status) {
        case ReconStatusTypeMap.Queued:
        case ReconStatusTypeMap.Subscribed:
        case ReconStatusTypeMap.Running:
            return "Running";
        case ReconStatusTypeMap.TimedOut:
        case ReconStatusTypeMap.Failed:
            return "Failed";
        case ReconStatusTypeMap.Success:
            return "Success";
        case ReconStatusTypeMap.CloseToOverdue:
            return "Close To Overdue (<= 30mins till overdue)";
        case ReconStatusTypeMap.Overdue:
            return "Overdue (Past SLA time)";
        case ReconStatusTypeMap.NeverRun:
            return "Not Triggered";
        case ReconStatusTypeMap.InvalidConfiguration:
            return "Invalid Configuration";
        default:
            return "";
    }
};
