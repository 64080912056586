import { SEPARATOR } from "@enfusion-ui/core";
const reportUID = (args) => {
    const list = [args.reportId];
    if (args.tableId)
        list.push(args.tableId);
    return list.join(SEPARATOR);
};
const portfolioUID = (args) => {
    return args.path;
};
const blotterUID = (args) => {
    return args.blotter;
};
export const createGridUID = {
    pms: reportUID,
    pwb: portfolioUID,
    oms: blotterUID,
};
