import { css, styled, useIsContentMobile, useIsContentTablet, } from "@enfusion-ui/web-core";
import * as React from "react";
export const FormElementContainer = styled.div `
  display: flex;
  flex: 1 1;
  width: 100%;

  ${({ formLayout = "desktop", mobileBasis, tabletBasis, desktopBasis, basis, }) => {
    if (formLayout === "mobile" && mobileBasis)
        return css `
        flex-basis: ${mobileBasis};
      `;
    if (formLayout === "tablet" && tabletBasis)
        return css `
        flex-basis: ${tabletBasis};
      `;
    if (formLayout === "desktop" && desktopBasis)
        return css `
        flex-basis: ${desktopBasis};
      `;
    return css `
      flex-basis: ${basis};
    `;
}}
`;
export const FormElement = ({ basis = "100%", children, inputId, ...rest }) => {
    const isMobile = useIsContentMobile();
    const isTablet = useIsContentTablet();
    let formLayout = "desktop";
    if (isTablet)
        formLayout = "tablet";
    if (isMobile)
        formLayout = "mobile";
    return (React.createElement(FormElementContainer, { ...rest, id: inputId, basis: basis, formLayout: formLayout }, children));
};
