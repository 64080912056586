/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useRefCallback } from "./useRefCallback";
export function useAsyncCall(asyncMethod, onError, defaultLoading = false) {
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(defaultLoading);
    const [errored, setErrored] = React.useState(false);
    const abortController = React.useRef(new AbortController());
    const fetchResult = useRefCallback(async () => {
        setLoading(true);
        setErrored(false);
        try {
            setResult(await asyncMethod(abortController.current.signal));
        }
        catch (err) {
            onError?.(err);
            setErrored(true);
        }
        finally {
            setLoading(false);
        }
    }, [asyncMethod]);
    const abort = useRefCallback((reason) => {
        abortController.current.abort(reason);
    }, [abortController]);
    return React.useMemo(() => ({ result, loading, errored, call: fetchResult, abort }), [result, loading, errored, fetchResult, abort]);
}
