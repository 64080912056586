import { useRestAbortableOptions } from "@enfusion-ui/hooks";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { createControlled } from "../forms";
import { MultiSelect } from "../inputs";
export const DeskSelect = ({ label = "Desks", placeholder = "All Desks", inputId = "assigned-desk-multi-selection-id", ...props }) => {
    const { options, loading } = useRestAbortableOptions(REST_API.SECURITY.GET_TRADING_DESKS.FETCH, (td) => ({ value: td.id, label: td.groupName }));
    return (React.createElement(MultiSelect, { ...props, label: label, options: options, inputId: inputId, isLoading: loading, placeholder: placeholder }));
};
export const ControlledAssignedDeskSelect = createControlled()(DeskSelect, (i) => i, {
    defaultName: "assignedDeskIds",
    defaultWatch: true,
    useFormElement: false,
});
