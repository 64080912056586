import { useGeneralizedAuth } from "@enfusion-ui/core";
import { styled } from "@enfusion-ui/web-core";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { GlobalTimesPanel } from "../display/GlobalTimesPanel";
export const BOTTOM_INFO_BAR_HEIGHT = 20;
const BottomInfoBarContainer = styled.div `
  width: 100%;
  height: ${BOTTOM_INFO_BAR_HEIGHT}px;
  background-color: ${({ theme }) => ["dracula", "darkSpaceGray", "light", "monokai"].includes(theme.key)
    ? "var(--primary-hover)"
    : "var(--primary)"};

  display: flex;
  align-items: center;
  padding: 0px 0.5rem;
  color: ${({ theme }) => `var(--text-${theme.key === "light" ? "inverted" : "normal"})`};
`;
const LOCATIONS = [
    { location: "Local Time", timeZone: undefined },
    { location: "Chicago", timeZone: "America/Chicago" },
    { location: "Hong Kong", timeZone: "Asia/Hong_Kong" },
    { location: "London", timeZone: "Europe/London" },
    { location: "New York", timeZone: "America/New_York" },
    { location: "Tokyo", timeZone: "Asia/Tokyo" },
];
export const BottomInfoBar = ({ style, }) => {
    const { user } = useGeneralizedAuth();
    const [locations, setLocations] = React.useState(LOCATIONS);
    React.useEffect(() => {
        const timePanelEntries = user?.flags?.timePanelDetails?.timePanelEntries;
        if (timePanelEntries?.length) {
            let locations = timePanelEntries;
            if (!locations.some((entry) => entry.timeZone === user?.flags?.timeZone)) {
                locations = [
                    { location: "Local Time", timeZone: undefined },
                    ...locations,
                ];
            }
            setLocations(locations);
        }
    }, [user]);
    return (React.createElement(BottomInfoBarContainer, { style: style },
        React.createElement(GlobalTimesPanel, { locations: locations }),
        user?.flags?.timePanelDetails?.issue && (React.createElement(FontAwesomeIcon, { icon: faExclamationCircle, size: "sm", title: user?.flags?.timePanelDetails?.issue }))));
};
