import { formatPercentage, isNotNull, } from "@enfusion-ui/utils";
import { createControlled } from "../forms/ControlledInputs";
import { NumericInput, NumericInputAsString, } from "./NumericInput";
export const ControlledNumericInput = createControlled()(NumericInput, (props, { invalid }) => ({
    ...props,
    invalid,
}), { defaultWatch: true, defaultNullValue: null });
export const ControlledNumericInputAsString = createControlled()(NumericInputAsString, (props, { invalid }) => ({
    ...props,
    invalid,
}));
function formatValue(num, fractionDigits) {
    if (!isNotNull(num))
        return "";
    const val = Number(num);
    return formatPercentage(val / 100, fractionDigits);
}
function handleChange(onChange, outputDecimal) {
    return (value) => {
        let val = value;
        if (isNotNull(val) && outputDecimal)
            val = val / 100;
        onChange(val);
    };
}
function getPercentValue(val, inputDecimal) {
    if (!isNotNull(val))
        return null;
    if (inputDecimal)
        return val * 100;
    return val;
}
export const ControlledPercentInput = createControlled()(NumericInput, ({ onChange, outputDecimal = true, inputDecimal = true, value, ...props }, { invalid }) => ({
    ...props,
    invalid,
    formatValue,
    value: getPercentValue(value, inputDecimal),
    onChange: handleChange(onChange, outputDecimal),
}), { defaultWatch: true, defaultNullValue: null });
