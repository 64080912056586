import { getUTCDateString } from "@enfusion-ui/utils";
import { omit } from "lodash";
import { checkUserEnabled, checkUserPermission, checkUserReadOnly, } from "../userChecks";
import * as CDXOrderFormStrategy from "./orderFormStrategy/CDXOrderFormStrategy";
import * as FXOrderFormStrategy from "./orderFormStrategy/FXOrderFormStrategy";
import * as IRSOrderFormStrategy from "./orderFormStrategy/IRSOrderFormStrategy";
import * as RegularOrderFormStrategy from "./orderFormStrategy/RegularOrderFormStrategy";
import * as VarSwapOrderFormStrategy from "./orderFormStrategy/VarSwapOrderFormStrategy";
import { initialCDXOrderFormState, initialFXOrderFormState, initialIRSOrderFormState, initialOrderFormState, initialVarSwapOrderFormState, } from "./orders";
export const ALLOCATION_TYPE = {
    absolute: "Absolute",
    percent: "Percent",
};
const TEXT_FIELDS = [
    "allocationInstructions",
    "complianceNotes",
    "counterpartyMemo",
    "instructions",
    "locateBrokerId",
];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const replaceEmptyStringValuesWithNull = (obj) => {
    const newObj = { ...obj };
    Object.keys(newObj).forEach((key) => {
        if (newObj[key] === "" && !TEXT_FIELDS.includes(key)) {
            newObj[key] = null;
        }
    });
    return newObj;
};
export function convertFormValueToFXOrder(data) {
    const enhancedData = { ...data, brokerAccountNumber: data.brokerAccount };
    const orderValues = omit(enhancedData, [
        "orderType",
        "limitPrice",
        "stopPrice",
        "calculatorType",
        "locateBrokerId",
        "locateId",
        "timeInfForce",
        "swapOrder",
        "strategy",
        "capacity",
        "darkPools",
        "allocationSchemeId",
        "parentOrderId",
        "Links",
    ]);
    return {
        ...orderValues,
        parentOrderId: null,
        destination: typeof orderValues.destination === "string"
            ? orderValues.destination
            : orderValues.destination?.description,
        fixingDate: orderValues?.fixingDate
            ? getUTCDateString(new Date(orderValues.fixingDate))
            : undefined,
        settleDate: orderValues?.settleDate
            ? getUTCDateString(new Date(orderValues.settleDate))
            : undefined,
        farFixingDate: orderValues?.farFixingDate
            ? getUTCDateString(new Date(orderValues.farFixingDate))
            : undefined,
        farSettleDate: orderValues?.farSettleDate
            ? getUTCDateString(new Date(orderValues.farSettleDate))
            : undefined,
    };
}
export const convertFXOrderToFormValues = (order, defaults = initialFXOrderFormState) => {
    const { counterpartyMemo, webOrderType, quantity, ...rest } = order;
    const formValues = {
        counterpartyMemo: counterpartyMemo ?? "",
        quantity: quantity ? `${quantity}` : "0",
        webOrderType: webOrderType,
        ...rest,
    };
    return {
        ...defaults,
        ...formValues,
    };
};
export function convertFormValuesToOrder(data) {
    const { instrument, destination, targetCompId, quantity, locateBrokerIds, ...orderValues } = omit(data, [
        // "executionSchemeId",
        "reviewedBy",
        "reviewedOn",
        "complianceState",
        "filledQty",
        "openQty",
        "status",
        "statusText",
        "lastUpdate",
        "tradeReportingIndicator",
        "avgPrice",
        "parentOrderId",
        "Links",
        "qoeValue",
        "locates",
    ]);
    return replaceEmptyStringValuesWithNull({
        ...orderValues,
        calculatorType: null,
        targetCompId: targetCompId ?? null,
        destination: typeof destination === "string" ? destination : destination?.description,
        instrumentId: instrument?.id ?? null,
        locateBrokerId: locateBrokerIds === null
            ? null
            : locateBrokerIds
                ? locateBrokerIds.join(",")
                : "",
        quantity: quantity,
    });
}
export function convertFormValueToFXSplitOrder(data, orderId) {
    const { destination, targetCompId, quantity, locateBrokerIds, ...orderValues } = omit(data, [
        // "executionSchemeId",
        "reviewedBy",
        "reviewedOn",
        "complianceState",
        "filledQty",
        "openQty",
        "status",
        "statusText",
        "lastUpdate",
        "tradeReportingIndicator",
        "avgPrice",
        "parentOrderId",
        "Links",
        "qoeValue",
        "locates",
    ]);
    return replaceEmptyStringValuesWithNull({
        ...orderValues,
        parentOrderId: orderId,
        calculatorType: null,
        targetCompId: targetCompId ?? null,
        destination: typeof destination === "string"
            ? destination
            : destination?.description,
        locateBrokerId: locateBrokerIds ? locateBrokerIds.join(",") : "",
        quantity: quantity,
    });
}
export function convertFormValuesToSplitOrder(data, orderId) {
    const { instrument, destination, targetCompId, quantity, locateBrokerIds, ...orderValues } = omit(data, [
        // "executionSchemeId",
        "reviewedBy",
        "reviewedOn",
        "complianceState",
        "filledQty",
        "openQty",
        "status",
        "statusText",
        "lastUpdate",
        "tradeReportingIndicator",
        "avgPrice",
        "parentOrderId",
        "Links",
        "qoeValue",
        "locates",
    ]);
    return replaceEmptyStringValuesWithNull({
        ...orderValues,
        parentOrderId: orderId,
        calculatorType: null,
        targetCompId: targetCompId ?? null,
        destination: typeof destination === "string" ? destination : destination?.description,
        instrumentId: instrument?.id ?? null,
        locateBrokerId: locateBrokerIds ? locateBrokerIds.join(",") : "",
        quantity: quantity,
    });
}
export const convertOrderToFormValues = (order, defaults = initialOrderFormState) => {
    const { calculatorType, counterpartyMemo, locateBrokerId, webOrderType, quantity, ...rest } = order;
    const formValues = {
        currentCalculator: calculatorType,
        counterpartyMemo: counterpartyMemo ?? "",
        locateBrokerIds: locateBrokerId ? locateBrokerId.split(",") : null,
        locates: null,
        notional: defaults.notional || 0,
        quantity: quantity ? quantity : defaults.quantity || 0,
        webOrderType: webOrderType,
        ...rest,
    };
    return {
        ...defaults,
        ...formValues,
    };
};
export const convertToDarkPool = (
// todo check if type is still used
darkPools) => {
    if (!darkPools)
        return [];
    return darkPools.map((e) => {
        if (e.value)
            return e.value;
        return e;
    });
};
export const OrderFormStrategyContext = (formType) => {
    switch (formType) {
        case "fx":
            return FXOrderFormStrategy;
        case "cdx":
            return CDXOrderFormStrategy;
        case "varSwap":
            return VarSwapOrderFormStrategy;
        case "irs":
            return IRSOrderFormStrategy;
        default:
            return RegularOrderFormStrategy;
    }
};
export const checkIsFxOrder = (financialSubType) => financialSubType === "FXForward" ||
    financialSubType === "FXSpot" ||
    financialSubType === "FXSpotForward" ||
    financialSubType === "FXSwap";
export const checkIsFutureSpreadOrder = (financialSubType) => financialSubType === "FutureSpread";
export const checkIsCdxOrder = (financialSubType) => financialSubType === "CreditDefaultSwap" ||
    financialSubType === "CDXIndex" ||
    financialSubType === "CDX";
export const checkIsVarSwapOrder = (financialSubType) => financialSubType === "VarianceSwap" ||
    financialSubType === "VolatilitySwap" ||
    financialSubType === "VarianceVolatilitySwap";
export const checkIsIrsOrder = (financialSubType) => financialSubType === "VanillaInterestRateSwap" ||
    financialSubType === "BRLInterestRateSwap" ||
    financialSubType === "OISInterestRateSwap" ||
    financialSubType === "InterestRateSwap";
export const getOrderFormType = (financialSubType, skipCheck) => {
    if (checkIsFxOrder(financialSubType))
        return "fx";
    if (checkIsFutureSpreadOrder(financialSubType))
        return "futureSpread";
    if (checkIsCdxOrder(financialSubType))
        return "cdx";
    if (!skipCheck?.varSwap && checkIsVarSwapOrder(financialSubType))
        return "varSwap";
    if (!skipCheck?.irs && checkIsIrsOrder(financialSubType))
        return "irs";
    return "equity";
};
export function convertFormValueToCDXOrder(data, orderId) {
    let formValues = data;
    formValues = omit(data, [
        "ccpShortName",
        "fcmdcmShortName",
        "tenorShortName",
    ]);
    if (orderId && orderId !== 0) {
        formValues = omit(data, ["cdxIndex"]);
    }
    if (data?.parentOrderId) {
        formValues = omit(formValues, ["cdxIndex", "ccp", "instrument"]);
    }
    else {
        formValues = omit(formValues, ["instrumentId"]);
    }
    const { destination, targetCompId, locateBrokerIds, ...orderValues } = omit(formValues, [
        // "executionSchemeId",
        "orderType",
        "limitPrice",
        "stopPrice",
        "calculatorType",
        "locateBrokerId",
        "locateId",
        "timeInfForce",
        "swapOrder",
        "strategy",
        "capacity",
        "darkPools",
        "allocationSchemeId",
    ]);
    return replaceEmptyStringValuesWithNull({
        ...orderValues,
        calculatorType: null,
        targetCompId: targetCompId ?? null,
        destination: typeof destination === "string" ? destination : destination?.description,
        locateBrokerId: locateBrokerIds ? locateBrokerIds.join(",") : "",
    });
}
export function convertFormValueToIRSOrder(data) {
    let formValues = data;
    formValues = omit(data, ["ccpShortName", "fcmdcmShortName", "irsInstrument"]);
    const { destination, targetCompId, locateBrokerIds, ...orderValues } = omit(formValues, [
        "orderType",
        "limitPrice",
        "stopPrice",
        "calculatorType",
        "locateBrokerId",
        "locateId",
        "timeInfForce",
        "swapOrder",
        "offeringType",
        "strategy",
        "capacity",
        "darkPools",
        "allocationSchemeId",
    ]);
    return replaceEmptyStringValuesWithNull({
        ...orderValues,
        calculatorType: null,
        targetCompId: targetCompId ?? null,
        destination: typeof destination === "string" ? destination : destination?.description,
        locateBrokerId: locateBrokerIds ? locateBrokerIds.join(",") : null,
    });
}
export const convertCDXOrderToFormValues = (order, defaults = initialCDXOrderFormState) => {
    const { counterpartyMemo, webOrderType, quantity, ...rest } = order;
    const formValues = {
        counterpartyMemo: counterpartyMemo ?? "",
        quantity: quantity ? `${quantity}` : "0",
        webOrderType: webOrderType,
        ...rest,
    };
    return {
        ...defaults,
        ...formValues,
    };
};
export const convertIRSOrderToFormValues = (order, defaults = initialIRSOrderFormState) => {
    const { counterpartyMemo, webOrderType, quantity, tenor, rate, ...rest } = order;
    const formValues = {
        counterpartyMemo: counterpartyMemo ?? "",
        quantity: quantity ? `${quantity}` : "0",
        webOrderType: webOrderType,
        tenor: tenor === "NullPeriod" ? null : tenor,
        rate: !rate ? 0 : rate * 100,
        ...rest,
    };
    return {
        ...defaults,
        ...formValues,
    };
};
export const convertFormValueToCDXSplitOrder = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
formState, orderId) => {
    return convertFormValueToCDXOrder({
        ...formState,
        parentOrderId: orderId,
    });
};
export function convertFormValueToVarSwapOrder(data) {
    let formValues = data;
    const { destination, targetCompId, locateBrokerIds, ...orderValues } = omit(formValues, [
        "orderType",
        "limitPrice",
        "stopPrice",
        "calculatorType",
        "locateBrokerId",
        "locateId",
        "timeInfForce",
        "swapOrder",
        "offeringType",
        "strategy",
        "capacity",
        "darkPools",
        "allocationSchemeId",
    ]);
    return replaceEmptyStringValuesWithNull({
        ...orderValues,
        calculatorType: null,
        targetCompId: targetCompId ?? null,
        destination: typeof destination === "string" ? destination : destination?.description,
        locateBrokerId: locateBrokerIds ? locateBrokerIds.join(",") : null,
    });
}
export const convertFormValueToIRSSplitOrder = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
formState, orderId) => {
    return convertFormValueToIRSOrder({
        ...formState,
        parentOrderId: orderId,
    });
};
export const convertVarSwapOrderToFormValues = (order, defaults = initialVarSwapOrderFormState) => {
    const { counterpartyMemo, webOrderType, quantity, strike, lowerCap, upperCap, indicativeStrike, ...rest } = order;
    const formValues = {
        counterpartyMemo: counterpartyMemo ?? "",
        quantity: quantity ? `${quantity}` : "0",
        webOrderType: webOrderType,
        strike: !strike ? 0 : strike * 100,
        lowerCap: !lowerCap ? 0 : lowerCap * 100,
        upperCap: !upperCap ? 0 : upperCap * 100,
        indicativeStrike: !indicativeStrike ? 0 : indicativeStrike * 100,
        ...rest,
    };
    return {
        ...defaults,
        ...formValues,
    };
};
export const convertFormValueToVarSwapSplitOrder = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
formState, orderId) => {
    return convertFormValueToVarSwapOrder({
        ...formState,
        parentOrderId: orderId,
    });
};
export const omitEmptyAllocationRows = (allocationEntries) => {
    const validAllocationEntries = allocationEntries.filter((entries) => entries.accountId ||
        entries.allocationQuantity !== 0 ||
        entries.borrowAgreementId ||
        entries.portfolioTotalReturnSwapId ||
        entries.positionBlock ||
        entries.dealId);
    return validAllocationEntries;
};
export const getUpdatedAllocationHierarchies = (allocationEntries, strategyBookId) => allocationEntries?.map((entry) => ({ ...entry, bookId: strategyBookId }));
export const isBookHierarchyInconsistent = (allocationEntries, strategyBookId) => {
    const validAccountAllocationEntires = omitEmptyAllocationRows(allocationEntries);
    const hasBlankHierarchy = omitEmptyAllocationRows(allocationEntries).some((allocationEntry) => !allocationEntry.bookId);
    return validAccountAllocationEntires.some((allocationEntry) => allocationEntry.bookId &&
        (allocationEntry.bookId !== strategyBookId || hasBlankHierarchy));
};
export const irsTenors = [
    { label: "6M", value: "6M" },
    { label: "12M", value: "12M" },
    { label: "18M", value: "18M" },
    { label: "2Y", value: "2Y" },
    { label: "3Y", value: "3Y" },
    { label: "4Y", value: "4Y" },
    { label: "5Y", value: "5Y" },
    { label: "6Y", value: "6Y" },
    { label: "7Y", value: "7Y" },
    { label: "8Y", value: "8Y" },
    { label: "9Y", value: "9Y" },
    { label: "10Y", value: "10Y" },
    { label: "12Y", value: "12Y" },
    { label: "15Y", value: "15Y" },
    { label: "20Y", value: "20Y" },
    { label: "25Y", value: "25Y" },
    { label: "30Y", value: "30Y" },
    { label: "40Y", value: "40Y" },
    { label: "50Y", value: "50Y" },
];
export const getAllocationAndQtyValues = (getOptimizedQty, allocationQuantity, quantity, type, allocationNumberType) => {
    const res = {
        allocationQuantityDisplay: type === ALLOCATION_TYPE.absolute
            ? allocationQuantity * quantity
            : allocationQuantity * 100,
        qtyPct: type === ALLOCATION_TYPE.absolute
            ? allocationQuantity * 100
            : getOptimizedQty(quantity, allocationQuantity),
    };
    // When template type "Absolute", allocationQuantity is absolute value
    if (allocationNumberType === ALLOCATION_TYPE.absolute) {
        const ratio = quantity ? allocationQuantity / quantity : 0;
        if (type === ALLOCATION_TYPE.absolute) {
            res.allocationQuantityDisplay = allocationQuantity;
            res.qtyPct = ratio * 100;
        }
        else {
            // Need optimized quantity when "Percent" type is selected
            res.allocationQuantityDisplay = ratio * 100;
            res.qtyPct = getOptimizedQty(quantity, ratio);
        }
    }
    return res;
};
export const getAllocationAndQtyFromInput = (newValue, quantity, type, templateNumberType) => {
    const absolute = type === ALLOCATION_TYPE.absolute
        ? newValue
        : (newValue / 100) * (quantity ?? 1);
    const ratio = type === ALLOCATION_TYPE.absolute
        ? quantity
            ? newValue / quantity
            : 0
        : newValue / 100;
    if (templateNumberType === ALLOCATION_TYPE.absolute) {
        return {
            allocationQuantity: absolute,
            qtyPct: ratio * 100,
        };
    }
    return {
        allocationQuantity: ratio,
        qtyPct: absolute,
    };
};
export const convertAllocationEntriesQuantityToRatio = (allocationEntries, quantity) => {
    return allocationEntries.map((entry) => ({
        ...entry,
        allocationQuantity: entry.allocationQuantity / quantity,
    }));
};
export function getCanCreateOrders(user) {
    if (!user)
        return false;
    return (!!user?.adminUser ||
        (checkUserEnabled(user, "OMS") &&
            !checkUserReadOnly(user, "OMS") &&
            checkUserPermission(user, "CreateExecutionOrder") === true));
}
export function getCanUpdateOrders(user) {
    if (!user)
        return false;
    return (!!user?.adminUser ||
        (checkUserEnabled(user, "OMS") &&
            !checkUserReadOnly(user, "OMS") &&
            checkUserPermission(user, "UpdateExecutionOrder") === true));
}
export function getUniqueMessageList(list) {
    const set = new Set();
    list.forEach((message) => {
        if (message.startsWith("Order."))
            message = message.substring(6, message.length);
        if (message.startsWith("Allocation Info."))
            message = message.substring(16, message.length);
        set.add(message);
    });
    return [...set];
}
export const sideDisplaySettings = (option = false) => ({
    Buy: { name: option ? "Buy to Open" : "Buy", color: "success" },
    BuyToCover: {
        name: option ? "Buy to Close" : "Buy To Cover",
        color: "success",
    },
    BuyProtection: {
        name: "Buy Protection",
        color: "success",
    },
    BuyToOpen: {
        name: "Buy To Open",
        color: "success",
    },
    SellToClose: {
        name: "Sell to Close",
        color: "danger",
    },
    BuyToClose: {
        name: "Buy To Close",
        color: "danger",
    },
    SellToOpen: {
        name: "Sell To Open",
        color: "success",
    },
    PayFixed: {
        name: "Pay Fixed",
        color: "success",
    },
    UnwindPayFixed: {
        name: "Unwind Pay Fixed",
        color: "danger",
    },
    ReceiveFixed: {
        name: "Receive Fixed",
        color: "danger",
    },
    UnwindReceiveFixed: {
        name: "Unwind Receive Fixed",
        color: "success",
    },
    Sell: { name: option ? "Sell to Close" : "Sell", color: "danger" },
    SellShort: { name: option ? "Sell to Open" : "Sell Short", color: "danger" },
    SellProtection: {
        name: "Sell Protection",
        color: "danger",
    },
});
export const workflowStateOptions = (hasFinalReview = false) => {
    return [
        { value: "New", label: "New" },
        { value: "UnderReview", label: "Under Review" },
        ...(hasFinalReview
            ? [
                { value: "FinalReview", label: "Final Review" },
                { value: "Reviewed", label: "Reviewed" },
            ]
            : [{ value: "Reviewed", label: "Reviewed" }]),
    ];
};
export const optionsOrderSideOptions = [
    {
        value: "Buy",
        display: "BTO",
        label: "BTO",
        selectedColor: "success",
    },
    {
        value: "Sell",
        display: "STC",
        label: "STC",
        selectedColor: "danger",
    },
    {
        value: "SellShort",
        display: "STO",
        label: "STO",
        selectedColor: "danger",
    },
    {
        value: "BuyToCover",
        display: "BTC",
        label: "BTC",
        selectedColor: "success",
    },
];
export const limitedOptionsWithFullDisplay = [
    {
        value: "Buy",
        display: "Buy",
        label: "B",
        selectedColor: "success",
    },
    {
        value: "Sell",
        display: "Sell",
        label: "S",
        selectedColor: "danger",
    },
];
export const limitedOptions = [
    {
        value: "Buy",
        display: "B",
        label: "B",
        selectedColor: "success",
    },
    {
        value: "Sell",
        display: "S",
        label: "S",
        selectedColor: "danger",
    },
];
export const cdxOptions = [
    {
        value: "BuyProtection",
        display: "Buy Protection",
        label: "Buy Protection",
        selectedColor: "success",
    },
    {
        value: "SellProtection",
        display: "Sell Protection",
        label: "Sell Protection",
        selectedColor: "danger",
    },
];
export const varSwapOptions = (isMobile) => [
    {
        value: "BuyToOpen",
        display: "Buy to Open",
        label: isMobile ? "BTO" : "Buy to Open",
        selectedColor: "success",
    },
    {
        value: "SellToClose",
        display: "Sell to Close",
        label: isMobile ? "STC" : "Sell to Close",
        selectedColor: "danger",
    },
    {
        value: "BuyToClose",
        display: "Buy to Close",
        label: isMobile ? "BTC" : "Buy to Close",
        selectedColor: "danger",
    },
    {
        value: "SellToOpen",
        display: "Sell to Open",
        label: isMobile ? "STO" : "Sell to Open",
        selectedColor: "success",
    },
];
export const irsOptions = (isMobile) => [
    {
        value: "PayFixed",
        display: "Pay Fixed",
        label: isMobile ? "PF" : "Pay Fixed",
        selectedColor: "success",
    },
    {
        value: "UnwindPayFixed",
        display: "Unwind Pay Fixed",
        label: isMobile ? "UPF" : "Unwind Pay Fixed",
        selectedColor: "danger",
    },
    {
        value: "ReceiveFixed",
        display: "Receive Fixed",
        label: isMobile ? "RF" : "Receive Fixed",
        selectedColor: "danger",
    },
    {
        value: "UnwindReceiveFixed",
        display: "Unwind Receive Fixed",
        label: isMobile ? "URF" : "Unwind Receive Fixed",
        selectedColor: "success",
    },
];
export const orderSideOptions = [
    ...limitedOptions,
    {
        value: "SellShort",
        display: "SS",
        label: "SS",
        selectedColor: "danger",
    },
    {
        value: "BuyToCover",
        display: "BC",
        label: "BC",
        selectedColor: "success",
    },
];
export const getOrderSideOptions = (optionOrder, isMobile, params = {}) => {
    if (optionOrder)
        return optionsOrderSideOptions;
    if (params.limited && params.fullDisplay)
        return limitedOptionsWithFullDisplay;
    if (params.limited)
        return limitedOptions;
    if (params.cdx)
        return cdxOptions;
    if (params.varSwap)
        return varSwapOptions(isMobile);
    if (params.irs)
        return irsOptions(isMobile);
    return orderSideOptions;
};
export const complianceErrorMessage = (message) => {
    if (!message)
        return "";
    let complianceErrorMessage = "";
    if (message?.includes("lacks the needed 'UpdateExecutionOrder' permission"))
        complianceErrorMessage = "User is not permissioned to update orders.";
    return complianceErrorMessage;
};
export const getStrategyOptions = (strategyBooks) => strategyBooks
    ? strategyBooks.map((strategyBook) => {
        return { label: strategyBook.name, value: strategyBook.id };
    })
    : [];
