import { useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";
export function useGridApi(onGridReady, onGridPreDestroyed) {
    const gridApiRef = React.useRef();
    const handleGridReady = useRefCallback((event) => {
        gridApiRef.current = event;
        onGridReady?.(event);
    }, [onGridReady]);
    const handleGridPreDestroyed = useRefCallback((event) => {
        gridApiRef.current = undefined;
        onGridPreDestroyed?.(event);
    }, [onGridPreDestroyed]);
    return React.useMemo(() => ({
        gridApiRef,
        onGridReady: handleGridReady,
        onGridPreDestroyed: handleGridPreDestroyed,
    }), [handleGridReady, handleGridPreDestroyed]);
}
