import { useRefCallback, useRefState } from "@enfusion-ui/hooks";
import { bigValue, formatNumber, isNotNull, parseStringToNumber, } from "@enfusion-ui/utils";
import BigNumber from "bignumber.js";
import * as React from "react";
export const defaultFormatValue = (num, fractionDigits, style) => {
    return formatNumber(num, { formatNegative: false, fractionDigits, style });
};
function formatDisplay(value, formatValue, fractionDigits, style) {
    return value !== null && typeof value !== "undefined"
        ? formatValue?.(value, fractionDigits, style) ?? value.toString()
        : null;
}
export const useNumericInputLogic = ({ value, fractionDigits, formatStyle, onChange, enableMultiplier = false, step = 1, forceStep = false, defaultValue = null, formatValue = defaultFormatValue, min, max, asNumber = true, onFocus, onBlur, onDirectChange, }) => {
    const [displayValue, setDisplayValueBase] = React.useState(() => formatDisplay(isNotNull(value) ? new BigNumber(value).valueOf() : null, formatValue, fractionDigits, formatStyle));
    const displayValueRef = React.useRef(null);
    const setDisplayValue = useRefCallback((val) => {
        displayValueRef.current = val;
        setDisplayValueBase(val);
    }, [setDisplayValueBase, displayValueRef]);
    const [hasError, setHasError] = React.useState(false);
    const timeOutRef = React.useRef(-1);
    const [selectionState, setSelectionState] = useRefState({
        start: displayValue?.length || 0,
        end: displayValue?.length || 0,
    });
    const callChange = useRefCallback((val) => {
        onChange(val === null ? val : asNumber ? Number(val) : val);
    }, [asNumber, onChange]);
    React.useEffect(() => {
        let val = bigValue(value);
        if (value === null) {
            val = bigValue(defaultValue);
            displayValueRef.current = null;
            callChange(val);
        }
        if (displayValueRef.current === null) {
            const newValue = formatDisplay(val, formatValue, fractionDigits, formatStyle);
            setDisplayValue(newValue);
            displayValueRef.current = null;
            setSelectionState({
                start: newValue?.length || 0,
                end: newValue?.length || 0,
            });
        }
    }, [
        value,
        displayValueRef,
        setDisplayValue,
        defaultValue,
        formatValue,
        setSelectionState,
    ]);
    const handleHasError = useRefCallback(() => {
        setHasError(true);
        const val = bigValue(defaultValue);
        callChange(val);
    }, [setHasError, defaultValue]);
    const triggerChange = useRefCallback((val) => {
        setHasError(false);
        const newVal = forceStep ? val.minus(val.mod(step)) : val;
        const newDisplayValue = formatDisplay(newVal.valueOf(), formatValue, fractionDigits, formatStyle);
        setDisplayValue(newDisplayValue);
        displayValueRef.current = null;
        setSelectionState({
            start: newDisplayValue?.length || 0,
            end: newDisplayValue?.length || 0,
        });
        callChange(newVal.valueOf());
    }, [
        setHasError,
        forceStep,
        step,
        formatValue,
        fractionDigits,
        formatStyle,
        setSelectionState,
    ]);
    const clearInputValue = useRefCallback(() => {
        setHasError(false);
        const defVal = bigValue(defaultValue);
        const asStr = formatDisplay(defVal, formatValue, fractionDigits, formatStyle);
        setDisplayValue(asStr);
        displayValueRef.current = null;
        callChange(defVal);
        setSelectionState({ start: asStr?.length || 0, end: asStr?.length || 0 });
    }, [
        setHasError,
        setDisplayValue,
        displayValueRef,
        defaultValue,
        formatValue,
        fractionDigits,
        formatStyle,
        setSelectionState,
    ]);
    const handleInputFocus = useRefCallback((event) => {
        setDisplayValue(value?.toString() ?? "");
        onFocus?.(event);
    }, [setDisplayValue, value, onFocus]);
    const handleTriggerChange = useRefCallback(() => {
        if (typeof displayValue === "string") {
            try {
                const newVal = parseStringToNumber(displayValue, {
                    enableMultiplier,
                    min,
                    max,
                });
                if (newVal === null) {
                    clearInputValue();
                }
                else {
                    triggerChange(newVal);
                }
            }
            catch {
                handleHasError();
            }
        }
    }, [
        displayValue,
        clearInputValue,
        triggerChange,
        handleHasError,
        enableMultiplier,
        min,
        max,
    ]);
    const handleInputBlur = useRefCallback((event) => {
        handleTriggerChange();
        onBlur?.(event);
    }, [handleTriggerChange, onBlur]);
    const handleInputChange = useRefCallback((val) => {
        setDisplayValue(val);
        setHasError(false);
        onDirectChange?.(val);
    }, [setDisplayValue, setHasError, handleTriggerChange, onDirectChange]);
    const stepValue = useRefCallback((amount) => {
        const val = parseStringToNumber(displayValue, {
            enableMultiplier,
            min,
            max,
        });
        let newAmount = (val ?? new BigNumber(0)).plus(amount);
        if (typeof min !== "undefined") {
            newAmount = BigNumber.maximum(min, newAmount);
        }
        if (typeof max !== "undefined") {
            newAmount = BigNumber.minimum(max, newAmount);
        }
        triggerChange(newAmount);
    }, [triggerChange, displayValue, min, max, enableMultiplier]);
    const handleStepPress = useRefCallback((amount) => {
        stepValue(amount);
    }, [stepValue]);
    const doStepLogPress = useRefCallback((amount, time) => {
        stepValue(amount);
        const nextTime = Math.max(1, time - 25);
        timeOutRef.current = setTimeout(() => doStepLogPress(nextTime === 1 && time !== 1 ? amount * 2 : amount, nextTime), time);
    }, [stepValue]);
    const handleStepLongPress = useRefCallback((amount) => {
        doStepLogPress(amount, 200);
    }, [doStepLogPress]);
    const handleStepPressOut = () => clearTimeout(timeOutRef.current);
    // const inputErrors = errors || {};
    return {
        hasError,
        handleStepPress,
        handleStepLongPress,
        handleStepPressOut,
        handleInputBlur,
        handleInputFocus,
        handleInputChange,
        clearInputValue,
        displayValue: displayValue ?? "",
        setDisplayValue,
        selectionState,
        setSelectionState,
    };
};
