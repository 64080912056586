import { DimensionsProvider } from "@enfusion-ui/core";
import { createTestId } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
// This import path is required for jest
import { Button } from "../control";
import { ControlledInputBase } from "../forms/ControlledInputs";
import { FolderSelector } from "../inputs/FolderSelector";
import { ControlledTextInput } from "../inputs/TextInput";
import { SingleColumnGrid, SingleColumnGridWithGap } from "../Styled";
import { Modal } from "./Modal";
const ContentContainer = styled(SingleColumnGridWithGap) `
  margin-top: 8px;
  font-size: 1.2em;
`;
const ErrorContainer = styled.div `
  background-color: var(--danger);
  color: var(--text-normal);
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--text-input-border);
  padding: 0.5em;
  font-size: 0.75em;
`;
const ErrorMessage = styled.span `
  font-weight: bold;
  margin-left: 0.25em;
`;
export const CreateFolderModal = ({ open, folderPath, onCancel, root, onSubmit, }) => {
    return (React.createElement(Modal, { isOpen: open, onClose: onCancel, title: "Create Folder", allowDismissal: true, content: React.createElement(DimensionsProvider, null,
            React.createElement(ContentContainer, null,
                React.createElement(CreateFolderForm, { onSubmit: onSubmit, folderPath: folderPath, root: root }))) }));
};
const CreateFolderForm = ({ folderPath, onSubmit, root }) => {
    const formMethods = useForm();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const submitMiddleware = async (args) => {
        setLoading(true);
        setError(false);
        const { folderName, folder } = args;
        try {
            if (folderName === "") {
                throw error;
            }
            let path = folder ? folder.path : "";
            if (typeof path !== "undefined" && path !== "/" && path !== "") {
                path = root ? path.substring(path?.indexOf("/") + 1) : path;
            }
            if (path !== "" && path.charAt(0) === "/") {
                path = path.substring(1);
            }
            if (path.charAt(path.length - 1) !== "/") {
                path += "/";
            }
            await onSubmit(folderName, path);
        }
        catch (error) {
            setError(true);
        }
        finally {
            setLoading(false);
        }
    };
    return (React.createElement(FormProvider, { ...formMethods },
        React.createElement(SingleColumnGridWithGap, { as: "form", onSubmit: formMethods.handleSubmit(submitMiddleware) },
            React.createElement(SingleColumnGrid, null,
                error && (React.createElement(ErrorContainer, null,
                    React.createElement(FontAwesomeIcon, { icon: faExclamationCircle, size: "lg" }),
                    React.createElement(ErrorMessage, null, "Invalid Folder Name"))),
                React.createElement(ControlledInputBase, { name: "folder", render: ({ onChange }) => (React.createElement(FolderSelector, { label: "Path", onSelect: onChange, root: root, defaultValue: folderPath, clearable: true, "data-testid": createTestId("modal-create-folder-path-input") })), defaultValue: "" }),
                React.createElement(ControlledTextInput, { label: "Folder Name", name: "folderName", defaultValue: "", dataTestId: createTestId("folder-name-text-input") })),
            React.createElement(Button, { primary: true, type: "submit", busy: loading, dataTestId: createTestId("create-folder-submit-button") }, "Submit"))));
};
