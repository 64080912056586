/* eslint-disable @typescript-eslint/no-explicit-any */
import { useControllerProps, useFormInputValidation, } from "@enfusion-ui/core";
import { createRefList, useRefCallback } from "@enfusion-ui/hooks";
import { forwardRef } from "@enfusion-ui/utils";
import { identity } from "lodash";
import * as React from "react";
import { Controller, useWatch, } from "react-hook-form";
import { FormElement } from "../layout/FormElement";
export const ControlledInputBase = ({ render = () => React.createElement(React.Fragment, null), required, rules, ...props }) => {
    const { rules: resRules, errors } = useFormInputValidation({
        required,
        rules,
    });
    const handleRender = useRefCallback((props, state) => render({ ...props, errors, required: !!rules?.required }, state), [render, errors]);
    return React.createElement(Controller, { ...props, rules: resRules, render: handleRender });
};
export const ControlledEmpty = (props) => (React.createElement(ControlledInputBase, { ...props, render: () => React.createElement(React.Fragment, null) }));
const FormElementPlaceholder = ({ children }) => React.createElement(React.Fragment, null, children);
const FormElementPropList = [
    "className",
    "basis",
    "controllerStyle",
    "mobileBasis",
    "tabletBasis",
    "desktopBasis",
    "inputId",
];
export function createControlled() {
    function create(Component, modifyProps = identity, options) {
        const { useFormElement = true, defaultName = "", passRef = false, overwriteOnChange = false, defaultWatch = false, defaultNullValue, } = options || {};
        const Wrapper = !!useFormElement ? FormElement : FormElementPlaceholder;
        return forwardRef(({ nullValue = defaultNullValue, watch = defaultWatch, ...props }, ref) => {
            const name = React.useMemo(() => (props.name || defaultName), [props.name, defaultName]);
            const [controllerProps, inputProps, formElementProps] = useControllerProps({ ...props, name }, (useFormElement ? FormElementPropList : []));
            const watchedValue = useWatch({ name }) ?? nullValue;
            const getProps = useRefCallback((field, state) => modifyProps({
                ...field,
                ...inputProps,
                defaultValue: controllerProps.defaultValue,
                nullValue,
                onChange: overwriteOnChange
                    ? inputProps.onChange ?? field.onChange
                    : (e) => {
                        field.onChange(e);
                        inputProps.onChange?.(e);
                    },
                ref: passRef ? createRefList(ref, field.ref) : ref,
                value: (watch
                    ? watchedValue
                    : field.value ?? nullValue),
            }, state), [
                inputProps,
                nullValue,
                watch ? watchedValue : null,
                controllerProps.defaultValue,
            ]);
            return (React.createElement(Wrapper, { ...formElementProps },
                React.createElement(ControlledInputBase, { ...controllerProps, render: (field, state) => (React.createElement(Component, { ...getProps(field, state) })) })));
        });
    }
    return create;
}
export const FormController = ({ mobileBasis, tabletBasis, desktopBasis, basis, children, style, inputId, ...props }) => {
    return (React.createElement(FormElement, { style: style, basis: basis, inputId: inputId, mobileBasis: mobileBasis, tabletBasis: tabletBasis, desktopBasis: desktopBasis },
        React.createElement(ControlledInputBase, { ...props }, children)));
};
