import BigNumber from "bignumber.js";
import { identity } from "lodash";
import { countDecimalPlaces, parseNumber } from "./formatters";
export const MULTIPLIERS = {
    h: 100,
    t: 1000,
    k: 1000,
    m: 1e6,
    b: 1e9,
    T: 1e12,
    hundred: 100,
    thousand: 1000,
    million: 1e6,
    billion: 1e9,
    trillion: 1e12,
};
const OPERATOR_KEYS = ["*", "/", "+", "-", "(", ")"];
function parseMulti(str, allowDecimals = true) {
    let multiplier = 1;
    if (str.length > 1) {
        const lastChar = str[str.length - 1].toLowerCase();
        multiplier =
            MULTIPLIERS[lastChar] !== undefined ? MULTIPLIERS[lastChar] : 1;
    }
    const parsedValue = parseNumber(str);
    const evaluated = parsedValue.isNaN()
        ? null
        : parsedValue.multipliedBy(multiplier);
    if (evaluated === null)
        return null;
    return allowDecimals
        ? evaluated
        : evaluated.decimalPlaces(0, BigNumber.ROUND_FLOOR);
}
const spaceRegex = /\s+/g;
const negExpRegex = /e-/g;
const negExpReplacement = "e{";
const posExpRegex = /e\+/g;
const posExpReplacement = "e}";
export function parseInput(str, enableMultiplier, skipOperatorEval, allowDecimals = true, extraParser = identity) {
    if (!str)
        return null;
    const val = extraParser ? extraParser(str) : str;
    if (!enableMultiplier) {
        const res = parseNumber(val);
        const decimalPlaces = countDecimalPlaces(val);
        return skipOperatorEval ? res.toFixed(decimalPlaces) : res;
    }
    let stripedString = val.replace(spaceRegex, "");
    if (stripedString.startsWith("+") || stripedString.startsWith("-"))
        stripedString = `0${stripedString}`;
    while (stripedString.endsWith("=")) {
        stripedString = stripedString.slice(0, -1);
    }
    stripedString = stripedString
        .replace(negExpRegex, negExpReplacement)
        .replace(posExpRegex, posExpReplacement); // exponential values
    const list = OPERATOR_KEYS.reduce((newStr, key) => newStr.replaceAll(key, ` ${key} `), stripedString)
        .trim()
        .replace(spaceRegex, " ")
        .replace(new RegExp(negExpReplacement, "g"), "e-")
        .replace(new RegExp(posExpReplacement, "g"), "e+")
        .split(" ")
        .map((i) => {
        if (OPERATOR_KEYS.includes(i))
            return i;
        const places = countDecimalPlaces(i);
        return (parseMulti(i, allowDecimals) ?? new BigNumber(0)).toFixed(places);
    });
    try {
        if (skipOperatorEval)
            return list.join("");
        // eslint-disable-next-line no-new-func
        return new BigNumber(Function(`return ${list.join("")};`)());
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error("parseInput err", `${list.join("")}`, err);
        return null;
    }
}
export const parseStringToNumber = (val, { increment = 0, enableMultiplier = false, allowDecimals = true, min, max, extraParser, } = {}) => {
    if (!val && val !== 0)
        return null;
    const newValue = typeof val === "string"
        ? parseInput(val, enableMultiplier, false, allowDecimals, extraParser)
        : new BigNumber(val);
    if (newValue !== null && !Number.isNaN(newValue)) {
        const numStepDecimal = countDecimalPlaces(new BigNumber(increment));
        const numValueDecimal = countDecimalPlaces(newValue);
        const numDecimal = numStepDecimal >= numValueDecimal ? numStepDecimal : numValueDecimal;
        const multiplier = 10 ** numDecimal;
        let finalVal = numDecimal === 0
            ? newValue.plus(increment)
            : new BigNumber(newValue
                .plus(increment)
                .multipliedBy(multiplier)
                .toFixed(numDecimal, 1)).dividedBy(multiplier);
        if (typeof min !== "undefined" && finalVal.isLessThan(min)) {
            finalVal = new BigNumber(min);
        }
        if (typeof max !== "undefined" && finalVal.isGreaterThan(max)) {
            finalVal = new BigNumber(max);
        }
        return finalVal;
    }
    throw new Error("Not a valid number");
};
export function bigValue(value) {
    return value !== null ? new BigNumber(value).valueOf() : null;
}
