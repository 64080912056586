import EventEmitter from "eventemitter3";
import { noop } from "lodash";
export class BasicInterval extends EventEmitter {
    callback = noop;
    interval = 100;
    runBeforeFirstInterval = true;
    timer = -1;
    constructor(callback, interval, runBeforeFirstInterval = true) {
        super();
        this.callback = callback;
        this.interval = interval;
        this.runBeforeFirstInterval = runBeforeFirstInterval;
    }
    run = () => {
        this.emit("run");
        this.callback();
    };
    start = (interval) => {
        if (typeof interval === "number")
            this.interval = interval;
        this.emit("start");
        this.timer = setInterval(this.run, this.interval);
        if (this.runBeforeFirstInterval)
            this.run();
    };
    stop = () => {
        this.emit("stop");
        clearInterval(this.timer);
    };
}
