import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import { createTestId } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import { faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Portal } from "../portal/Portal";
const Container = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);

  font-size: 0.75rem;
  font-weight: 700;
  white-space: nowrap;
  min-width: ${({ minWidth }) => (!!minWidth ? minWidth : "unset")};
`;
const ButtonContainer = styled.button `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing);

  font: inherit;
  border: none;
  background: none;
  color: ${({ color }) => color ?? "var(--text-normal)"};

  :hover {
    cursor: pointer;
  }
`;
const Icon = styled(FontAwesomeIcon) `
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "rotate(0)")};
`;
const MenuContainer = styled.div `
  overflow: hidden;
  font-size: 0.875rem;
  color: var(--text-normal);
  border-radius: var(--radius);
  background-color: var(--background-color-0);

  max-height: 320px;
  overflow-y: auto;
`;
const MenuItemContainer = styled.div `
  min-width: 5rem;
  padding: var(--spacing-l) var(--spacing-xl);
  background-color: ${({ selected }) => selected ? "var(--primary-hover)" : "inherit"};

  :hover {
    background-color: var(--primary);
    cursor: pointer;
  }
`;
export function TextButtonDropdown({ value, label, options, onChange, color, minWidth, dataTestId = "text-button-dropdown", }) {
    const menuState = useModalState();
    const buttonRef = React.useRef(null);
    const menuContainerRef = React.useRef(null);
    const onClickMenuItem = useRefCallback((e) => {
        onChange(e.target.getAttribute("data-value"));
        menuState.closeModal();
    }, [onChange, menuState.closeModal]);
    React.useEffect(() => {
        if (menuState.open) {
            const list = Array.from(menuContainerRef.current?.children ?? []);
            const el = list.find((i) => i.getAttribute("data-selected") === "true");
            el?.scrollIntoView({ block: "center" });
        }
    }, [menuState.open]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { minWidth: minWidth },
            label ? React.createElement("label", null,
                label,
                ":") : null,
            React.createElement(ButtonContainer, { color: color, ref: buttonRef, onClick: menuState.openModal, "data-testid": createTestId(dataTestId, "button") },
                options.find((opt) => opt.value === value)?.label,
                React.createElement(Icon, { size: "sm", open: menuState.open, icon: faAngleUp }))),
        React.createElement(Portal, { open: menuState.open, topOffset: 5, watchPosition: true, attachedRef: buttonRef, onClickOutside: (e) => {
                if (buttonRef.current?.contains(e.target))
                    return;
                menuState.closeModal();
            } },
            React.createElement(MenuContainer, { ref: menuContainerRef, onClick: onClickMenuItem, "data-testid": createTestId(dataTestId, "options") }, options.map((opt, idx) => (React.createElement(MenuItemContainer, { key: idx, selected: opt.value === value, "data-value": opt.value, "data-selected": opt.value === value }, opt.label)))))));
}
