import { faDiagramPredecessor } from "@fortawesome/pro-solid-svg-icons";
export const customButtonPanel = {
    name: "Custom Button Panel",
    minSize: (mobile) => (mobile ? [2, 2] : [2, 2]),
    defaultSize: [2, 2],
    maxSize: [42, 12],
    icon: faDiagramPredecessor,
    description: "Button list that perform predefined actions",
    defaultConfig: {
        defaultValue: null,
        key: "CustomButtonPanel",
    },
    type: "display",
    category: "orderForm",
};
