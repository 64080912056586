import { has } from "lodash";
export function checkUserReadOnly(user, flag) {
    if (!user?.flags || !has(user.flags, flag))
        return false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return user.flags[flag].readOnly ?? false;
}
export function checkUserEnabled(user, flag) {
    if (!user?.flags || !has(user.flags, flag))
        return false;
    return user.flags[flag].enabled;
}
export function checkUserPermission(user, perm) {
    if (!user?.flags?.permissions || !has(user.flags.permissions, perm))
        return false;
    return user.flags.permissions[perm];
}
export function checkUserType(user, userTypeName, caseSensitive = true) {
    if (!user?.flags?.userType)
        return false;
    const userType = user.flags.userType.trim();
    return caseSensitive
        ? userType === userTypeName.trim()
        : userType.toLocaleLowerCase() === userTypeName.trim().toLocaleLowerCase();
}
export function checkUserPo(user, poName, caseSensitive = true) {
    if (!user?.flags?.processingOrgName)
        return false;
    const userPo = user.flags.processingOrgName.trim();
    return caseSensitive
        ? userPo === poName.trim()
        : userPo.toLocaleLowerCase() === poName.trim().toLocaleLowerCase();
}
export function checkUserPoId(user, poId) {
    if (typeof user?.flags?.processingOrdId !== "number")
        return false;
    return poId === user.flags.processingOrdId;
}
