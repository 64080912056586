/* eslint-disable @typescript-eslint/no-explicit-any */
import { themesForDashboard } from "@enfusion-ui/core";
import { aggregateGraphData, formatDate, formatReportValue, } from "@enfusion-ui/utils";
import { parseISO } from "date-fns";
import { orderBy, sortBy } from "lodash";
import * as React from "react";
import { getColumnDef, getDefaultColor } from "../../../../core/utils";
const defaultConfig = {
    datasourceId: "",
    subTableDatasourceChannelId: null,
    type: "bar",
    categories: [],
    series: [],
    colorByPoint: false,
};
export const useGraphData = (args = {}) => {
    const { width, height, config = defaultConfig, theme = themesForDashboard.dashDark, rows, columns, onLoad, } = args;
    const { type, title, subTitle, series, categories, timeSeries = false, yMin, xMin, datasourceId, tooltipFractions, showLegend = true, dataSorting = true, showDataLabels = false, sortByKey = 0, sortDir = "desc", aggregationMethod = "max", colorByPoint = false, } = config;
    const containerRef = React.useRef(null);
    const formatLabel = React.useCallback((value) => {
        try {
            return type.endsWith("line") && timeSeries ? formatDate(value) : value;
        }
        catch (err) {
            return value;
        }
    }, [type, timeSeries]);
    const rect = containerRef.current?.getBoundingClientRect() ||
        { width: 0, height: 0 };
    const seriesData = React.useMemo(() => {
        if (!rows || !columns || series.length === 0)
            return { series: [], categories: [], yAxisTitle: "", xAxisTitle: "" };
        const seriesKey = getColumnDef(series[0].value, columns)?.name || "";
        const { data, colors } = categories.reduce((res, cat, idx) => {
            const cbp = (colorByPoint && !type.endsWith("line")) || type === "pie";
            res.data.push({
                name: cat.label || "",
                key: getColumnDef(cat.value, columns)?.name || "",
                colorByPoint: cbp,
                zoneAxis: type === "bar" || type === "column" ? "y" : undefined,
                data: [],
                negativeColor: cbp ? undefined : cat.negativeColor || cat.color,
            });
            if (!cbp) {
                if (cat.color)
                    res.colors.push(cat.color);
                else
                    res.colors.push(getDefaultColor(idx));
            }
            return res;
        }, { data: [], colors: [] });
        const orderByKey = sortByKey > data.length ? 0 : sortByKey;
        // convert data into a structure that can be sorted and keep the name
        const dataRes = rows.reduce((res, row) => {
            if (row.columnValues[seriesKey]) {
                const name = type.endsWith("line") && timeSeries
                    ? parseISO(row.columnValues[seriesKey].value).valueOf()
                    : row.columnValues[seriesKey].value || "-";
                return [
                    ...res,
                    {
                        name,
                        data: data.reduce((result, entry) => {
                            return {
                                ...result,
                                [entry.key]: type === "pie"
                                    ? Math.abs(row.columnValues[entry.key].value)
                                    : Number(row.columnValues[entry.key].value),
                            };
                        }, {}),
                    },
                ];
            }
            return res;
        }, []);
        const sortedData = timeSeries
            ? sortBy(dataRes, ["name"])
            : dataSorting
                ? orderBy(dataRes, [`data.${data[orderByKey].key}`], [sortDir])
                : dataRes;
        // convert the sorted data into graph data structure
        for (const dataEntry of sortedData) {
            for (const entry of data) {
                entry.data.push({
                    name: dataEntry.name,
                    y: dataEntry.data[entry.key],
                });
            }
        }
        return {
            series: aggregateGraphData(data ?? [], aggregationMethod),
            xAxisTitle: series[0].label,
            yAxisTitle: "",
            colors: colors.length === 0 ? undefined : colors,
        };
    }, [
        series,
        categories,
        datasourceId,
        rows,
        JSON.stringify(Object.keys(columns ?? {})),
        type,
        timeSeries,
        dataSorting,
        sortByKey,
        sortDir,
        aggregationMethod,
        aggregateGraphData,
        colorByPoint,
    ]);
    const options = React.useMemo(() => {
        const titleAreaHeight = title ? 32 + (subTitle ? 14 : 0) : 0;
        // Currency should remain same for all the values to be plotted on the y-axis. Hence, selecting only once from the first series.
        const currency = rows?.[0]?.columnValues[seriesData.series?.[0]?.key]?.currency;
        const graphOptions = {
            series: seriesData.series,
            chart: {
                type,
                animation: false,
                width,
                // subtracting height of the title div with the container
                height: (height || titleAreaHeight) - titleAreaHeight,
                backgroundColor: theme.colors.backgroundColor1,
                events: {
                    load: onLoad,
                },
            },
            legend: {
                itemStyle: {
                    color: theme.colors.textNormal,
                },
            },
            yAxis: {
                min: yMin,
                title: {
                    text: seriesData.yAxisTitle,
                    style: {
                        color: theme.colors.textNormal,
                    },
                },
                labels: {
                    formatter: function () {
                        const self = this;
                        const col = getColumnDef(categories[0].value, columns);
                        if (!col)
                            return "Column Error";
                        return formatReportValue({
                            value: self.value,
                            currency,
                        }, col);
                    },
                    style: {
                        color: theme.colors.textNormal,
                    },
                },
            },
            xAxis: {
                title: {
                    text: seriesData.xAxisTitle,
                    style: {
                        color: theme.colors.textNormal,
                    },
                },
                labels: {
                    style: {
                        color: theme.colors.textNormal,
                    },
                },
                min: xMin,
                type: "category",
                crosshair: true,
                ...(type && type.endsWith("line") && timeSeries
                    ? {
                        type: "datetime",
                        labels: {
                            formatter: function () {
                                const self = this;
                                return seriesData.series[0].data[self.pos]
                                    ? formatLabel(new Date(seriesData.series[0].data[self.pos].name).toISOString())
                                    : "Missing Date";
                            },
                            style: {
                                color: theme.colors.textNormal,
                            },
                        },
                    }
                    : {}),
            },
            title: {
                text: "",
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    animation: false,
                    pointPadding: 0.2,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: showDataLabels,
                        formatter: function columnDataLabels() {
                            const self = this;
                            const col = getColumnDef(categories[0].value, columns);
                            if (!col)
                                return "Column Error";
                            return formatReportValue({
                                value: self.y,
                                currency,
                            }, col);
                        },
                    },
                    showInLegend: showLegend,
                },
                pie: {
                    allowPointSelect: false,
                    cursor: "default",
                    dataLabels: {
                        enabled: showDataLabels,
                    },
                },
                line: {},
                spline: {},
            },
            tooltip: {
                xDateFormat: "%b/%e/%Y",
                headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
                pointFormatter: function pointFormatter() {
                    const self = this;
                    const color = self.series.color;
                    const col = getColumnDef(categories[0].value, columns);
                    return `<tr><td style="color:${color};padding:0">${self.series.name}: </td>
               <td style="padding:0;padding-left:3px"><b>${type === "pie"
                        ? self.percentage
                        : col
                            ? formatReportValue({
                                value: self.y,
                                currency,
                            }, col)
                            : "Column Error"}${type === "pie" ? "%" : ""}</b></td></tr>`;
                },
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
        };
        if (type !== "pie" && seriesData.colors) {
            graphOptions["colors"] = seriesData.colors;
        }
        return graphOptions;
    }, [
        type,
        showDataLabels,
        tooltipFractions,
        showLegend,
        dataSorting,
        JSON.stringify(rect),
        seriesData,
        xMin,
        yMin,
        width,
        height,
        title,
        subTitle,
        theme,
    ]);
    return React.useMemo(() => ({ options: options, chartType: type }), [options, type]);
};
