import { Actions, DockLocation } from "flexlayout-react";
export function layoutModelMiddleware(state, action) {
    switch (action.type) {
        case "add": {
            const { tabs, layoutModel } = state;
            const addedTabs = action.payload
                .map(({ id }) => {
                return tabs.find(({ id: tabId }) => tabId === id);
            })
                .filter(Boolean);
            for (const tabDef of addedTabs) {
                try {
                    layoutModel?.doAction(Actions.addNode(tabDef, 
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    layoutModel.getActiveTabset().getId(), DockLocation.CENTER, -1, true));
                }
                catch (err) {
                    console.warn("Error opening tab", err);
                }
            }
            return { ...state, layoutModel };
        }
        case "remove": {
            const { id } = action.payload;
            const { layoutModel, tabs } = state;
            if (!tabs.some((i) => i.id === id)) {
                try {
                    layoutModel?.doAction(Actions.deleteTab(id));
                }
                catch (err) {
                    console.warn("Error removing tab", id, err);
                }
            }
            return { ...state, layoutModel };
        }
        case "rename":
        case "update": {
            const { id, name, unique } = action.payload;
            const { layoutModel } = state;
            try {
                if (unique)
                    layoutModel?.doAction(Actions.updateNodeAttributes(id, { id: unique }));
                layoutModel?.doAction(Actions.renameTab(unique || id, name));
            }
            catch (err) {
                console.warn("Error renaming tab", id, err);
            }
            return { ...state, layoutModel };
        }
        case "undock": {
            const { id } = action.payload;
            const { layoutModel } = state;
            try {
                layoutModel?.doAction(Actions.deleteTab(id));
            }
            catch (err) {
                console.warn("Error removing tab", id, err);
            }
            return { ...state, layoutModel };
        }
        case "dock": {
            const { id, tabSetId } = action.payload;
            const { tabs, layoutModel } = state;
            const tabDef = tabs.find(({ id: tabId }) => tabId === id);
            if (!!tabDef) {
                try {
                    const existingTabSet = layoutModel?.getNodeById(tabSetId);
                    let newTabSetId = layoutModel?.getActiveTabset().getId();
                    if (!!existingTabSet && existingTabSet.getType() === "tabset") {
                        newTabSetId = tabSetId;
                    }
                    layoutModel?.doAction(Actions.addNode(tabDef, newTabSetId, DockLocation.CENTER, -1, true));
                }
                catch (err) {
                    console.warn("Error un popping tab", err);
                }
            }
            return { ...state, layoutModel };
        }
    }
    return { ...state };
}
