/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useFormTableList, useFormTableListSetter, useMounted, useRefCallback, } from "@enfusion-ui/hooks";
import { ExecutionOrderTypes, } from "@enfusion-ui/types";
import BigNumber from "bignumber.js";
import { debounce, isEmpty, omit, pick } from "lodash";
import * as React from "react";
import { FormProvider, useForm, useFormContext, useWatch, } from "react-hook-form";
import { UNKNOWN_INSTRUMENT_ID } from "../../constants";
import { AllocationsControlContext, useGeneralizedMethods, } from "../../context";
import { TOAST_CONTENT } from "../../textConstants";
import { useInstrument } from "../hooks/useInstrument";
import { ALLOCATION_TYPE, convertAllocationEntriesQuantityToRatio, convertToDarkPool, getOrderFormType, getUniqueMessageList, omitEmptyAllocationRows, OrderFormStrategyContext, } from "../utils";
import { initialCDXOrderFormContextState, initialFXOrderFormContextState, initialIRSOrderFormContextState, initialOrderFormContextState, initialVarSwapOrderFormContextState, OrderFormContext, useOEMSOrderForm, } from "./context";
class WarningException extends Error {
    error;
    constructor(error) {
        super(error);
        this.error = error;
        this.name = "WarningException";
    }
}
const defaultGetFormState = () => Promise.resolve(null);
const overwriteIfChangedFields = [
    "quantity",
    "orderSide",
    "limitPrice",
    "webOrderType",
    "accountAllocation",
];
function getFormType(type, flags) {
    if (type === "fx" && !flags.fxAllowed)
        return "equity";
    if (type === "cdx" && !flags.cdxAllowed)
        return "equity";
    if (type === "irs" && !flags.irsAllowed)
        return "equity";
    if (type === "varSwap" && !flags.varSwapAllowed)
        return "equity";
    return type;
}
export const OrderFormProvider = ({ children, orderId = 0, splitOrder = false, formType: formTypeBase = "equity", custom = false, completed = false, isInitialized = false, submissionStatus, getFormState = defaultGetFormState, initialValues = {}, getRoutes, transmitted, isSplit = false, flags, onNewOrderFormReady, onFormChange, }) => {
    const { errorToast, successToast, warningToast, restServer, api } = useGeneralizedMethods();
    const [formType, setFormTypeBase] = React.useState(() => getFormType(formTypeBase, flags));
    const setFormType = useRefCallback((type) => {
        setFormTypeBase(getFormType(type, flags));
    }, [flags]);
    const newOrder = splitOrder || orderId === 0;
    const isMounted = useMounted();
    const isFxOrder = formType === "fx";
    const isCdxOrder = formType === "cdx";
    const isVarSwapOrder = formType === "varSwap";
    const isFutureSpreadOrder = formType === "futureSpread";
    const isIrsOrder = formType === "irs";
    // Incase of Sell orders the qty will be negative hence used absolute value
    const minQuantity = React.useMemo(() => {
        if (getRoutes && !splitOrder) {
            return Math.abs((getRoutes?.(orderId) ?? []).reduce((acc, eachRoute) => acc + (eachRoute.columnValues.Quantity ?? 0), 0));
        }
        return 0;
    }, [orderId, getRoutes, splitOrder]);
    const [state, setStateBase] = React.useState(() => {
        function res(v, f = "equity") {
            return {
                ...initialOrderFormContextState,
                formType: f,
                initialOrder: {
                    ...v,
                    ...initialValues,
                },
            };
        }
        if (isFxOrder)
            return res(initialFXOrderFormContextState.initialOrder, initialFXOrderFormContextState.formType);
        if (isCdxOrder)
            return res(initialCDXOrderFormContextState.initialOrder, initialCDXOrderFormContextState.formType);
        if (isVarSwapOrder)
            return res(initialVarSwapOrderFormContextState.initialOrder, initialVarSwapOrderFormContextState.formType);
        if (isIrsOrder)
            return res(initialIRSOrderFormContextState.initialOrder, initialIRSOrderFormContextState.formType);
        return res(initialOrderFormContextState.initialOrder, initialOrderFormContextState.formType);
    });
    const setState = useRefCallback((setter) => {
        if (isMounted())
            setStateBase(setter);
    }, [isMounted, setStateBase]);
    const prevInitialFormState = React.useRef(JSON.stringify(state.initialOrder));
    const [submitErrors, setSubmitErrors] = React.useState(null);
    const [complianceRecords, setComplianceRecords] = React.useState(null);
    const [warningRecords, setWarningRecords] = React.useState(null);
    const [isFormBusy, setIsFormBusy] = React.useState(false);
    const [complianceFailure, setComplianceFailure] = React.useState();
    const formMethods = useForm({
        defaultValues: JSON.parse(JSON.stringify(state.initialOrder)),
    });
    const { reset, setValue, getValues, formState } = formMethods;
    const localNotional = formMethods.watch("localNotional");
    const orderFormStrategy = React.useMemo(() => OrderFormStrategyContext(formType), [formType]);
    const onOrderChange = useRefCallback(async ({ order, notional, }) => {
        const prevFormValues = getValues();
        const updatedFormValues = await orderFormStrategy.handleOrderChange(order, prevFormValues, restServer);
        const initialOrder = {
            ...updatedFormValues,
        };
        if (!isFxOrder) {
            initialOrder.notional =
                typeof notional === "number"
                    ? `${notional}`
                    : updatedFormValues.notional;
        }
        reset({ ...JSON.parse(JSON.stringify(initialOrder)) });
    }, [orderFormStrategy, getValues, setState, isFxOrder, reset]);
    const getDefaultOrderState = useRefCallback(async (id) => {
        if (isMounted()) {
            try {
                return await orderFormStrategy.getDefaultOrderState(restServer, id);
            }
            catch (error) {
                throw new Error("Failed to get default order");
            }
        }
    }, [isMounted, orderFormStrategy, state.initialOrder]);
    const defaultOrderOnInstrumentChange = useRefCallback(debounce(async (instrument, dirtyKeysBase = [], dirtyValues = {}) => {
        try {
            if (newOrder && !splitOrder) {
                setState((prevSate) => ({
                    ...prevSate,
                    secondaryLoading: true,
                    error: false,
                }));
                let res = await getDefaultOrderState(instrument.id);
                // keep changed values
                const values = { ...getValues(), ...dirtyValues };
                const dirtyKeys = formState.isDirty
                    ? (function getDirtyKeys() {
                        const dirtyFields = pick(formState.dirtyFields, overwriteIfChangedFields);
                        return Object.keys(dirtyFields).reduce((res, key) => {
                            if (dirtyFields[key] === true)
                                res.push(key);
                            return res;
                        }, dirtyKeysBase);
                    })()
                    : dirtyKeysBase;
                for (let i = 0; i < dirtyKeys.length; i += 1) {
                    const key = dirtyKeys[i];
                    res[key] = values[key];
                }
                if (typeof initialValues.instrumentId !== "undefined" &&
                    Number(initialValues.instrumentId) === Number(instrument.id)) {
                    res = { ...res, ...initialValues };
                }
                reset({ ...res, instrumentId: res?.instrument?.id });
                setState((prevSate) => ({
                    ...prevSate,
                    secondaryLoading: false,
                }));
            }
        }
        catch (err) {
            errorToast(err.message);
            setState((prevState) => ({
                ...prevState,
                secondaryLoading: false,
                error: true,
            }));
        }
    }, 500), [
        getDefaultOrderState,
        initialValues,
        newOrder,
        splitOrder,
        getValues,
        formState,
        reset,
        state,
    ]);
    const submitOrder = useRefCallback(async (submissionInfo, transmit = false, acceptComplianceWarnings = false, acceptValidationWarnings) => {
        setComplianceRecords(null);
        setWarningRecords(null);
        setSubmitErrors(null);
        setIsFormBusy(true);
        if (submissionInfo.accountAllocation?.allocationEntries) {
            const validAllocationEntries = omitEmptyAllocationRows(submissionInfo.accountAllocation.allocationEntries);
            const allocationEntries = (submissionInfo.accountAllocation.allocationNumberType ===
                ALLOCATION_TYPE.absolute
                ? convertAllocationEntriesQuantityToRatio(validAllocationEntries, Number(submissionInfo.quantity))
                : validAllocationEntries).map((entry) => omit(entry, [
                "rowId",
                "custodianId",
                "fundName",
                "book",
                "allocationQuantityDisplay",
                "accountName",
                "portfolioTotalReturnSwap",
                "borrowAgreement",
            ]));
            submissionInfo = {
                ...submissionInfo,
                accountAllocation: {
                    ...submissionInfo.accountAllocation,
                    allocationEntries,
                },
            };
        }
        try {
            let response = await orderFormStrategy.submitOrder(restServer, submissionInfo, {
                transmit,
                orderId: submissionInfo.id ?? orderId,
                splitOrder,
                acceptComplianceWarnings,
                acceptValidationWarnings,
            });
            const hasComplianceResult = response.complianceResult !== null &&
                response.complianceResult.complianceRecords;
            if (hasComplianceResult)
                setComplianceRecords(response.complianceResult.complianceRecords);
            if (response.errorMessage)
                setSubmitErrors([response.errorMessage]);
            if (response.saveState.startsWith("Saved")) {
                const baseText = `Order ${splitOrder ? "split" : transmit ? "transmitted" : "staged"} successfully`;
                if (response.errorMessage && transmit) {
                    errorToast("Failed to transmit order.", response.errorMessage);
                    setComplianceFailure(response.orderInfo.id);
                    throw new WarningException("Order staged with failures");
                }
                if (hasComplianceResult &&
                    response.complianceResult.complianceState === "Failed") {
                    if (transmit) {
                        errorToast("Failed to transmit order.", response.complianceResult.firstFailedMessage);
                    }
                    setComplianceFailure(response.orderInfo.id);
                    throw new WarningException("Order staged with failures");
                }
                else {
                    if (hasComplianceResult &&
                        response.complianceResult.complianceState.startsWith("Warning")) {
                        warningToast(`${baseText} with warnings.`, `OID: ${response.orderInfo.id}`);
                        setComplianceFailure(response.orderInfo.id);
                        throw new WarningException("Order staged with warnings");
                    }
                    else {
                        successToast(`${baseText}.`, `OID: ${response.orderInfo.id}`);
                        if (!newOrder || splitOrder)
                            onOrderChange({ order: response.orderInfo });
                    }
                }
            }
            else {
                errorToast(`Failed to ${splitOrder ? "split" : "save"} order.`);
                throw new Error();
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }
        catch (err) {
            if (err?.name === "WarningException")
                throw err;
            errorToast(`Failed to ${splitOrder ? "split" : "save"} order.`);
            const errorList = getUniqueMessageList(err?.errors ?? []);
            if (!!err?.warnings?.length && errorList.length === 0)
                setWarningRecords(getUniqueMessageList(err.warnings));
            const messageList = !!err?.message
                ? getUniqueMessageList([err.message])
                : [];
            setSubmitErrors(errorList.length ? errorList : messageList);
            throw new Error();
        }
        finally {
            setIsFormBusy(false);
        }
    }, [orderId, newOrder, splitOrder, onOrderChange]);
    const fetchOMSPreferences = useRefCallback(async () => {
        try {
            return await api.OEMS.GET_OMS_PREFERENCES.FETCH();
        }
        catch (err) {
            console.error("Error loading oms preferences", err);
            throw err;
        }
    }, [api]);
    const fetchOMSSettings = useRefCallback(async () => {
        try {
            return await api.OEMS.GET_OMS_SETTINGS.FETCH();
        }
        catch (err) {
            console.error("Error loading oms settings", err);
            throw err;
        }
    }, [api]);
    const fetchInitOrder = useRefCallback(async (preferences, settings) => {
        try {
            const syncedFormState = await getFormState(orderId);
            let res = {
                initialOrder: syncedFormState,
                preferences,
                settings,
            };
            if (!syncedFormState || isEmpty(syncedFormState)) {
                if (splitOrder) {
                    const formValues = await orderFormStrategy.getSplitOrderState(restServer, orderId);
                    res.initialOrder = formValues;
                    if (formValues.quantity)
                        res.maxQuantity = Number(formValues.quantity);
                }
                else if (newOrder) {
                    const formValues = await getDefaultOrderState(state.initialOrder.instrumentId ?? UNKNOWN_INSTRUMENT_ID);
                    res.initialOrder = formValues;
                }
                else {
                    const formValues = await orderFormStrategy.getOrderState(restServer, orderId);
                    if (!!formValues.parentOrderId) {
                        const parentSplitOrderFormValues = await orderFormStrategy.getOrderState(restServer, formValues.parentOrderId);
                        if (parentSplitOrderFormValues.quantity) {
                            res.maxQuantity =
                                Number(formValues.quantity) +
                                    Number(parentSplitOrderFormValues.idleQty);
                        }
                    }
                    res.initialOrder = formValues;
                }
                res.initialOrder = {
                    ...res.initialOrder,
                    ...initialValues,
                };
            }
            setState((prevState) => ({
                ...prevState,
                ...res,
            }));
        }
        catch (err) {
            console.error(`Error loading order: ${orderId}`, err);
            throw err;
        }
    }, [newOrder, splitOrder, orderId, state.initialOrder, initialValues]);
    const initForm = useRefCallback(async () => {
        setState((prevState) => ({
            ...prevState,
            initialLoading: true,
            error: false,
        }));
        try {
            const settings = await fetchOMSSettings();
            await fetchInitOrder(await fetchOMSPreferences(), settings);
            if (newOrder)
                onNewOrderFormReady?.();
        }
        catch (err) {
            errorToast("Failed to load the order form", err.message);
            setState((prevState) => ({
                ...prevState,
                initialLoading: false,
                error: true,
            }));
        }
        finally {
            setState((prevState) => ({
                ...prevState,
                initialLoading: false,
            }));
        }
    }, []);
    React.useEffect(() => {
        if (isInitialized)
            initForm();
    }, [isInitialized]);
    React.useEffect(() => {
        const asStr = JSON.stringify(state.initialOrder);
        if (asStr !== prevInitialFormState.current) {
            prevInitialFormState.current = asStr;
            reset({ ...JSON.parse(JSON.stringify(state.initialOrder)) });
        }
    }, [JSON.stringify(state.initialOrder), reset]);
    const getCurrentOrder = useRefCallback(() => {
        return orderFormStrategy.getCurrentOrder(getValues(), orderId, splitOrder);
    }, [orderId, getValues, splitOrder, orderFormStrategy]);
    const calculateNotional = useRefCallback(debounce(async (value, modifier = 1) => {
        try {
            const order = getCurrentOrder();
            const { instrumentId, orderSide } = order;
            if (!instrumentId) {
                setValue("notionalCcy", null, {
                    shouldDirty: false,
                });
                setValue("localNotionalCcy", null, {
                    shouldDirty: false,
                });
            }
            else if (instrumentId === UNKNOWN_INSTRUMENT_ID) {
                setValue("notionalCcy", "USD", {
                    shouldDirty: false,
                });
                setValue("localNotionalCcy", "USD", {
                    shouldDirty: false,
                });
            }
            if (!value ||
                value === "0" ||
                !orderSide ||
                !instrumentId ||
                instrumentId === UNKNOWN_INSTRUMENT_ID) {
                setValue("notional", "0", {
                    shouldDirty: true,
                });
                setValue("localNotional", "0", {
                    shouldDirty: true,
                });
            }
            else if (!isFormBusy) {
                const { notional, localNotional, notionalCcy, localNotionalCcy } = await api.OEMS.GET_CALCULATED_VALUE.FETCH({
                    order: {
                        ...order,
                        quantity: value,
                        darkPools: convertToDarkPool(order?.darkPools),
                    },
                    target: value,
                    targetType: "IncrementalQuantity",
                });
                setValue("notionalCcy", notionalCcy, {
                    shouldDirty: false,
                });
                setValue("localNotionalCcy", localNotionalCcy, {
                    shouldDirty: false,
                });
                setValue("notional", notional * modifier, {
                    shouldDirty: true,
                });
                setValue("localNotional", localNotional * modifier, {
                    shouldDirty: true,
                });
            }
        }
        catch (err) {
            console.error("Failed to calculate the notional.", err);
            errorToast("Failed to calculate the notional.", err && err?.message ? err.message : undefined);
        }
    }, 1000), [api]);
    const handleQuantity = useRefCallback((value, modifier = 1) => {
        const qtyValue = value && Number(value) > 0 ? value : "0";
        setValue("quantity", qtyValue, {
            shouldDirty: true,
        });
        if (!isFxOrder && !isCdxOrder && !isVarSwapOrder && !isIrsOrder) {
            calculateNotional(qtyValue, modifier);
        }
    }, [calculateNotional, setValue, isFxOrder]);
    const calculateQuantity = useRefCallback(debounce(async (value, notionalModifier = 1) => {
        try {
            if (!value || value === "0") {
                setValue("quantity", "0", {
                    shouldDirty: true,
                });
            }
            else {
                const order = getCurrentOrder();
                const { instrumentId, orderSide } = order;
                if (instrumentId && orderSide) {
                    const result = await api.OEMS.GET_CALCULATED_VALUE.FETCH({
                        order: {
                            ...order,
                            darkPools: convertToDarkPool(order?.darkPools),
                        },
                        target: value,
                        targetType: "IncrementalNotional",
                    });
                    const val = new BigNumber(result?.order.quantity || 0);
                    if (typeof state.maxQuantity !== "undefined" &&
                        state.maxQuantity !== null &&
                        val.isGreaterThan(state.maxQuantity)) {
                        handleQuantity(state.maxQuantity, notionalModifier);
                    }
                    else {
                        setValue("quantity", val.valueOf(), {
                            shouldDirty: true,
                        });
                        setValue("notionalCcy", result.notionalCcy, {
                            shouldDirty: false,
                        });
                        setValue("localNotionalCcy", result.localNotionalCcy, {
                            shouldDirty: false,
                        });
                        setValue("notional", result.notional * notionalModifier, {
                            shouldDirty: true,
                        });
                        setValue("localNotional", result.localNotional * notionalModifier, {
                            shouldDirty: true,
                        });
                    }
                }
            }
        }
        catch (err) {
            console.error("Failed to calculate the quantity.", err);
            errorToast("Failed to calculate the quantity.", err && err?.message ? err.message : undefined);
        }
    }, 1000), [state.maxQuantity, api]);
    const handleNotional = useRefCallback((value, modifier = 1) => {
        if (Number(localNotional) !== Number(value)) {
            setValue("localNotional", value, {
                shouldDirty: true,
            });
            calculateQuantity(value, modifier);
        }
    }, [calculateQuantity, setValue, localNotional]);
    const resetForm = useRefCallback(async () => {
        reset({ ...JSON.parse(JSON.stringify(state.initialOrder)) });
        handleQuantity(state.initialOrder.quantity);
        setComplianceRecords(null);
        setWarningRecords(null);
        setSubmitErrors(null);
    }, [reset, state.initialOrder]);
    const checkIfTransmittable = useRefCallback(async (orderFormState) => {
        try {
            const order = orderFormStrategy.convertFormValuesToOrder(state.initialOrder, orderFormState);
            const response = await api.OEMS.IS_ORDER_TRANSMITTABLE.FETCH({
                ...order,
                id: splitOrder || newOrder ? 0 : orderId,
                parentOrderId: splitOrder
                    ? orderId
                    : order.parentOrderId || undefined,
            });
            return response;
        }
        catch (err) {
            console.error("Failed to check if order is transmittable", err.message, err);
            if (err.errors)
                setSubmitErrors(err.errors);
            throw err;
        }
    }, [state.initialOrder, orderId, api, orderFormStrategy, newOrder, splitOrder]);
    const keywordsControl = useFormTableList(useFormTableListSetter("keywords", formMethods.getValues, formMethods.setValue), state.initialOrder.keywords);
    const value = React.useMemo(() => {
        return {
            ...state,
            orderId,
            newOrder,
            completed,
            splitOrder,
            isFutureSpreadOrder,
            setFormType,
            submitOrder,
            defaultOrderOnInstrumentChange,
            submitErrors,
            setSubmitErrors,
            complianceRecords,
            warningRecords,
            setWarningRecords,
            setComplianceRecords,
            isFormBusy,
            setIsFormBusy,
            resetForm,
            handleNotional,
            handleQuantity,
            getCurrentOrder,
            onOrderChange,
            complianceFailure,
            setComplianceFailure,
            submissionStatus,
            isTransmitted: transmitted,
            custom,
            isSplit,
            isFxOrder,
            isCdxOrder,
            isVarSwapOrder,
            isIrsOrder,
            reInitForm: initForm,
            orderFormStrategy,
            checkIfTransmittable,
            minQuantity,
            keywordsControl,
        };
    }, [
        JSON.stringify(state),
        orderId,
        newOrder,
        completed,
        splitOrder,
        custom,
        isFutureSpreadOrder,
        setFormType,
        submitOrder,
        defaultOrderOnInstrumentChange,
        submitErrors,
        setSubmitErrors,
        complianceRecords,
        warningRecords,
        setWarningRecords,
        setComplianceRecords,
        isFormBusy,
        setIsFormBusy,
        resetForm,
        handleNotional,
        handleQuantity,
        getCurrentOrder,
        onOrderChange,
        complianceFailure,
        setComplianceFailure,
        submissionStatus,
        transmitted,
        isSplit,
        isFxOrder,
        isCdxOrder,
        isVarSwapOrder,
        isIrsOrder,
        initForm,
        checkIfTransmittable,
        minQuantity,
        orderFormStrategy,
        keywordsControl,
    ]);
    const allocationsControl = React.useMemo(() => ({
        entryType: "order",
        newEntry: newOrder,
        formType,
        isEntryCompleted: completed,
        isEntryTransmitted: transmitted ?? false,
        submitErrors,
        setSubmitErrors,
        isFormBusy,
        setIsFormBusy,
        initialAccountAllocations: state.initialOrder?.accountAllocation,
        isVarSwapEntry: isVarSwapOrder,
        isFxEntry: isFxOrder,
        isIrsEntry: isIrsOrder,
        isCdxEntry: isCdxOrder,
        isFutureSpreadEntry: isFutureSpreadOrder,
    }), [
        newOrder,
        formType,
        completed,
        transmitted,
        submitErrors,
        setSubmitErrors,
        isFormBusy,
        setIsFormBusy,
        state,
        isVarSwapOrder,
        isFxOrder,
        isIrsOrder,
        isCdxOrder,
        isFutureSpreadOrder,
    ]);
    return (React.createElement(OrderFormContext.Provider, { value: value },
        React.createElement(AllocationsControlContext.Provider, { value: allocationsControl },
            React.createElement(FormProvider, { ...formMethods },
                React.createElement(Effects, null),
                onFormChange && React.createElement(ChangeControl, { onChange: onFormChange }),
                children))));
};
const Effects = () => {
    const { api, errorToast } = useGeneralizedMethods();
    const { setFormType } = useOEMSOrderForm();
    const instrument = useInstrument();
    const { setValue } = useFormContext();
    const { orderSide, orderType, limitPrice, stopPrice } = useWatch({
        name: ["orderSide", "orderType", "limitPrice", "stopPrice"],
    });
    React.useEffect(() => {
        const des = instrument?.financialSubType?.description;
        const formType = getOrderFormType(des ? des.split(" ").join("") : undefined);
        if (instrument && instrument.id !== UNKNOWN_INSTRUMENT_ID)
            setFormType(formType);
    }, [instrument?.id]);
    React.useEffect(() => {
        const getLimitPrice = async () => {
            try {
                if (instrument &&
                    orderSide &&
                    (limitPrice === "0" || !limitPrice) &&
                    (orderType === ExecutionOrderTypes.Limit ||
                        orderType === ExecutionOrderTypes.StopLimit)) {
                    const limitPrice = await api.OEMS.GET_LIMIT_PRICE.FETCH(instrument?.id ?? UNKNOWN_INSTRUMENT_ID, orderSide, instrument.financialSubType.description.replace(/\s/g, ""));
                    setValue("limitPrice", limitPrice);
                }
            }
            catch (error) {
                errorToast(TOAST_CONTENT.oems.networkError.priceLimit.fetchFailed, error.message);
            }
        };
        getLimitPrice();
    }, [instrument?.id, orderSide, orderType, api]);
    React.useEffect(() => {
        if (orderType === ExecutionOrderTypes.Market ||
            orderType === ExecutionOrderTypes.MarketOnClose) {
            setValue("limitPrice", "0");
            setValue("stopPrice", "0");
        }
        if (orderType === ExecutionOrderTypes.MarketOnClose) {
            setValue("timeInForce", "ATC");
        }
    }, [orderType]);
    React.useEffect(() => {
        if (orderType !== ExecutionOrderTypes.StopLimit) {
            const hasLimit = !isNaN(Number(limitPrice)) && Number(limitPrice) > 0;
            const hasStop = !isNaN(Number(stopPrice)) && Number(stopPrice) > 0;
            if (hasLimit && hasStop) {
                setValue("orderType", ExecutionOrderTypes.StopLimit);
            }
            else if (hasLimit) {
                setValue("orderType", ExecutionOrderTypes.Limit);
            }
            else if (hasStop) {
                setValue("orderType", ExecutionOrderTypes.Stop);
            }
        }
    }, [limitPrice, stopPrice]);
    return null;
};
const ChangeControl = ({ onChange }) => {
    const { orderId } = useOEMSOrderForm();
    const { getValues, watch } = useFormContext();
    const res = watch();
    const triggerChange = useRefCallback(debounce(() => {
        onChange(orderId, getValues());
    }, 300), [getValues, onChange, orderId]);
    React.useEffect(() => {
        triggerChange();
    }, [res, orderId]);
    return null;
};
