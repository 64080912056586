import { createErrorBoundary, } from "@enfusion-ui/core";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { Button } from "../control";
import { ContentMessage } from "./ContentMessage";
const ErrorBoundaryContainer = styled.div `
  color: var(--background-accent);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const DefaultFallbackComponent = React.memo((props) => {
    const details = props.showDetails ? props.error?.message : undefined;
    return (React.createElement(ErrorBoundaryContainer, { style: props.style, className: props.className },
        React.createElement(ContentMessage, { icon: props.icon, message: props.message ?? "Sorry, something has gone wrong.", details: details, onlyTitleDetails: props.onlyTitleDetails, onIconClick: props.iconClickReset ? props.resetError : undefined }),
        props.showReset && (React.createElement("div", { style: !!details ? { marginTop: "var(--spacing)" } : {} },
            React.createElement(Button, { onClick: props.resetError }, "Reset")))));
});
export const ErrorBoundaryCore = createErrorBoundary(DefaultFallbackComponent);
export const ErrorBoundary = React.memo(function ErrorBoundary({ hasError, onError, onReset, children, ...props }) {
    return (React.createElement(ErrorBoundaryCore, { hasError: hasError, onError: onError, onReset: onReset, fallbackProps: props }, children));
});
