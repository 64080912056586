export const buildSelectedPGNodesHierarchy = (portfolioGroupNodes, selectedPaths) => {
    const nodeMap = new Map();
    const createNodeMap = (node) => {
        nodeMap.set(node.id, node);
        (node.nodes || []).forEach((child) => {
            createNodeMap(child);
        });
    };
    portfolioGroupNodes.forEach((node) => createNodeMap(node));
    const getFullPathNodes = (nodeId) => {
        let tempId = nodeId;
        const pathNodes = [];
        while (tempId) {
            pathNodes.unshift(tempId);
            tempId = tempId.includes("/")
                ? nodeId.substring(0, tempId.lastIndexOf("/"))
                : "";
        }
        return pathNodes;
    };
    const isFullySelected = (node) => {
        if (!node.nodes || node.nodes.length === 0)
            return false;
        return node.nodes.every((child) => selectedPaths.includes(child.id));
    };
    const buildTree = (nodeId, selectedSet) => {
        const node = nodeMap.get(nodeId);
        if (!node)
            return null;
        const res = {
            id: Number(nodeId.split("/").pop()),
            fundIds: [],
            accountIds: [],
            children: [],
        };
        if (isFullySelected(node)) {
            return res;
        }
        for (const child of node.nodes || []) {
            if (selectedSet.has(child.id)) {
                if (child.file) {
                    if (child.fundId && selectedPaths.includes(child.id))
                        res.fundIds.push(Number(child.fundId));
                    if (child.accountId && selectedPaths.includes(child.id))
                        res.accountIds.push(Number(child.accountId));
                }
                else {
                    const childNode = buildTree(child.id, selectedSet);
                    if (childNode)
                        res.children.push(childNode);
                }
            }
        }
        res.fundIds = [...new Set(res.fundIds)];
        res.accountIds = [...new Set(res.accountIds)];
        return res;
    };
    const expandedIds = new Set();
    selectedPaths.forEach((id) => getFullPathNodes(id).forEach((expandedId) => expandedIds.add(expandedId)));
    const topLevelNodes = [...expandedIds].filter((id) => ![...expandedIds].some((path) => id.startsWith(`${path}/`) && id !== path));
    return topLevelNodes
        .map((id) => buildTree(id, expandedIds))
        .filter((node) => node !== null);
};
export const getFullySelectedNodePaths = (portfolioGroupNodes, selectedNodes) => {
    const result = new Set();
    const traverseNodes = (node) => {
        if (selectedNodes.includes(node.id)) {
            result.add(node.id);
            node.nodes?.forEach((child) => {
                result.add(child.id);
                traverseNodes(child);
            });
        }
    };
    const findAndTraverseNodes = (nodes) => {
        nodes.forEach((node) => {
            if (selectedNodes.includes(node.id)) {
                traverseNodes(node);
            }
            else {
                findAndTraverseNodes(node.nodes ?? []);
            }
        });
    };
    findAndTraverseNodes(portfolioGroupNodes);
    return Array.from(result);
};
export const getSelectedPGNodesPaths = (selectedHierarchy, mainHierarchy) => {
    const selectedPaths = new Set();
    const traverse = (selectedNode, mainNode) => {
        if (!mainNode)
            return;
        const mainChildrenIds = new Set(mainNode.nodes?.map((child) => child.id.split("/").pop()) || []);
        const mainAccountIds = new Set(mainNode.accountIds || []);
        const mainFundIds = new Set(mainNode.fundIds || []);
        const selectedChildrenIds = new Set(selectedNode.children?.map((child) => child.id) || []);
        const selectedAccountIds = new Set(selectedNode.accountIds || []);
        const selectedFundIds = new Set(selectedNode.fundIds || []);
        const isFullySelected = (!selectedChildrenIds.size &&
            !selectedAccountIds.size &&
            !selectedFundIds.size) ||
            ([...mainChildrenIds].every((id) => selectedChildrenIds.has(id)) &&
                [...mainAccountIds].every((id) => selectedAccountIds.has(id)) &&
                [...mainFundIds].every((id) => selectedFundIds.has(id)));
        if (isFullySelected) {
            selectedPaths.add(mainNode.id);
        }
        else {
            selectedNode?.children.forEach((child) => {
                const correspondingMainChild = mainNode?.nodes.find((c) => Number(`${c.id}`.split("/").pop()) === child.id);
                traverse(child, correspondingMainChild);
            });
            selectedNode?.accountIds.forEach((accId) => {
                if (mainAccountIds.has(accId)) {
                    selectedPaths.add(`${mainNode.id}/${accId}`);
                }
            });
            selectedNode?.fundIds.forEach((fundId) => {
                if (mainFundIds.has(fundId)) {
                    selectedPaths.add(`${mainNode.id}/${fundId}`);
                }
            });
        }
    };
    selectedHierarchy.forEach((root) => {
        const correspondingMainRoot = mainHierarchy.find((node) => Number(`${node.id}`.split("/").pop()) === root.id);
        traverse(root, correspondingMainRoot);
    });
    const fullySelectedNodePaths = getFullySelectedNodePaths(mainHierarchy, Array.from(selectedPaths));
    return fullySelectedNodePaths;
};
