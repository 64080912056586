import { createCoreBroadcastChannel } from "@enfusion-ui/hooks";
import * as React from "react";
export const AuthContext = React.createContext(undefined);
export const AUTH_CHANNEL_NAME = "ef-auth-ch";
export const AUTH_CHANNEL = createCoreBroadcastChannel(AUTH_CHANNEL_NAME, {
    webWorkerSupport: false,
});
export function useAuth() {
    const context = React.useContext(AuthContext);
    if (context === undefined)
        throw new Error("useAuth must be used within a AuthProvider");
    return context;
}
export const ControlledFlagList = [
    "devMode",
    "oems",
    "dashboards",
    "services",
    "reports",
    "factset",
    "compliance",
    "generalFiles",
    "portfolios",
    "operationsFiles",
    "reconDashboard",
    "analyticsDashboard",
    "managedServices",
];
