import { agDateFilterComparator, formatNumber, formatReportValue, formatReportValueSimplified, } from "@enfusion-ui/utils";
import { parse } from "date-fns";
import parseISO from "date-fns/parseISO";
import { dataEntry, formatCellValues } from "../../utils";
// Column data types that should be formatted as numbers
const getReportColumnType = (alignment, dataType) => {
    if (dataType === "Double" || dataType === "Integer")
        return "numberColumn";
    if (dataType === "Date")
        return "dateColumn";
    if (alignment === "Right")
        return "standardRightColumn";
    return "standardColumn";
};
const PARENT_CELL = "agGroupCellRenderer";
export function createReportColumnDefs({ columns, columnSort, hasChildren, }) {
    let parentSet = false;
    const columnDefs = columns.map((column) => {
        const { name, displayName, dataType, columnFormat, visible } = column;
        const sortIdx = columnSort.findIndex((sortSetting) => {
            return sortSetting.columnName === name;
        });
        const sortOptions = {};
        if (sortIdx !== -1) {
            sortOptions.initialSort = columnSort[sortIdx].ascending ? "asc" : "desc";
            sortOptions.initialSortIndex = sortIdx;
        }
        const type = getReportColumnType(columnFormat?.alignment, dataType);
        const hide = "visible" in column ? !visible : false;
        let cellRenderer = dataType === "HTMLLink"
            ? "htmlLinkCellRenderer"
            : columnFormat?.name === "Quotation"
                ? "priceCellRenderer"
                : undefined;
        if (!parentSet && !hide && hasChildren && !cellRenderer) {
            parentSet = true;
        }
        const overrides = {};
        if (type === "dateColumn") {
            const { dateTimeFormat } = column;
            overrides.comparator = (valueA, valueB) => {
                return parseISO(valueA).getTime() - parseISO(valueB).getTime();
            };
            overrides.pivotComparator = (valueA, valueB) => {
                return (parse(valueA, dateTimeFormat, new Date()).getTime() -
                    parse(valueB, dateTimeFormat, new Date()).getTime());
            };
            overrides.filter = "agMultiColumnFilter";
            overrides.filterParams = {
                filters: [
                    {
                        filter: "agDateColumnFilter",
                        filterParams: {
                            comparator: (filterDate, cellValue) => {
                                if (cellValue === null)
                                    return -1;
                                return parseISO(cellValue).getTime() - filterDate.getTime();
                            },
                        },
                    },
                    {
                        filter: "agSetColumnFilter",
                        filterParams: {
                            valueFormatter: (args) => {
                                return args.value !== null && typeof args.value !== "undefined"
                                    ? formatReportValue(args.value, column)
                                    : null;
                            },
                            comparator: overrides.comparator,
                        },
                    },
                ],
            };
        }
        return {
            ...sortOptions,
            colId: name,
            headerName: displayName,
            name,
            type,
            cellDataType: type,
            pinned: cellRenderer === PARENT_CELL ? "left" : undefined,
            sortable: true,
            filter: true,
            maxWidth: cellRenderer === PARENT_CELL ? window.innerWidth / 3 : undefined,
            valueGetter: (args) => {
                return dataEntry(args).value ?? null;
            },
            valueFormatter: (args) => {
                if (args.value === null || typeof args.value === "undefined")
                    return null;
                let res = args;
                if (typeof args.value.value !== "undefined")
                    res = args.value;
                else if (!args.node?.group)
                    res = dataEntry(args);
                return formatReportValue(res, column);
            },
            filterValueGetter: (args) => {
                const data = dataEntry(args);
                if (dataType === "Double" &&
                    column?.numberStyle ===
                        "Percent" &&
                    data?.value != null) {
                    return Number(formatNumber(data.value, {
                        fractionDigits: column?.decimalPlaces ?? 2,
                        style: "percent",
                        useGrouping: false,
                    }).replace("%", ""));
                }
                return data.value || null;
            },
            cellRenderer,
            cellClassRules: {
                warningCell: (args) => dataEntry(args).status === "Warning",
                errorCell: (args) => dataEntry(args).status === "Error",
                errorText: ({ value }) => {
                    const cellValue = value?.value !== undefined ? value?.value : value;
                    if (cellValue === null)
                        return false;
                    if (columnFormat?.redIfNegative && cellValue < 0)
                        return true;
                    if (type === "numberColumn") {
                        if (columnFormat?.redIfNegative &&
                            (cellValue?.toString().includes("(") || Number(cellValue) < 0))
                            return true;
                    }
                    switch (dataType) {
                        case "Integer":
                        case "Double": {
                            return value < 0;
                        }
                        default:
                            return false;
                    }
                },
                rightAlignedCell: () => type === "standardRightColumn",
                leftAlignedCell: () => type !== "standardRightColumn",
            },
            hide,
            initialHide: hide,
            ...overrides,
        };
    });
    return [...columnDefs];
}
function valueTooltipGetterCreator(column) {
    return function valueTooltipGetter(args) {
        const data = dataEntry(args);
        const exception = data.exception;
        const res = formatReportValueSimplified(data, column, {
            numberFormat: {
                fractionDigits: [column.decimalPlaces ?? 2, 16, 2],
            },
        });
        return `${res}${exception ? ` (${exception})` : ""}`;
    };
}
export function createReportColumnDefsSimplified({ columns, columnSort, hasChildren, }) {
    let parentSet = false;
    const columnDefs = columns.map((column) => {
        const { name, displayName, dataType, columnFormat, visible } = column;
        const sortIdx = columnSort.findIndex((sortSetting) => {
            return sortSetting.columnName === name;
        });
        const sortOptions = {};
        if (sortIdx !== -1) {
            sortOptions.initialSort = columnSort[sortIdx].ascending ? "asc" : "desc";
            sortOptions.initialSortIndex = sortIdx;
        }
        const type = getReportColumnType(columnFormat?.alignment, dataType);
        const hide = "visible" in column ? !visible : false;
        let cellRenderer = dataType === "HTMLLink"
            ? "htmlLinkCellRenderer"
            : columnFormat?.name === "Quotation"
                ? "priceCellRenderer"
                : undefined;
        if (!parentSet && !hide && hasChildren && !cellRenderer)
            parentSet = true;
        const overrides = {};
        if (type === "dateColumn") {
            const { dateTimeFormat } = column;
            overrides.comparator = (valueA, valueB) => {
                return parseISO(valueA).getTime() - parseISO(valueB).getTime();
            };
            overrides.pivotComparator = (valueA, valueB) => {
                return (parse(valueA, dateTimeFormat, new Date()).getTime() -
                    parse(valueB, dateTimeFormat, new Date()).getTime());
            };
            overrides.filter = "agMultiColumnFilter";
            overrides.filterParams = {
                filters: [
                    {
                        filter: "agDateColumnFilter",
                        filterParams: {
                            comparator: agDateFilterComparator.bind(null, "day"),
                        },
                    },
                    {
                        filter: "agSetColumnFilter",
                        filterParams: {
                            valueFormatter: (args) => {
                                return args.value !== null && typeof args.value !== "undefined"
                                    ? formatReportValue({ value: args.value }, column)
                                    : null;
                            },
                            comparator: overrides.comparator,
                        },
                    },
                ],
            };
            overrides.filterValueGetter = (args) => {
                const data = dataEntry(args);
                if (!data || data.value === null)
                    return null;
                return data.formatted;
            };
        }
        if (dataType === "Double")
            overrides.tooltipValueGetter = valueTooltipGetterCreator(column);
        return {
            ...sortOptions,
            colId: name,
            headerName: displayName,
            name,
            headerTooltip: displayName,
            type,
            cellDataType: type,
            pinned: cellRenderer === PARENT_CELL ? "left" : undefined,
            sortable: true,
            maxWidth: cellRenderer === PARENT_CELL ? window.innerWidth / 3 : undefined,
            valueGetter: (args) => {
                return dataEntry(args)?.value ?? null;
            },
            valueFormatter: (args) => {
                return dataEntry(args)?.formatted ?? null;
            },
            // filterValueGetter: (args: ValueGetterParams) => {
            //   const data = dataEntry(args);
            //   if (!data || data.value === null) return null;
            //   return data.value;
            // },
            cellRenderer,
            cellClassRules: {
                warningCell: (args) => dataEntry(args).status === "Warning",
                errorCell: (args) => dataEntry(args).status === "Error",
                errorText: (args) => {
                    const entry = dataEntry(args);
                    const cellValue = entry?.value !== undefined ? entry?.value : args.value;
                    if (cellValue === null)
                        return false;
                    if (columnFormat?.redIfNegative && cellValue < 0)
                        return true;
                    if (dataType === "Double" || dataType === "Integer")
                        return cellValue < 0;
                    return false;
                },
            },
            initialHide: hide,
            ...overrides,
        };
    });
    return [...columnDefs];
}
export const dataTypeDefinitions = {
    numberColumn: {
        extendsDataType: "number",
        baseDataType: "number",
    },
    standardColumn: {
        extendsDataType: "text",
        baseDataType: "text",
    },
    dateColumn: {
        extendsDataType: "dateString",
        baseDataType: "dateString",
        valueParser: (param) => param.newValue,
        dateParser: (value) => {
            if (value === null || value === undefined || value === "")
                return new Date("");
            return new Date(formatCellValues("DateTime", value));
        },
    },
};
