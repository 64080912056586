import { groupBy, omit, sortBy } from "lodash";
import { getFileExt } from "./files";
export const sortByFileName = (a, b) => a.name.localeCompare(b.name);
const isDiffReport = (diffReport = false) => (i) => ["diff"].indexOf(getFileExt(i.name)) === (diffReport ? 0 : -1);
export const fileTreeEntriesToNodeData = (entries, recurseOnChildren = false, diffReport = false) => {
    const directories = entries.filter((i) => !i.file);
    const childFiles = entries.filter((i) => i.file);
    const files = childFiles.filter(isDiffReport(diffReport));
    return [...directories, ...files].map((i) => ({
        ...omit(i, ["children"]),
        id: i.path,
        nodes: recurseOnChildren && i.children
            ? fileTreeEntriesToNodeData(i.children, true, diffReport)
            : undefined,
    }));
};
export const fileTreeEntriesToSortedNodeData = (entries, recurseOnChildren = false, diffReport = false) => {
    const childDirectories = entries.filter((i) => !i.file);
    const childFiles = entries.filter((i) => i.file).sort(sortByFileName);
    const directories = childDirectories.sort(sortByFileName);
    const files = childFiles.filter(isDiffReport(diffReport));
    return [...directories, ...files].map((i) => ({
        ...i,
        id: i.path,
        nodes: recurseOnChildren && i.children
            ? fileTreeEntriesToSortedNodeData(i.children, true, diffReport)
            : undefined,
    }));
};
export const NULL_GROUP_KEY = "__NULL__";
const ROOT_PATH = "/root";
export function buildPBNodes(entries) {
    return sortBy(entries, "description").map((i) => ({
        name: i.description || i.code,
        id: i.groupId !== NULL_GROUP_KEY
            ? `${ROOT_PATH}/${i.groupId}/${i.code}`
            : `${ROOT_PATH}/${i.code}`,
        path: i.groupId !== NULL_GROUP_KEY
            ? `${ROOT_PATH}/${i.groupId}/${i.code}`
            : `${ROOT_PATH}/${i.code}`,
        code: i.code,
        groupId: i.groupId !== NULL_GROUP_KEY ? i.groupId : null,
        file: true,
        depth: i.groupId !== NULL_GROUP_KEY ? 2 : 1,
    }));
}
export function buildPBTree(options) {
    const grouped = groupBy(options.map((i) => ({
        ...i,
        groupId: i.groupId === null ? NULL_GROUP_KEY : i.groupId,
    })), "groupId");
    const groups = Object.keys(grouped).sort();
    let nodes = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const groupId of groups) {
        if (groupId !== NULL_GROUP_KEY)
            nodes.push({
                id: `${ROOT_PATH}/${groupId}`,
                name: groupId,
                path: `${ROOT_PATH}/${groupId}`,
                nodes: buildPBNodes(grouped[groupId]).map((i) => {
                    if (i.groupId === NULL_GROUP_KEY)
                        return { ...i, groupId: null };
                    return i;
                }),
                file: false,
                depth: 1,
            });
    }
    nodes = [...nodes, ...buildPBNodes(grouped[NULL_GROUP_KEY])];
    return [
        {
            id: `${ROOT_PATH}`,
            name: "<ALL>",
            file: false,
            path: `${ROOT_PATH}`,
            nodes,
            depth: 0,
            defaultOpen: true,
        },
    ];
}
export const reduceFundNodes = (nodes) => nodes.reduce((res, data) => {
    switch (data.level) {
        case "Custodian":
            if (data.nodes?.length) {
                res.accountIds = [
                    ...res.accountIds,
                    ...data.nodes.map((x) => Number(x.id)),
                ];
            }
            break;
        case "Account":
            res.accountIds.push(Number(data.accountId));
            break;
        case "Fund":
            res.fundIds.push(Number(data.fundId));
            break;
        case "FundContainer":
            if (data.nodes?.length) {
                res.fundIds = [
                    ...res.fundIds,
                    ...data.nodes.map((x) => Number(x.fundId)),
                ];
            }
            break;
        default:
        // do nothing
    }
    return res;
}, { accountIds: [], fundIds: [] });
