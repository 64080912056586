export function dataEntry({ data, colDef, }, defaultValue = {}) {
    return data && colDef && colDef.colId
        ? data[colDef.colId] ||
            data.columnValues?.[colDef?.colId] ||
            defaultValue
        : defaultValue;
}
function baseText({ value, valueFormatted }) {
    if (valueFormatted)
        return valueFormatted;
    const type = typeof value;
    if (type === "object" || type === "undefined")
        return "";
    return `${value}`;
}
export function valueTooltipGetter(args) {
    const exception = dataEntry(args).exception;
    const base = baseText(args);
    return `${base}${exception ? ` (${exception})` : ""}`;
}
