import { useRefCallback } from "@enfusion-ui/hooks";
import { checkPasswordStrength } from "@enfusion-ui/utils";
import React, { useEffect } from "react";
import { useGeneralizedMethods } from "./context";
import { TOAST_CONTENT } from "./textConstants";
export const usePasswordValidation = (formMethods) => {
    const { api, errorToast } = useGeneralizedMethods();
    const rulesRef = React.useRef(null);
    const getPasswordRules = useRefCallback(async () => {
        try {
            rulesRef.current = await api.UTILITY.GET_PASSWORD_RULES.FETCH();
        }
        catch (error) {
            errorToast(TOAST_CONTENT.password.networkError.fetchFailed, error.message);
        }
    }, [api, errorToast]);
    useEffect(() => {
        getPasswordRules();
    }, [getPasswordRules]);
    const validatePassword = useRefCallback(async (value) => {
        const { currentPassword } = formMethods.getValues();
        if (value === currentPassword)
            return "Cannot match current password";
        if (rulesRef.current === null) {
            await getPasswordRules();
        }
        if (rulesRef.current) {
            const result = checkPasswordStrength(rulesRef.current, value);
            if (result.verdict < 4) {
                return result.hint;
            }
        }
        return true;
    }, [getPasswordRules, formMethods]);
    const revalidatePassword = useRefCallback((value) => {
        const { newPassword } = formMethods.getValues();
        if (value !== newPassword)
            return "Must match new password";
        return true;
    }, [formMethods]);
    const handleChangePassword = useRefCallback(async (currentPassword, newPassword, onCancel, onSuccess) => {
        try {
            await api.UTILITY.CHANGE_PASSWORD.FETCH(btoa(currentPassword), btoa(newPassword));
            onSuccess?.();
            onCancel();
        }
        catch (e) {
            console.warn("Change password error", e);
            throw new Error("Failed to change password");
        }
    }, [api]);
    return { validatePassword, handleChangePassword, revalidatePassword };
};
