import { GeneralizedMethodsContext } from "@enfusion-ui/core";
import { noop } from "lodash";
import * as React from "react";
import { errorToast, infoToast, REST_API, restServer, successToast, warningToast, } from "../../utils";
export const MockGeneralizedMethodsProvider = ({ children }) => {
    return (React.createElement(GeneralizedMethodsContext.Provider, { value: {
            successToast,
            errorToast,
            infoToast,
            warningToast,
            restServer: restServer,
            api: REST_API,
            openLink: noop,
            appSource: "Web",
            platform: "web",
            appVersion: process.env.REACT_APP_VERSION || "Unknown",
        } }, children));
};
