import { INTERNAL_PO_IDS, INTERNAL_PO_IDS_LIST, useGeneralizedAuth, } from "@enfusion-ui/core";
import { useLocalStorage, useRefCallback } from "@enfusion-ui/hooks";
import { debounce } from "lodash";
import * as React from "react";
import { ControlledFlagList } from "./context";
import { FlagContext } from "./flagContext";
const defaultFlags = {
    devMode: false,
    oems: true,
    dashboards: !!process.env.REACT_APP_ENABLE_DASHBOARDS,
    services: true,
    reports: true,
    factset: true,
    compliance: !!process.env.REACT_APP_ENABLE_COMPLIANCE,
    generalFiles: !!process.env.REACT_APP_ENABLE_FILES,
    portfolios: !!process.env.REACT_APP_ENABLE_PORTFOLIOS,
    operationsFiles: !!process.env.REACT_APP_ENABLE_MS_OPERATIONS,
    reconDashboard: !!process.env.REACT_APP_ENABLE_RECON_DASHBOARD,
    analyticsDashboard: !!process.env.REACT_APP_ENABLE_ALX_DASHBOARD,
    managedServices: !!process.env.REACT_APP_ENABLE_MS_PHASE_1B,
};
const expressAllowedViews = ["oems", "dashboards"];
export const FlagProvider = ({ children, }) => {
    const { isUserType, isEnabled, isAdmin, user, isPoId } = useGeneralizedAuth();
    const [enabled, setEnabled] = useLocalStorage(`${user?.username || "default"}-flags`, "false");
    const [allowedToChange, setAllowedToChange] = React.useState(defaultFlags);
    const setBasis = useRefCallback(debounce(() => {
        if (!!user?.username) {
            const allowedList = { ...defaultFlags, devMode: true };
            const enabledList = {
                ...(enabled === "false" ? defaultFlags : enabled),
            };
            const setE = (key, isAllowed) => {
                allowedList[key] = isAllowed;
                if (enabled === "false")
                    enabledList[key] = defaultFlags[key] && allowedList[key];
                console.log("setE", {
                    key,
                    isAllowed,
                    al: allowedList[key],
                    el: enabledList[key],
                    df: defaultFlags[key],
                    ef: enabled === "false",
                });
            };
            setE("oems", isEnabled("OMS"));
            setE("reports", isEnabled("Reports"));
            setE("services", !!process.env.REACT_APP_ENABLE_SERVICES_MARKETING ||
                isEnabled("Services"));
            setE("operationsFiles", isEnabled("OperationsFiles"));
            setE("generalFiles", isEnabled("GeneralFiles"));
            setE("factset", isEnabled("FactSet"));
            setE("compliance", isEnabled("CompliancePortal"));
            setE("portfolios", isEnabled("Workbench") ||
                isAdmin() ||
                [
                    ...INTERNAL_PO_IDS_LIST,
                    727319,
                    729614,
                    736978,
                    353699,
                    611489,
                    622855,
                    349670,
                    620377,
                    685504,
                    726126,
                    666564,
                    650731,
                    460675, // "Foresite Capital"
                ].some(isPoId));
            setE("reconDashboard", isAdmin() || isEnabled("ReconDashboard"));
            setE("managedServices", isAdmin() || isEnabled("ManagedServices"));
            setE("analyticsDashboard", isPoId(INTERNAL_PO_IDS.Enfusion) ||
                (process.env.REACT_APP_ENVIRONMENT === "development" &&
                    isPoId(689629)) /* Soros Fund Management LLC */);
            if (isUserType("Express")) {
                for (const key of ControlledFlagList) {
                    const allow = expressAllowedViews.includes(key);
                    allowedList[key] = allow;
                    enabledList[key] = allow;
                }
            }
            console.log({
                allowedList,
                enabledList,
                defaultFlags,
                enabled,
            });
            setAllowedToChange(allowedList);
            setEnabled(enabledList);
        }
    }, 1000), [
        enabled,
        user,
        isUserType,
        isEnabled,
        isAdmin,
        isPoId,
        setAllowedToChange,
        setEnabled,
    ]);
    React.useEffect(() => {
        setBasis();
    }, [enabled === "false", user]);
    const changeFlag = useRefCallback((key, value) => {
        setEnabled((state = defaultFlags) => ({
            ...(state === "false" ? defaultFlags : state),
            [key]: value,
        }));
    }, [setEnabled]);
    // This is to allow sales to enable some things in prod to demo even if its not ready for prime time yet.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.changeFlag = changeFlag;
    const reset = useRefCallback(() => {
        setEnabled("false");
    }, [setEnabled]);
    const value = React.useMemo(() => ({
        enabled: enabled === "false" ? defaultFlags : enabled,
        allowedToChange,
        reset,
        changeFlag,
    }), [enabled, allowedToChange, reset, changeFlag]);
    return React.createElement(FlagContext.Provider, { value: value }, children);
};
