import { styled } from "@enfusion-ui/web-core";
import { kebabCase } from "lodash";
import * as React from "react";
import DatePickerBase from "react-datepicker";
import { createControlled } from "../../forms";
import { TextInput } from "../TextInput";
import { DEFAULT_PLACEHOLDER, DEFAULT_PORTAL_ID } from "./constants";
const CalendarContainer = styled.div `
  background-color: ${({ theme }) => theme.colors.backgroundColor1};
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.backgroundColor1};
  }
  .react-datepicker__month .react-datepicker__week .react-datepicker__day,
  .react-datepicker__header .react-datepicker__current-month,
  .react-datepicker__header
    .react-datepicker__day-names
    .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.textNormal};
  }
  .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day:hover {
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
  }
`;
const PopperContainer = styled.div `
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 101;
`;
export function DatePicker({ onChange, label, name, value = null, placeholder = DEFAULT_PLACEHOLDER, errors, portalId = DEFAULT_PORTAL_ID, minWidth, clearable, disabled = false, hideLabel = false, inline = false, dataTestId, ...rest }) {
    const datePickerRef = React.useRef(null);
    const [calendarOpen, setCalendarOpen] = React.useState(datePickerRef.current?.isCalendarOpen() ?? false);
    return (React.createElement(DatePickerBase, { popperContainer: ({ children }) => {
            return calendarOpen ? (React.createElement(PopperContainer, null, children)) : null;
        }, customInput: React.createElement(TextInput, { name: name, label: label, hideLabel: hideLabel, inline: inline, errors: errors, clearable: clearable, onClearValue: () => onChange(null), minWidth: minWidth, disabled: disabled, dataTestId: dataTestId ??
                `date-picker-${typeof label === "string" ? kebabCase(label) : kebabCase(name)}` }), ref: datePickerRef, name: name, selected: value, onChange: (date) => {
            onChange(date);
            setCalendarOpen(false);
        }, placeholderText: placeholder, portalId: portalId, ...rest, disabled: disabled, calendarContainer: CalendarContainer, onBlur: () => {
            setCalendarOpen(false);
        }, onFocus: () => {
            setCalendarOpen(true);
        } }));
}
export const ControlledDatePicker = createControlled()(DatePicker, ({ value, ...props }) => ({
    ...props,
    value: value ? (value instanceof Date ? value : new Date(value)) : null,
}));
