import { css, styled, useTheme } from "@enfusion-ui/web-core";
import { faCircle, faCircleDot } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { kebabCase, noop } from "lodash";
import * as React from "react";
import { createControlled } from "../forms/ControlledInputs";
import { InputLabelControl } from "./TextInput";
const Container = styled.div `
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  justify-content: center;
  font-size: 1em;
  font-weight: 500;
`;
const RadioGroupWrapper = styled.label `
  display: flex;
  align-items: center;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "small")};
  margin: ${({ vertical }) => vertical
    ? "var(--spacing) 0 0 var(--spacing)"
    : "var(--spacing-l) var(--spacing) var(--spacing) var(--spacing)"};
  cursor: pointer;
`;
const Orientation = styled.div `
  ${({ vertical }) => vertical
    ? css `
          display: flex;
          flex-direction: column;
          gap: var(--spacing);
          margin: 0 4px;
        `
    : css `
          display: flex;
          flex-direction: row;
          margin-left: 8px;
        `}
`;
const StyledFAIcon = styled(FontAwesomeIcon) `
  width: var(--spacing-xl);
  height: var(--spacing-xl);
  margin-right: var(--spacing);
`;
export function RadioGroup({ options, label, name, value, onChange, errors, disabled, vertical, required, fontSize, inline, containerStyle, itemStyle, labelPlaceholder, }) {
    const { theme } = useTheme();
    return (React.createElement(Container, { inline: inline, style: containerStyle },
        React.createElement(InputLabelControl, { name: name, label: label, errors: errors, required: required, labelPlaceholder: labelPlaceholder }),
        React.createElement(Orientation, { vertical: vertical }, options.map((option) => {
            const selected = value === option.value;
            const iconColor = disabled
                ? theme.colors.textMuted
                : selected
                    ? theme.colors.primary
                    : theme.colors.textNormal;
            return (React.createElement(RadioGroupWrapper, { key: option.label + option.value, fontSize: fontSize, style: itemStyle, vertical: vertical, onClick: () => (disabled ? noop : onChange(option.value)), "data-testid": kebabCase(`radio-${name}-${option.label}`) },
                React.createElement(StyledFAIcon, { disabled: disabled, icon: selected ? faCircleDot : faCircle, color: iconColor }),
                option.label));
        }))));
}
export const ControlledRadioGroup = createControlled()(RadioGroup);
