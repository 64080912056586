import { isEqual } from "lodash";
import * as React from "react";
import { useRefCallback } from "./useRefCallback";
export function useDeepState(defaultState) {
    const [state, setStateCore] = React.useState(defaultState);
    const setState = useRefCallback(((args) => {
        setStateCore((curr) => {
            let newVal = args;
            if (typeof args === "function")
                newVal = args(curr);
            if (!isEqual(curr, newVal))
                return newVal;
            return curr;
        });
    }), []);
    return React.useMemo(() => {
        return [state, setState];
    }, [state, setState]);
}
