import { lightBg, themes } from "@enfusion-ui/core";
import { hexToRgb, hexToRgbBase } from "@enfusion-ui/utils";
export const DesktopThemeMap = {
    FlatDarkCarbonLookAndFeel: themes.darkCarbon,
    FlatDarkCobalt: themes.darkCobalt,
    FlatDarkPurpleLookAndFeel: themes.dark,
    FlatDarkSolarizedLookAndFeel: themes.darkSolarized,
    FlatDarkHighContrast: themes.dark,
    FlatGruvBoxDarkHard: themes.dark,
    FlatMonocai: themes.monokai,
    FlatNord: themes.darkSpaceGray,
    FlatDarcula: themes.dracula,
    FlatNightOwlContrast: themes.dark,
    FlatDarkSpaceGrayLookAndFeel: themes.darkSpaceGray,
    FlatVividDarkSpaceGrayLookAndFeel: themes.darkSpaceGray,
    FlatLightLookAndFeel: themes.light,
    FlatGrayLookAndFeel: themes.light,
};
const defaultFont = `"Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif`;
export const getCss = (theme, onlyAgGrid = false) => {
    const agGridVars = {
        "--ag-font-family": defaultFont,
        "--ag-accent": theme.colors.backgroundAccent,
        "--ag-border-color": theme.colors.backgroundAccent,
        "--ag-balham-active-color": theme.colors.primary,
        "--ag-foreground-color": theme.colors.textNormal,
        "--ag-background-color": theme.colors.backgroundColor2,
        "--ag-header-background-color": theme.colors.backgroundColor2,
        "--ag-tooltip-background-color": theme.colors.backgroundAccent,
        "--ag-subheader-background-color": theme.colors.backgroundColor2,
        "--ag-control-panel-background-color": theme.colors.backgroundColor2,
        "--ag-odd-row-background-color": theme.colors.backgroundColor1,
        "--ag-row-hover-color": hexToRgb(theme.colors.primary, 0.5),
        "--ag-column-hover-color": hexToRgb(theme.colors.primary, 0.5),
        "--ag-input-border-color": theme.colors.backgroundAccent,
        "--ag-invalid-color": theme.colors.danger,
        "--ag-input-focus-border-color": hexToRgb(theme.colors.primary, 0.8),
        "--ag-input-border-error": theme.colors.danger,
        "--ag-input-disabled-background-color": theme.colors.backgroundColor2,
        "--ag-checkbox-unchecked-color": theme.colors.textColor1,
        "--ag-checkbox-checked-color": theme.colors.textNormal,
        "--ag-checkbox-background-color": "transparent",
        // "--ag-advanced-filter-join-pill-color": theme.colors.,
        // "--ag-advanced-filter-column-pill-color": theme.colors.,
        // "--ag-advanced-filter-option-pill-color": theme.colors.,
        // "--ag-advanced-filter-value-pill-color": theme.colors.,
        "--ag-input-focus-box-shadow": hexToRgb(theme.colors.primary, 0.2),
        "--ag-range-selection-border-color": hexToRgb(theme.colors.primary, 0.2),
        "--ag-panel-background-color": theme.colors.backgroundColor2,
        "--ag-secondary-foreground-color": theme.colors.textColor1,
        "--ag-disabled-foreground-color": theme.colors.textMuted,
        "--ag-subheader-toolbar-background-color": theme.colors.backgroundColor2,
        "--ag-row-border-color": theme.colors.backgroundAccent,
        "--ag-chip-background-color": hexToRgb(theme.colors.backgroundAccent, 0.5),
        "--ag-chip-border-color": theme.colors.backgroundAccent,
        "--ag-range-selection-background-color": hexToRgb(theme.colors.primary, 0.2),
        "--ag-range-selection-background-color-2": hexToRgb(theme.colors.primary, 0.36),
        "--ag-range-selection-background-color-3": hexToRgb(theme.colors.primary, 0.49),
        "--ag-range-selection-background-color-4": hexToRgb(theme.colors.primary, 0.59),
        "--ag-selected-row-background-color": hexToRgb(theme.colors.primary, 0.28),
        "--ag-data-color": theme.colors.textNormal,
        "--ag-value-change-delta-up-color": theme.colors.success,
        "--ag-value-change-delta-down-color": theme.colors.danger,
        "--ag-header-column-separator": hexToRgb(theme.colors.backgroundAccent, 0.5),
    };
    if (onlyAgGrid)
        return agGridVars;
    return {
        "--primary": theme.colors.primary,
        "--primary-hover": theme.colors.primaryHover,
        "--info": theme.colors.info,
        "--info-hover": theme.colors.infoHover ?? "#16203c",
        "--success": theme.colors.success,
        "--success-hover": theme.colors.successHover ?? "#308867",
        "--warning": theme.colors.warning,
        "--warning-hover": theme.colors.warningHover ?? "#967c38",
        "--danger": theme.colors.danger,
        "--danger-hover": theme.colors.dangerHover ?? "#e21a0c",
        "--background-color-0": theme.colors.backgroundColor0,
        "--background-color-0-rgb": hexToRgbBase(theme.colors.backgroundColor0, "0,0,0"),
        "--background-color-0-hover": theme.colors.backgroundColor0Hover ?? "#2b2d32",
        "--background-color-1": theme.colors.backgroundColor1,
        "--background-color-1-rgb": hexToRgbBase(theme.colors.backgroundColor1, "0,0,0"),
        "--background-color-1-hover": theme.colors.backgroundColor1Hover ?? "#25272b",
        "--background-color-2": theme.colors.backgroundColor2,
        "--background-color-2-rgb": hexToRgbBase(theme.colors.backgroundColor2, "0,0,0"),
        "--background-accent": theme.colors.backgroundAccent,
        "--background-color-accent-rgb": hexToRgbBase(theme.colors.backgroundAccent, "0,0,0"),
        "--background-danger": "#540a04",
        "--background-warning": "#4b3e1c",
        "--text-normal": theme.colors.textNormal,
        "--text-normal-alpha": theme.colors.textNormal + "4D",
        "--text-inverted": theme.colors.textInverted,
        "--text-color-1": theme.colors.textColor1,
        "--text-hover": theme.colors.textHover,
        "--text-muted": theme.colors.textMuted,
        "--text-muted-alpha": theme.colors.textMuted + "4D",
        "--text-input-border": theme.colors.textInputBorder,
        "--border": theme.colors.border,
        "--input-background": theme.colors.inputBackground,
        "--input-border": theme.colors.inputBorder,
        "--overlay": theme.colors.overlay,
        "--tab-icon-color-0": theme.colors.tabIconColor0,
        "--background-top-app-bar": theme.colors.backgroundTopAppBar,
        "--background-form": theme.colors.backgroundForm || theme.colors.backgroundColor0,
        "--shadow": "0px 4px 4px rgba(0, 0, 0, 0.25)",
        ...theme.colors.dashboardVisuals.reduce((res, entry, idx) => ({
            ...res,
            [`--dashboard-color-${idx}`]: entry,
        }), {}),
        ...theme.colors.dashboardVisuals.reduce((res, entry, idx) => ({
            ...res,
            [`--dashboard-color-alpha-${idx}`]: entry + "CC",
        }), {}),
        "--preferred-button-color-0": "rgb(135, 206, 250)",
        "--preferred-button-color-1": "rgb(216, 191, 216)",
        "--preferred-button-color-2": theme.key === "light" ? "rgb(82, 200, 82)" : "rgb(152, 251, 152)",
        "--preferred-button-color-3": "rgb(222, 184, 135)",
        "--preferred-button-color-4": theme.key === "light" ? "rgb(100, 100, 25)" : "rgb(255, 250, 205)",
        "--preferred-button-color-5": "rgb(218, 112, 214)",
        "--preferred-button-color-6": theme.key === "light" ? "rgb(255, 75, 238)" : "rgb(255, 245, 238)",
        "--preferred-button-color-7": "rgb(255, 165, 170)",
        "--preferred-button-bg-color-0": "rgb(135, 206, 250, 0.1)",
        "--preferred-button-bg-color-1": "rgb(216, 191, 216, 0.1)",
        "--preferred-button-bg-color-2": "rgb(152, 251, 152, 0.1)",
        "--preferred-button-bg-color-3": "rgb(222, 184, 135, 0.1)",
        "--preferred-button-bg-color-4": "rgb(255, 250, 205, 0.1)",
        "--preferred-button-bg-color-5": "rgb(218, 112, 214, 0.1)",
        "--preferred-button-bg-color-6": "rgb(255, 245, 238, 0.1)",
        "--preferred-button-bg-color-7": "rgb(255, 165, 170, 0.1)",
        /* standard spacing */
        "--spacing-s": "0.15rem" /* 2.4px */,
        "--spacing": "0.25rem" /* 4px */,
        "--spacing-l": "0.5rem" /* 8px */,
        "--spacing-xl": "0.75rem" /* 12px */,
        "--spacing-xxl": "1.25rem" /* 20px */,
        "--radius-l": "10px",
        "--radius": "5px",
        /* fonts */
        "--default-font": defaultFont,
        "--header-font": defaultFont,
        /* font size */
        "--default-font-size": theme?.density === "high" ? "small" : "medium",
        "--tab-container-height": theme?.density === "high" ? "28px" : "34px",
        /* z-indexes */
        "--widget-z": 1000,
        "--modal-z": 1001,
        "--calendar-z": "1002",
        /* backgrounds */
        "--landing-bg": theme.backgrounds?.landingBg || lightBg,
        /*AgGrid style vars*/
        ...agGridVars,
    };
};
