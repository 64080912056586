export var Verdict;
(function (Verdict) {
    Verdict[Verdict["Unacceptable"] = 0] = "Unacceptable";
    Verdict[Verdict["VeryWeak"] = 1] = "VeryWeak";
    Verdict[Verdict["Weak"] = 2] = "Weak";
    Verdict[Verdict["Mediocre"] = 3] = "Mediocre";
    Verdict[Verdict["Strong"] = 4] = "Strong";
    Verdict[Verdict["VeryStrong"] = 5] = "VeryStrong";
    // web app only below
    Verdict[Verdict["ExtremelyStrong"] = 6] = "ExtremelyStrong";
    Verdict[Verdict["MaximumStrength"] = 7] = "MaximumStrength";
})(Verdict || (Verdict = {}));
function failCheck(hint) {
    return { verdict: Verdict.Unacceptable, hint };
}
// NOTE: this code is a port from PasswordCheck.java on server side - DO NOT REFACTOR to make updates easy to apply.
export function checkPasswordStrength(rules, passwd) {
    let upper = 0;
    let lower = 0;
    let numbers = 0;
    let special = 0;
    let length = 0;
    let intScore = 0;
    let verdict = Verdict.VeryWeak;
    let strLog = "";
    const hintTooShort = "Please add more characters";
    const hintAddLowercaseLetters = "Please add lowercase letters";
    const hintAddUppercaseLetters = "Please add uppercase letters";
    const hintAddNumericCharacters = "Please add numeric characters";
    const hintAddSpecialCharacters = "Please add special characters";
    if (passwd == null)
        return failCheck(hintTooShort);
    // PASSWORD LENGTH
    let hint = "";
    length = passwd.length;
    if (length < 5) {
        // length 4 or less
        intScore += 3;
        strLog = `${strLog}3 points for length (${length})\n`;
        hint = hintTooShort;
    }
    else if (length > 4 && passwd.length < 8) {
        // length between 5 and 7
        intScore += 6;
        strLog = `${strLog}6 points for length (${length})\n`;
        hint = hintTooShort;
    }
    else if (length > 7 && passwd.length < 16) {
        // length between 8 and 15
        intScore += 12;
        strLog = `${strLog}12 points for length (${length})\n`;
        hint = hintTooShort;
    }
    else if (length > 15) {
        // length 16 or more
        intScore += 18;
        strLog = `${strLog}18 point for length (${length})\n`;
    }
    // LETTERS
    let matches = passwd.match(/.??[a-z]/g);
    if (matches)
        // [verified] at least one lower case letter
        lower += matches.length;
    if (lower > 0) {
        intScore += 1;
        strLog += "1 point for a lower case character\n";
    }
    else
        hint = hintAddLowercaseLetters;
    matches = passwd.match(/.??[A-Z]/g);
    if (matches)
        // [verified] at least one upper case letter
        upper += matches.length;
    if (upper > 0) {
        intScore += 5;
        strLog += "5 point for an upper case character\n";
    }
    else
        hint = hintAddUppercaseLetters;
    // NUMBERS
    matches = passwd.match(/.??[0-9]/g);
    if (matches)
        // [verified] at least one number
        numbers += matches.length;
    if (numbers > 0) {
        intScore += 5;
        strLog += "5 points for a number\n";
        if (numbers > 1) {
            intScore += 2;
            strLog += "2 points for at least two numbers\n";
            if (numbers > 2) {
                intScore += 3;
                strLog += "3 points for at least three numbers\n";
            }
        }
    }
    if (numbers < 3)
        hint = hintAddNumericCharacters;
    // SPECIAL CHAR
    matches = passwd.match(/.??[:,!,@,#,$,%,^,&,*,?,_,~]/g);
    if (matches)
        // [verified] at least one special character
        special += matches.length;
    if (special > 0) {
        intScore += 5;
        strLog += "5 points for a special character\n";
        if (special > 1) {
            intScore += 5;
            strLog += "5 points for at least two special characters\n";
        }
    }
    if (special < 2)
        hint = hintAddSpecialCharacters;
    // COMBOS
    if (upper > 0 && lower > 0) {
        // [verified] both upper and lower case
        intScore += 2;
        strLog += "2 combo points for upper and lower letters\n";
    }
    if ((upper > 0 || lower > 0) && numbers > 0) {
        // [verified] both letters and numbers
        intScore += 2;
        strLog += "2 combo points for letters and numbers\n";
    }
    if ((upper > 0 || lower > 0) && numbers > 0 && special > 0) {
        // [verified] letters, numbers, and special characters
        intScore += 2;
        strLog += "2 combo points for letters, numbers and special chars\n";
    }
    if (upper > 0 && lower > 0 && numbers > 0 && special > 0) {
        // [verified] upper, lower, numbers, and special characters
        intScore += 2;
        strLog +=
            "2 combo points for upper and lower case letters, numbers and special chars\n";
    }
    console.warn("strLog = ", strLog);
    if (intScore < 16) {
        verdict = Verdict.VeryWeak;
    }
    else if (intScore > 15 && intScore < 25) {
        verdict = Verdict.Weak;
    }
    else if (intScore > 24 && intScore < 35) {
        verdict = Verdict.Mediocre;
    }
    else if (intScore > 34 && intScore < 45) {
        verdict = Verdict.Strong;
    }
    else {
        verdict = Verdict.VeryStrong;
    }
    // web app extra
    if (intScore >= 52)
        // max possible score
        verdict = Verdict.ExtremelyStrong;
    // Does it meet the password policy?
    let maxLength = 30;
    try {
        const mix = Number.parseInt(rules.mixedCase);
        if (lower < mix)
            return failCheck(hintAddLowercaseLetters);
        if (upper < mix)
            return failCheck(hintAddUppercaseLetters);
    }
    catch (e) { } // undefined
    try {
        const num = Number.parseInt(rules.numeric);
        if (numbers < num)
            return failCheck(hintAddNumericCharacters);
    }
    catch (e) { } // undefined
    try {
        const spec = Number.parseInt(rules.special);
        if (special < spec)
            return failCheck(hintAddSpecialCharacters);
    }
    catch (e) { } // undefined
    try {
        const min = Number.parseInt(rules.minLength);
        if (length < min)
            return failCheck(hintTooShort);
    }
    catch (e) { } // undefined
    try {
        const max = Number.parseInt(rules.maxLength);
        if (length > max)
            return failCheck("Please remove some characters");
        maxLength = max;
    }
    catch (e) { } // undefined
    try {
        const str = Number.parseInt(rules.strength);
        if (intScore < str)
            return failCheck(hint);
    }
    catch (e) { } // undefined
    // web app extra - upgrade very strong to max strength if maximum length password
    if (length >= maxLength) {
        if (verdict === Verdict.ExtremelyStrong)
            verdict = Verdict.MaximumStrength;
        // clear any hints
        hint = "";
    }
    return { verdict, hint };
}
export const hintTooShort = "Please add more characters";
export function verdictToString(verdict) {
    switch (verdict) {
        case Verdict.VeryWeak:
            return "Very Weak";
        case Verdict.VeryStrong:
            return "Very Strong";
        case Verdict.ExtremelyStrong:
            return "Full";
        case Verdict.MaximumStrength:
            return "Maximum";
        default:
            return Verdict[verdict];
    }
}
