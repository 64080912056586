import { THEME_KEY, themes, useGeneralizedAuth, } from "@enfusion-ui/core";
import { useLocalStorage, useRefCallback } from "@enfusion-ui/hooks";
import { noop } from "lodash";
import * as React from "react";
import { ThemeProviderBase } from "../utils/styledComponents";
import { isDesktop } from "../utils/window";
import { GlobalStyles } from "./GlobalStyles";
import { DesktopThemeMap, getCss } from "./utils";
const defaultThemeOptions = {
    density: "medium",
    cellFlashing: true,
};
const ThemeProviderControllerContext = React.createContext({
    themeKey: "dark",
    themeOptions: defaultThemeOptions,
    setThemeKey: noop,
    setThemeOptions: noop,
});
export const ThemeProviderContext = React.createContext(undefined);
export function useTheme() {
    const context = React.useContext(ThemeProviderContext);
    if (context === undefined) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }
    return context;
}
const ThemeProviderCore = React.memo(({ children, scoped, noCharting }) => {
    const { themeKey, themeOptions, setThemeKey, setThemeOptions: setThemeOptionsBase, } = React.useContext(ThemeProviderControllerContext);
    React.useEffect(() => {
        if (isDesktop()) {
            fin.InterApplicationBus.subscribe({ uuid: "*" }, "change-theme", (themeMessage) => {
                if (themeMessage) {
                    const { theme: newTheme } = themeMessage;
                    if (newTheme) {
                        const newThemeKey = (DesktopThemeMap[newTheme]?.key ??
                            "dark");
                        setThemeColor(newThemeKey);
                    }
                }
            });
        }
    }, []);
    const setThemeOptions = useRefCallback((options) => {
        setThemeOptionsBase({ ...themeOptions, ...options });
    }, [setThemeOptionsBase, themeOptions]);
    const setThemeColor = useRefCallback((key) => {
        if (themes[key]) {
            setThemeKey(key);
        }
        else {
            console.warn(`Theme with key '${key}' is not a valid option`);
        }
    }, [setThemeKey]);
    const value = React.useMemo(() => ({
        theme: { ...themes[themeKey], ...themeOptions },
        setThemeColor,
        setThemeOptions,
    }), [themeKey, themeOptions, setThemeColor, setThemeOptions]);
    return (React.createElement(ThemeProviderContext.Provider, { value: value },
        React.createElement(ThemeProviderBase, { theme: value.theme }, scoped ? (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.createElement("div", { style: { ...getCss(value.theme) } }, children)) : (React.createElement(React.Fragment, null,
            React.createElement(GlobalStyles, { noCharting: noCharting }),
            children)))));
});
export const ThemeProvider = React.memo(({ children, scoped = false, noCharting = false, controlled = false, defaultTheme = "dark", }) => {
    const Controller = React.useMemo(() => (controlled ? UserThemeControl : NoControl), [controlled]);
    return (React.createElement(Controller, { defaultTheme: defaultTheme },
        React.createElement(ThemeProviderCore, { scoped: scoped, noCharting: noCharting }, children)));
});
const NoControl = React.memo(({ defaultTheme, children }) => {
    const [themeKey, setThemeKey] = React.useState(defaultTheme);
    const [themeOptions, setThemeOptions] = React.useState(defaultThemeOptions);
    return (React.createElement(ThemeProviderControllerContext.Provider, { value: { themeKey, setThemeKey, themeOptions, setThemeOptions } }, children));
});
const UserThemeControl = React.memo(({ defaultTheme, children }) => {
    const { user } = useGeneralizedAuth();
    const [themeKey, setThemeKey] = useLocalStorage(`${THEME_KEY}-key-${user?.username}`, defaultTheme);
    const [themeOptions, setThemeOptions] = useLocalStorage(`${THEME_KEY}-options-${user?.username}`, defaultThemeOptions);
    return (React.createElement(ThemeProviderControllerContext.Provider, { value: { themeKey, setThemeKey, themeOptions, setThemeOptions } }, children));
});
