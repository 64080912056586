import queryString from "query-string";
const ConflictTypes = {
    Continue: "Continue",
    KillCurrent: "KillCurrent",
    KillOther: "KillOther",
};
const baseUrl = "/internal/api/utility";
export const calls = {
    RESOLVE_CONFLICT: {
        url: `${baseUrl}/resolveConflict`,
        method: "POST",
        query: {
            resolution: {
                defaultValue: ConflictTypes.Continue,
                options: Object.values(ConflictTypes),
            },
        },
    },
    LOGOUT: {
        url: `${baseUrl}/logout`,
        method: "POST",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    },
    HEARTBEAT: { url: `${baseUrl}/heartbeat` },
    GET_PASSWORD_RULES: { url: `${baseUrl}/getPasswordRules` },
    CHANGE_PASSWORD: {
        url: `${baseUrl}/changePassword`,
        method: "POST",
        body: { oldPassword: "", newPassword: "" },
    },
};
export default (callCreator) => ({
    RESOLVE_CONFLICT: callCreator((resolution) => [
        queryString.stringifyUrl({
            url: calls.RESOLVE_CONFLICT.url,
            query: { resolution },
        }),
        { method: "POST", ignoreAuth: true },
    ]),
    LOGOUT: callCreator(() => [
        calls.LOGOUT.url,
        {
            method: "POST",
            ignoreAuth: true,
            headers: calls.LOGOUT.headers,
        },
    ]),
    HEARTBEAT: callCreator(() => calls.HEARTBEAT.url),
    GET_PASSWORD_RULES: callCreator(() => calls.GET_PASSWORD_RULES.url),
    CHANGE_PASSWORD: callCreator((oldPassword, newPassword) => [
        calls.CHANGE_PASSWORD.url,
        { body: { oldPassword, newPassword }, ignoreAuth: true },
    ]),
});
