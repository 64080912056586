/* eslint-disable @typescript-eslint/no-explicit-any */
import { createRestServer } from "@enfusion-ui/core";
import { AUTH_TOKEN_KEY, AUTH_WSSERVERID } from "../context/auth/AuthProvider";
import { AUTH_CHANNEL } from "../context/auth/context";
import { AppLogging } from "./logging";
const { restServer, restServerBase } = createRestServer(AppLogging, AUTH_CHANNEL.broadcastMessage, (url, init) => window.fetch(url, init), process.env.REACT_APP_PROXY_HOST || "", () => {
    const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const WSSERVERID = localStorage.getItem(AUTH_WSSERVERID);
    const options = {};
    if (authToken)
        options["X-Auth-Token"] = authToken;
    if (WSSERVERID)
        options["Cookie"] = `WSSERVERID=${WSSERVERID}`;
    return Promise.resolve(options);
});
export { restServer, restServerBase };
