/* eslint-disable @typescript-eslint/no-explicit-any */
import Dexie from "dexie";
import { addDbMethods } from "../addDbMethods";
const db = new Dexie("enfusion-db");
db.version(1).stores({
    reports: "reportId, [username+reportId]",
});
const reportIndexes = "++id, reportId, tableId, username, [username+reportId+tableId], [username+reportId]";
db.version(2).stores({
    reports: null,
    reportData: `${reportIndexes}, lastUpdated`,
    reportTransactions: `${reportIndexes}, timestamp, sessionId`,
    gridState: "id++, uid, type, username, [username+type+uid], lastUpdated",
});
// db.delete({ disableAutoOpen: false })
const tables = {
    reports: addDbMethods(db.reportData),
    gridState: addDbMethods(db.gridState),
    reportTransactions: addDbMethods(db.reportTransactions),
};
export default { db, tables };
