import { ReconStatusTypeMap } from "@enfusion-ui/types";
export function getFileTooltipText(node) {
    return node.glName ? "Name: " + node.name + "\nGLName: " + node.glName : "";
}
export const handleIconColor = (status) => {
    switch (status) {
        case ReconStatusTypeMap.NeverRun:
            return "var(--text-muted)";
        case ReconStatusTypeMap.Subscribed:
        case ReconStatusTypeMap.Queued:
        case ReconStatusTypeMap.Running:
            return "var(--text-normal)";
        case ReconStatusTypeMap.Failed:
        case ReconStatusTypeMap.TimedOut:
            return "var(--danger)";
        case ReconStatusTypeMap.Success:
            return "var(--success)";
        case ReconStatusTypeMap.CloseToOverdue:
            return "var(--warning)";
        case ReconStatusTypeMap.Overdue:
            return "var(--danger)";
        case ReconStatusTypeMap.InvalidConfiguration:
            return "var(--warning)";
        default:
            return "var(--text-normal)";
    }
};
