import { isEqual } from "lodash";
import * as React from "react";
import { useRefCallback } from "./useRefCallback";
export function useCachedMemo(method, defaultCache, deps) {
    const cb = useRefCallback(method, deps);
    const cacheRef = React.useRef(defaultCache);
    return React.useMemo(() => {
        const newVal = cb();
        if (!isEqual(cacheRef.current, newVal))
            cacheRef.current = newVal;
        return cacheRef.current;
    }, deps);
}
