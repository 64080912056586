import base64 from "base-64";
import queryString from "query-string";
const baseUrl = "/services/api";
export const calls = {
    GET_PO_MASTER_DATA: {
        url: `${baseUrl}/po`,
    },
    GET_RECON_TYPE_MASTER: {
        url: `${baseUrl}/recon/type`,
    },
    GET_RECON_CONFIG_MASTER: {
        url: `${baseUrl}/recon/configs`,
    },
    GET_RECON_CONFIG: {
        url: `${baseUrl}/recon/config`,
    },
    GET_RECON_RUN_DETAILS: {
        url: `${baseUrl}/recon/job/details`,
        method: "POST",
        body: {},
    },
    RUN_RECON_REPORTS: {
        url: `${baseUrl}/recon/runJob`,
        method: "POST",
        body: {},
    },
    GET_RUN_DETAILS: {
        url: `${baseUrl}/recon/config/reconRunDetailsIds`,
    },
    GET_RECON_DATA: {
        url: `${baseUrl}/recon-data`,
        method: "POST",
        body: {},
    },
    DISABLE_RECON_JOB: {
        url: `${baseUrl}/recon/config/disable`,
        method: "PUT",
        body: {},
    },
    DASHBOARD_CONFIG: {
        url: `${baseUrl}/dashboardConfig`,
    },
    GET_IMPACT_COLUMNS: {
        url: `${baseUrl}/recon/config/impactColumn`,
    },
    GET_RECON_CONFIG_DETAILS: {
        url: `${baseUrl}/recon/job/config/details`,
    },
    GET_LATEST_RECON_DETAILS: {
        url: `${baseUrl}/recon/job/detail/latest`,
    },
    GET_RECON_CONFIG_METADATA: {
        url: `${baseUrl}/recon/config/metadata`,
    },
    GET_RECON_FILTER_OPTIONS: {
        url: `${baseUrl}/recon-data/filter-options`,
    },
    GET_PAGINATED_RECON_DATA: {
        url: `${baseUrl}/recon-data/paginated`,
        method: "POST",
        body: {},
    },
    GET_KPI_METRICS_DATA: {
        url: `${baseUrl}/metrics/kpi-metrics`,
    },
    GET_CASH_BALANCE_CHECKER_DATA: {
        url: `${baseUrl}/metrics/cash-balance-checker`,
        method: "POST",
        body: {},
    },
};
export default (callCreator) => {
    return {
        GET_PO_MASTER_DATA: callCreator((id) => id
            ? queryString.stringifyUrl({
                url: calls.GET_PO_MASTER_DATA.url,
                query: {
                    id,
                },
            })
            : `${calls.GET_PO_MASTER_DATA.url}/all`),
        GET_RECON_TYPE_MASTER: callCreator(() => calls.GET_RECON_TYPE_MASTER.url),
        GET_RECON_CONFIG_MASTER: callCreator((query) => queryString.stringifyUrl({
            url: calls.GET_RECON_CONFIG_MASTER.url,
            query: {
                ...query,
            },
        })),
        GET_RECON_CONFIG: callCreator((query) => queryString.stringifyUrl({
            url: calls.GET_RECON_CONFIG.url,
            query: {
                ...query,
            },
        })),
        GET_IMPACT_COLUMNS: callCreator((query) => queryString.stringifyUrl({
            url: calls.GET_IMPACT_COLUMNS.url,
            query: {
                ...query,
            },
        })),
        GET_RECON_RUN_DETAILS: callCreator((body) => {
            return [
                calls.GET_RECON_RUN_DETAILS.url,
                {
                    method: "POST",
                    body,
                },
            ];
        }),
        SAVE_DASHBOARD_CONFIG: callCreator((body) => [
            calls.DASHBOARD_CONFIG.url,
            {
                body,
                method: "PUT",
            },
        ]),
        UPDATE_RECON_CONFIG: callCreator((reconConfigId, body) => [
            queryString.stringifyUrl({
                url: calls.GET_RECON_CONFIG.url,
                query: {
                    reconConfigId,
                },
            }),
            {
                body,
                method: "PUT",
            },
        ]),
        ADD_RECON_CONFIG: callCreator((body) => [
            calls.GET_RECON_CONFIG.url,
            {
                body,
            },
        ]),
        GET_RECON_FILTER_OPTIONS: callCreator((body) => [
            calls.GET_RECON_FILTER_OPTIONS.url,
            {
                body,
            },
        ]),
        GET_DASHBOARD_CONFIG: callCreator(() => queryString.stringifyUrl({
            url: calls.DASHBOARD_CONFIG.url,
        })),
        RUN_RECON_REPORTS: callCreator((body) => {
            const { reconConfigIds, credentials } = body;
            return [
                calls.RUN_RECON_REPORTS.url,
                {
                    body: {
                        reconConfigIds,
                        password: credentials.password,
                    },
                    ignoreAuth: true,
                    headers: {
                        Authorization: `${base64.encode(`${credentials.adminUsername}:${credentials.adminPassword}`)}`,
                    },
                },
            ];
        }),
        GET_RUN_DETAILS: callCreator((query) => queryString.stringifyUrl({
            url: calls.GET_RUN_DETAILS.url,
            query: {
                ...query,
            },
        })),
        GET_RECON_DATA: callCreator((query) => {
            return [
                calls.GET_RECON_DATA.url,
                {
                    method: "POST",
                    body: { ...query },
                    streamed: "json",
                },
            ];
        }),
        DISABLE_RECON_JOB: callCreator((body) => {
            return [
                calls.DISABLE_RECON_JOB.url,
                {
                    method: "PUT",
                    body,
                },
            ];
        }),
        GET_PAGINATED_RECON_DATA: callCreator((query) => {
            return [
                calls.GET_PAGINATED_RECON_DATA.url,
                {
                    method: "POST",
                    body: { ...query },
                    streamed: "json",
                },
            ];
        }),
        GET_KPI_METRICS_DATA: callCreator((query) => {
            return [
                calls.GET_KPI_METRICS_DATA.url,
                {
                    method: "POST",
                    body: { ...query },
                    streamed: "json",
                },
            ];
        }),
        GET_LATEST_RECON_DETAILS: callCreator((query) => {
            return queryString.stringifyUrl({
                url: calls.GET_LATEST_RECON_DETAILS.url,
                query: {
                    ...query,
                },
            });
        }),
        GET_RECON_CONFIG_DETAILS: callCreator((query) => queryString.stringifyUrl({
            url: calls.GET_RECON_CONFIG_DETAILS.url,
            query: {
                ...query,
            },
        })),
        GET_RECON_CONFIG_METADATA: callCreator((query) => queryString.stringifyUrl({
            url: calls.GET_RECON_CONFIG_METADATA.url,
            query: {
                ...query,
            },
        })),
        GET_CASH_BALANCE_CHECKER_DATA: callCreator((body) => {
            return [
                calls.GET_CASH_BALANCE_CHECKER_DATA.url,
                {
                    method: "POST",
                    body,
                },
            ];
        }),
    };
};
