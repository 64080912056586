import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { createControlled } from "../../forms";
import { DatePicker } from "../DatePickers";
import { DatePresetSelect } from "./DatePresetSelect";
const CUSTOM_DATE = "AsOfDate";
const DatePresetContainer = styled.div `
  display: flex;
  justify-content: space-around;
  column-gap: var(--spacing);
  width: 100%;
`;
export const DatePresetInput = ({ label, value, onChange, errors, name, required, timezone, after = null, before = null, datePickerWidth, ...rest }) => {
    const [showDatePicker, setShowDatePicker] = React.useState(value instanceof Date || value === CUSTOM_DATE);
    const [dateValue, setDateValue] = React.useState();
    React.useEffect(() => {
        setDateValue(value instanceof Date ? value : null);
        setShowDatePicker(value instanceof Date || value === CUSTOM_DATE);
    }, [value]);
    const handleSelectionChange = (selectedOption) => {
        if (selectedOption === CUSTOM_DATE) {
            setShowDatePicker(true);
        }
        else {
            setShowDatePicker(false);
        }
        onChange(selectedOption);
    };
    const handleDateChange = (selectedDate) => {
        setDateValue(selectedDate);
        onChange(selectedDate);
    };
    return (React.createElement(DatePresetContainer, null,
        React.createElement(DatePresetSelect, { label: label, name: name, value: value instanceof Date ? CUSTOM_DATE : value, onChange: handleSelectionChange, errors: errors, required: required, timezone: timezone, ...rest }),
        showDatePicker && (React.createElement(DatePicker, { value: dateValue, label: React.createElement(React.Fragment, null, "\u00A0"), onChange: handleDateChange, minDate: after, maxDate: before, minWidth: `${datePickerWidth ?? 100}px`, ...rest }))));
};
export const ControlledDatePreset = createControlled()(DatePresetInput);
