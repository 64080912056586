import { varReplace } from "./utils/varReplace";
const baseUrl = "/internal/api/legalentity/v1";
export const calls = {
    SAVE_RESTRICTED_ISSUERS: {
        url: `${baseUrl}/issuers/restricted/save`,
        method: "POST",
        body: {},
    },
    SEARCH_ISSUERS: { url: `${baseUrl}/issuers/search` },
    GET_RESTRICTED_ISSUERS: { url: `${baseUrl}/issuers/restricted` },
    GET_ISSUERS_INSTRUMENT: { url: `${baseUrl}/issuers/instrument` },
    GET_ALL_FUNDS: { url: `${baseUrl}/funds/all` },
    GET_TRS_AGREEMENTS: {
        url: `${baseUrl}/agreements/trs/{id}`,
        urlParams: {
            id: { defaultValue: 1 },
        },
    },
    GET_BORROW_AGREEMENTS: {
        url: `${baseUrl}/agreements/borrow/{id}`,
        urlParams: {
            id: { defaultValue: 1 },
        },
    },
    GET_ALL_COUNTERPARTIES: { url: `${baseUrl}/counterparties/all` },
    GET_PORTFOLIO_GROUPS: { url: `${baseUrl}/portfoliogroups` },
    GET_PORTFOLIO_GROUPS_HIERARCHY: {
        url: `${baseUrl}/portfoliogroups/hierarchy`,
    },
    GET_CCP: { url: `${baseUrl}/ccp` },
    GET_FCM: { url: `${baseUrl}/fcm` },
    GET_MARGIN_FUNDS: { url: `${baseUrl}/funds` },
    GET_MARKET_ENV: { url: `${baseUrl}/marketenvironments` },
    GET_QUOTE_SETS: { url: `${baseUrl}/quotesets` },
    GET_SOURCES: { url: `${baseUrl}/marketdata/sources` },
};
export default (callCreator) => {
    return {
        GET_TRS_AGREEMENTS: callCreator((id) => varReplace(calls.GET_TRS_AGREEMENTS.url, { id })),
        GET_BORROW_AGREEMENTS: callCreator((id) => varReplace(calls.GET_BORROW_AGREEMENTS.url, { id })),
        GET_ALL_COUNTERPARTIES: callCreator(() => calls.GET_ALL_COUNTERPARTIES.url),
        GET_PORTFOLIO_GROUPS: callCreator(() => calls.GET_PORTFOLIO_GROUPS.url),
        GET_PORTFOLIO_GROUPS_HIERARCHY: callCreator(() => calls.GET_PORTFOLIO_GROUPS_HIERARCHY.url),
        GET_CCP: callCreator(() => calls.GET_CCP.url),
        GET_FCM: callCreator(() => calls.GET_FCM.url),
        GET_MARGIN_FUNDS: callCreator(() => calls.GET_MARGIN_FUNDS.url),
        GET_MARKET_ENV: callCreator(() => calls.GET_MARKET_ENV.url),
        GET_QUOTE_SETS: callCreator(() => calls.GET_QUOTE_SETS.url),
        GET_SOURCES: callCreator(() => calls.GET_SOURCES.url),
    };
};
