const b16 = (hex) => parseInt(hex, 16);
const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
export function hexToRgbBase(hex, defaultValue = undefined) {
    const result = hexRegex.exec(hex);
    return result
        ? `${b16(result[1])}, ${b16(result[2])}, ${b16(result[3])}`
        : defaultValue;
}
export function hexToRgb(hex, alpha, defaultValue = undefined) {
    const withAlpha = typeof alpha === "number";
    const base = hexToRgbBase(hex, undefined);
    return base
        ? `rgb${withAlpha ? "a" : ""}(${base}${withAlpha ? `, ${alpha}` : ""})`
        : defaultValue;
}
