/* eslint-disable @typescript-eslint/no-explicit-any */
import { lowerCase, words } from "lodash";
import { isNotNull } from "../guards";
import { CONVERSION_MODIFIERS, NUMBER_MODIFIERS, NUMBER_WORD_MAPPING, } from "./units";
function getType(_value, _unit, _outUnit) {
    throw new Error("not implemented just type");
}
const UNIT_KEYS = Object.keys(CONVERSION_MODIFIERS);
function getUnit(wordList) {
    return wordList.find((i) => UNIT_KEYS.includes(i));
}
const MODIFIER_KEYS = Object.keys(NUMBER_MODIFIERS);
function getModifier(wordList) {
    return wordList.find((i) => MODIFIER_KEYS.includes(i));
}
function getValue(wordList) {
    return wordList.reduce((res, key) => res + NUMBER_WORD_MAPPING[key], 0);
}
function createTimeProxy() {
    let inUnit = "milliseconds";
    let value = null;
    const convertValue = (outUnit) => {
        let convertedValue = value ?? 0;
        if (inUnit !== "milliseconds")
            convertedValue *= CONVERSION_MODIFIERS[inUnit];
        return convertedValue / CONVERSION_MODIFIERS[outUnit];
    };
    const timeProxy = new Proxy({}, {
        get: (_target, key) => {
            if (key === "get")
                return (val, unit, outUnit) => {
                    value = val;
                    inUnit = unit || "milliseconds";
                    if (outUnit)
                        return convertValue(outUnit);
                    return timeProxy;
                };
            const allWords = words(key).map(lowerCase);
            const unit = getUnit(allWords);
            if (unit) {
                if (isNotNull(value))
                    return convertValue(unit);
                inUnit = unit;
                return timeProxy;
            }
            const modifier = getModifier(allWords);
            if (modifier) {
                value = (value ?? 0) * NUMBER_MODIFIERS[modifier];
            }
            else {
                value =
                    (value ?? 0) +
                        getValue(allWords);
            }
            return timeProxy;
        },
    });
    return timeProxy;
}
export const TimeProxy = new Proxy({}, {
    get: (_target, key) => createTimeProxy()[key],
});
