import { TOAST_CONTENT, useGeneralizedAuth } from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { fileTreeEntriesToNodeData } from "@enfusion-ui/utils";
import * as React from "react";
import { REST_API } from "../../utils/api";
import { errorToast, successToast } from "../../utils/toast";
const getFolderContent = (files, filePath) => {
    if (filePath === "/") {
        return files;
    }
    const pathParts = filePath.split("/");
    for (const node of files) {
        if (!node.file && node.nodes) {
            if (node.path === filePath)
                return node.nodes;
            // split to try and match path parts so we don't false match on /a/b-1 instead of /a/b
            if (pathParts.slice(0, node.path.split("/").length).join("/") === node.path)
                return getFolderContent(node.nodes, filePath);
        }
    }
    return [];
};
const sectionMap = {
    general: "GeneralFiles",
    services: "Services",
    operations: "Operations",
    managedServices: "ManagedServices",
};
export const FilesProvider = ({ children, enabled, root, StateProvider }) => {
    const toastContent = React.useMemo(() => TOAST_CONTENT[sectionMap[root]], [root]);
    const [files, setFiles] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const isMounted = useMounted();
    const [listParams, setListParams] = React.useState({});
    const { user } = useGeneralizedAuth();
    const fetchStorageList = useRefCallback(async () => {
        try {
            const res = await retrieveNodes(null);
            if (isMounted())
                setFiles(res);
        }
        catch (err) {
            if (isMounted())
                setError(err);
        }
    }, [listParams]);
    React.useEffect(() => {
        async function fetchFiles() {
            if (isMounted())
                setLoading(true);
            try {
                await fetchStorageList();
            }
            finally {
                if (isMounted())
                    setLoading(false);
            }
        }
        if (enabled)
            fetchFiles();
    }, [enabled]);
    const retrieveNodes = useRefCallback(async (node) => {
        let path = node === null ? undefined : typeof node === "string" ? node : node.path;
        if (path === "all" || path === "all/")
            path = undefined;
        try {
            const res = root === "operations" && !user?.adminUser
                ? undefined
                : await REST_API.STORAGE.LIST_NODES.FETCH({
                    ...listParams,
                    root,
                    path,
                });
            return res?.children
                ? fileTreeEntriesToNodeData(res.children, !!listParams.query)
                : [];
        }
        catch (err) {
            throw new Error("Failed to get node details");
        }
    }, [listParams]);
    const checkForDuplicates = async (fileList, filePath) => {
        if (!files || files.length === 0)
            return [];
        const content = getFolderContent(files, filePath);
        if (content.length === 0)
            return [];
        const fileNames = Array.from(fileList).map((i) => i.name);
        return content.reduce((res, node) => {
            if (fileNames.includes(node.name)) {
                return [...res, node.name];
            }
            return res;
        }, []);
    };
    const uploadFiles = useRefCallback(async (fileList, filePath) => {
        try {
            await REST_API.STORAGE.UPLOAD_FILES.FETCH(root, fileList, filePath);
            await fetchStorageList();
            successToast("File(s) uploaded successfully");
        }
        catch (error) {
            errorToast("Failed to upload file(s)");
            console.warn(error);
        }
    }, [fetchStorageList]);
    const deleteFolder = useRefCallback(async (folderPath, onSuccess) => {
        try {
            await REST_API.STORAGE.DELETE_NODE.FETCH(root, folderPath);
            await fetchStorageList();
            successToast(TOAST_CONTENT.folder.delete.success);
            if (onSuccess)
                onSuccess();
        }
        catch (err) {
            errorToast(TOAST_CONTENT.folder.delete.failure, err.message);
            console.warn(TOAST_CONTENT.folder.delete.failure, err);
        }
    }, [fetchStorageList]);
    const deleteFile = useRefCallback(async (filePath, onSuccess) => {
        try {
            await REST_API.STORAGE.DELETE_NODE.FETCH(root, filePath);
            await fetchStorageList();
            successToast(toastContent.delete.success);
            onSuccess?.();
        }
        catch (err) {
            errorToast(toastContent.delete.failure, err.message);
            console.warn(toastContent.delete.failure, err);
        }
    }, [fetchStorageList]);
    const moveFile = useRefCallback(async (filePath, destinationFolder, onSuccess) => {
        try {
            await REST_API.STORAGE.MOVE_NODE.FETCH(root, filePath, destinationFolder);
            await fetchStorageList();
            successToast(toastContent.move.success);
            onSuccess?.();
        }
        catch (err) {
            errorToast(toastContent.move.failure, err.message);
            console.warn(toastContent.move.failure, err);
        }
    }, [fetchStorageList]);
    const createFolder = useRefCallback(async (folderName, folderPath, onSuccess) => {
        try {
            await REST_API.STORAGE.CREATE_FOLDER.FETCH(root, folderName, folderPath);
            await fetchStorageList();
            successToast(TOAST_CONTENT.folder.delete.success);
            onSuccess?.();
        }
        catch (err) {
            errorToast(TOAST_CONTENT.folder.delete.failure, err.message);
            console.warn(TOAST_CONTENT.folder.delete.failure, err);
        }
    }, [fetchStorageList]);
    const setParams = useRefCallback((action) => {
        setListParams(action);
        setTimeout(() => fetchStorageList(), 500);
    }, [setListParams, fetchStorageList]);
    return (React.createElement(StateProvider, { value: {
            files,
            loading,
            fetchStorageList,
            retrieveNodes,
            uploadFiles,
            checkForDuplicates,
            deleteFolder,
            deleteFile,
            moveFile,
            createFolder,
            error,
            setParams,
        } }, children));
};
