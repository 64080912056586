import { replaceDoubleSlashWithSingleSlash } from "@enfusion-ui/utils";
import { createBroadcastMessage } from "./broadcastMessages";
export const AUTH_EVENT = {
    FORBIDDEN: "auth-fb",
    LOGOUT: "auth-lo",
    CONFLICT: "auth-cf",
};
export const authEventMessage = (event, meta) => createBroadcastMessage(event, meta);
function appendBuffer(buffer1, buffer2) {
    var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp;
}
async function readStream(res, reader) {
    const { done, value } = await reader.read();
    if (done)
        return res;
    res = appendBuffer(res, value);
    return await readStream(res, reader);
}
export function createRestServer(AppLogging, broadcastMessage, fetchCall, urlPrefix = "", getStaticHeaders = () => Promise.resolve({})) {
    const logger = AppLogging.prefix("[REST]");
    async function restServerBase(endpoint, params = { credentials: "include" }) {
        const headers = {
            ...params?.headers,
            ...(await getStaticHeaders(params)),
        };
        let body = params?.body;
        if (!(params?.body instanceof FormData)) {
            headers["Content-Type"] = "text/plain";
            if (typeof params?.body === "object") {
                headers["Content-Type"] = "application/json";
                body = JSON.stringify(body);
            }
        }
        const config = {
            method: body ? "POST" : "GET",
            ...params,
            credentials: params.credentials || "include",
            body,
            headers,
        };
        config.method = config.method.toLocaleUpperCase();
        let cleanEndpoint = endpoint.startsWith(urlPrefix)
            ? endpoint.replace(urlPrefix, "")
            : endpoint;
        cleanEndpoint = replaceDoubleSlashWithSingleSlash(`${cleanEndpoint.startsWith("/") ? "" : "/"}${cleanEndpoint}`);
        const REQ_URL = `${urlPrefix}${cleanEndpoint}`;
        logger.info(`${config.method} ${cleanEndpoint}`);
        const response = await fetchCall(REQ_URL, config);
        const logMsg = (content) => logger.info(`${content} (${response.status}): ${config.method} ${cleanEndpoint}`);
        if (response.status === 418) {
            logMsg("auth conflict");
            if (params?.ignoreAuth !== true)
                broadcastMessage(authEventMessage(AUTH_EVENT.CONFLICT));
            throw new Error("Conflict");
        }
        if (response.status === 503) {
            logMsg("server issue");
            throw new Error("Service Not Available");
        }
        if (response.status === 403) {
            logMsg("auth issue");
            broadcastMessage(authEventMessage(AUTH_EVENT.FORBIDDEN, [cleanEndpoint]));
        }
        if (response.status === 401) {
            logMsg("auth error");
            if (params?.ignoreAuth !== true)
                broadcastMessage(authEventMessage(AUTH_EVENT.LOGOUT, ["Unauthorized"]));
            throw new Error("Unauthorized");
        }
        if (typeof params.streamed === "string" && response.body) {
            // Read the stream in chunks
            const res = await readStream(new Uint8Array(), response.body.getReader());
            const textRes = new TextDecoder().decode(res);
            if (params.streamed === "json") {
                try {
                    const json = JSON.parse(textRes);
                    return response.ok ? [json, response] : Promise.reject(json);
                }
                catch (err) {
                    return Promise.reject(err);
                }
            }
            return [textRes, response];
        }
        if (response.headers.get("Content-Type")?.startsWith("application/json")) {
            const json = (await response.json());
            return response.ok ? [json, response] : Promise.reject(json);
        }
        return [(await response.text()), response];
    }
    async function restServer(endpoint, params) {
        const res = await restServerBase(endpoint, params);
        return res[0];
    }
    return { restServer, restServerBase };
}
