import { TimeProxy } from "@enfusion-ui/utils";
import EventEmitter from "eventemitter3";
const RECONNECT_INTERVAL = 5000;
const MAX_RECONNECT_INTERVAL = 5 * 60 * 1000;
const MAX_POLICY_ISSUE_COUNT = 3;
export function createConnectMsg(subTo, authToken, session) {
    const msg = {
        command: "CONNECT",
        "accept-version": "1.2",
        "sub-to": subTo,
    };
    if (authToken !== null)
        msg["X-Auth-Token"] = authToken;
    if (typeof session !== "undefined")
        msg.session = session;
    return JSON.stringify(msg);
}
export class SocketControllerCore {
    params;
    socket;
    preOpenMessages = [];
    reconnectCount = 1;
    policyIssueCount = 0;
    processingClose = false;
    waiting = false;
    closed = false;
    reconnectTimer = -1;
    clearReconnectTimer = -1;
    constructor(params) {
        this.params = {
            ...params,
            onWillReconnect: params.onWillReconnect ?? (() => Promise.resolve(true)),
            reconnectInterval: params.reconnectInterval ?? RECONNECT_INTERVAL,
        };
    }
    onPolicyIssue;
    get logger() {
        return this.loggerInstance.prefix(`[Socket](${this.params.path}${this.params.name ? ` - ${this.params.name}` : ""})`);
    }
    handleClose = async (event) => {
        clearTimeout(this.clearReconnectTimer);
        if (this.processingClose) {
            this.logger.info(`Waiting || Closing: - ${event.reason} - ${event.code} - ${event.wasClean}`);
            setTimeout(() => {
                if (!this.processingClose)
                    this.handleClose(event);
            }, 1000);
        }
        else {
            this.processingClose = true;
            clearTimeout(this.reconnectTimer);
            if (this.waiting) {
                this.waiting = false;
                this.logger.info(`Cancel reconnect (${this.reconnectCount}) - new closing`);
            }
            this.logger.info(`Closing: - ${event.reason} - ${event.code} - ${event.wasClean}`);
            this.params.onClose?.(event, this);
            if (event.code >= 1000 && event.code <= 1002) {
                this.policyIssueCount = 0;
                this.processingClose = false;
                return; // Normal close
            }
            if (event.code === 1008) {
                if (this.policyIssueCount === MAX_POLICY_ISSUE_COUNT && !this.closed) {
                    this.onPolicyIssue?.();
                }
                else {
                    this.policyIssueCount += 1;
                }
            }
            if (!this.closed) {
                this.logger.info(`Will reconnect (${this.reconnectCount})`);
                const shouldContinue = await this.params.onWillReconnect(this);
                if (shouldContinue !== false) {
                    if (!this.closed) {
                        const timeout = Math.min(this.params.reconnectInterval * this.reconnectCount, MAX_RECONNECT_INTERVAL);
                        this.waiting = true;
                        this.reconnectTimer = setTimeout(() => {
                            this.logger.info(`Reconnecting (${this.reconnectCount})`);
                            this.init();
                            this.reconnectCount += 1;
                            this.waiting = false;
                        }, timeout);
                    }
                }
                else {
                    this.logger.info(`Cancel reconnect (${this.reconnectCount})`);
                }
            }
            this.processingClose = false;
        }
    };
    setHeaders = (headers) => {
        this.params.headers = { ...headers };
    };
    init = () => {
        const newSocket = this.createWebSocket(this.params.path, this.params.headers);
        if (newSocket) {
            this.socket = newSocket;
            this.socket.addEventListener("open", () => {
                this.logger.info("Open");
                this.params.onOpen?.(this);
                const msgs = [...this.preOpenMessages];
                this.preOpenMessages.length = 0;
                msgs.forEach((msg) => newSocket.send(msg));
                this.clearReconnectTimer = setTimeout(() => {
                    this.reconnectCount = 1;
                }, 1000);
            });
            this.socket.addEventListener("message", (event) => {
                this.policyIssueCount = 0;
                this.params.onMessage?.(event, this);
            });
            this.socket.addEventListener("error", (event) => {
                this.logger.safeError("Error", new Error(event.message));
                this.params.onError?.(event, this);
                if (this.socket?.readyState === WebSocket.CLOSED) {
                    this.handleClose({
                        ...event,
                        code: -1,
                        reason: "error",
                        wasClean: false,
                    });
                }
            });
            this.socket.addEventListener("close", this.handleClose);
        }
        else {
            this.logger.safeError("Init failed to create a socket");
        }
    };
    reconnect = () => {
        if (this.socket) {
            try {
                this.socket.close(3000, "reconnect");
            }
            catch (err) {
                this.logger.safeError("Reconnect error", err, true);
            }
        }
        else {
            this.init();
        }
    };
    send = (message) => {
        try {
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                this.params.onSend?.(message, this);
                this.socket?.send(message);
            }
            else {
                this.preOpenMessages.push(message);
            }
        }
        catch (err) {
            this.logger.safeError("Send error", err, true);
        }
    };
    close = () => {
        this.logger.info("Close method called");
        this.closed = true;
        clearTimeout(this.reconnectTimer);
        clearTimeout(this.clearReconnectTimer);
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            const e = new Error("close called");
            this.params.onDisconnect?.(e, this);
            this.socket.close(1000, "normal close");
        }
        this.socket = undefined;
    };
    ready = (params) => {
        this.params.onReady?.(this, params);
        const msgs = [...this.preOpenMessages];
        this.preOpenMessages.length = 0;
        msgs.forEach((msg) => this.send(msg));
    };
    getSocketStatus = () => {
        if (this.socket)
            return this.socket.readyState;
        return WebSocket.CLOSED;
    };
}
export const DEFAULT_SOCKET_ID = "DEFAULT_ID";
export class SocketControllerClassBase extends EventEmitter {
    controller = new Map();
    tokenValue = null;
    get token() {
        return this.tokenValue;
    }
    set token(token) {
        this.tokenValue = token;
    }
    activeState = false;
    get active() {
        return this.activeState;
    }
    set active(active) {
        this.activeState = active;
    }
    emitId = (type, payload = {}, id = DEFAULT_SOCKET_ID) => {
        return this.emit(type, { id, payload });
    };
    session = new Map();
    socketPath = null;
    heartbeatInterval = TimeProxy.minutes.five.asMilliseconds;
    heartbeatIntervalId = null;
    reset = () => { };
    onCleanUp = () => { };
    onInitSocket = () => { };
    get controllerCount() {
        return this.controller.size;
    }
    hasId = (id) => this.controller.has(id);
    removeSession = (id = DEFAULT_SOCKET_ID) => {
        this.session.delete(id);
    };
    getSocketStatus = (id = DEFAULT_SOCKET_ID) => {
        return this.controller.get(id)?.getSocketStatus() ?? WebSocket.CLOSED;
    };
    createController = async (id) => {
        const emit = (type, payload = {}) => this.emitId(type, payload, id);
        const controller = new this.SocketController({
            path: this.socketPath,
            name: this.name,
            headers: await this.getSessionHeaders(`${this.name} Socket`),
            onOpen: (controller) => {
                this.onOpen?.(controller, id);
                controller.send(this.getConnectMsg(id));
                emit("open", { readyState: controller.getSocketStatus() });
            },
            onDisconnect: (e, controller) => {
                this.onDisconnect?.(e, controller, id);
                controller.send(this.disconnectMsg);
            },
            onClose: (event, controller) => {
                this.onClose?.(event, controller, id);
                emit("close", {
                    readyState: controller.getSocketStatus(),
                    ...this.closeMsgParams(event, controller, id),
                });
                if (event.code !== 3000)
                    this.reset(id);
            },
            onError: (err, controller) => {
                this.onError?.(err, controller, id);
                emit("error", {
                    readyState: controller.getSocketStatus(),
                    message: JSON.stringify(err),
                    ...this.errorMsgParams(err, controller, id),
                });
            },
            onMessage: async (event, controller) => {
                // should not send message if some provider has terminated the worker/socket
                if (controller.getSocketStatus() === WebSocket.OPEN) {
                    try {
                        if (this.onMessage)
                            await this.onMessage(event, controller, id);
                    }
                    catch (err) {
                        this.onError?.(err, controller, id);
                        emit("error", {
                            readyState: controller.getSocketStatus(),
                            message: JSON.stringify(err),
                            ...this.errorMsgParams(err, controller, id),
                        });
                    }
                }
            },
            onWillReconnect: async (controller) => {
                this.onWillReconnect?.(controller, id);
                if (!this.active)
                    return false;
                controller.setHeaders(await this.getSessionHeaders(`${this.name} Socket`));
            },
            onReady: (controller, args) => this.onReady?.(controller, args ?? null, id),
            onSend: (msg, controller) => this.onSend?.(msg, controller, id),
        });
        this.onCreateController?.(controller, id);
        this.startHeartbeat();
        return controller;
    };
    onCreateController;
    onOpen;
    onMessage;
    onDisconnect;
    onSend;
    onClose;
    closeMsgParams = () => ({});
    onError;
    errorMsgParams = () => ({});
    onReady;
    onWillReconnect;
    send = (msg, id = DEFAULT_SOCKET_ID) => {
        this.controller.get(id)?.send(JSON.stringify(msg));
    };
    heartbeat = () => {
        for (const controller of this.controller.values()) {
            if (controller)
                controller.send("\n");
        }
    };
    startHeartbeat = () => {
        if (this.heartbeatIntervalId === null) {
            this.heartbeatIntervalId = setInterval(() => {
                this.heartbeat();
            }, this.heartbeatInterval);
        }
    };
    stopHeartbeat = () => {
        if (this.controller.size === 0 && this.heartbeatIntervalId !== null) {
            clearInterval(this.heartbeatIntervalId);
            this.heartbeatIntervalId = null;
        }
    };
    getConnectMsg(id = DEFAULT_SOCKET_ID) {
        return createConnectMsg(this.subTo, this.token, this.session.get(id));
    }
    get disconnectMsg() {
        return JSON.stringify({
            command: "DISCONNECT",
            "accept-version": "1.2",
            "sub-to": this.subTo,
        });
    }
    closeController = (id = DEFAULT_SOCKET_ID) => {
        this.controller.get(id)?.close();
        this.controller.delete(id);
        this.stopHeartbeat();
    };
    reconnect = (reason, id = DEFAULT_SOCKET_ID) => {
        this.emitId("reconnect", {
            reason,
            readyState: this.controller.get(id)?.getSocketStatus() ?? WebSocket.CLOSED,
        }, id);
        this.controller.get(id)?.reconnect();
    };
    cleanUp = (id = DEFAULT_SOCKET_ID) => {
        const shouldRmListeners = this.onCleanUp(id);
        if (shouldRmListeners !== false)
            this.removeAllListeners();
        this.closeController(id);
        this.reset(id);
    };
    cleanUpAll = () => {
        const keys = [...this.controller.keys()];
        for (const key of keys) {
            this.cleanUp(key);
        }
    };
    get logger() {
        return this.loggerInstance.prefix(`[Socket](${this.name})`);
    }
    initSocket = async (id = DEFAULT_SOCKET_ID) => {
        try {
            this.controller.get(id)?.close();
            this.controller.set(id, this.createController ? await this.createController(id) : null);
            this.controller.get(id)?.init();
            this.onInitSocket(id);
        }
        catch (err) {
            this.logger.localOnly.safeError("Init Socket Error", err);
        }
    };
}
