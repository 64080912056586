/* eslint-disable @typescript-eslint/no-explicit-any */
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import { createTestId } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
// This import path is required for jest
import { Button } from "../control";
import { Modal } from "./Modal";
const GridContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: var(--spacing-xl);
  gap: var(--spacing-xl);
`;
const ContentContainer = styled.div `
  margin-top: var(--spacing-l);
  white-space: pre-line;
`;
export const ModalActionContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--spacing-l);
`;
const testId = (id) => createTestId("confirmation-modal", id);
export function useConfirmationModal({ renderContent, onCancel, onSubmit, defaultOpen = false, title: defaultTitle, renderTitle, ...props }) {
    const { open, openModal, closeModal, openContentRef } = useModalState({
        defaultOpen,
    });
    const handleCancel = useRefCallback(() => {
        closeModal();
        onCancel?.(openContentRef.current);
    }, [onCancel, closeModal]);
    const handleSubmit = useRefCallback(() => {
        closeModal();
        onSubmit?.(openContentRef.current);
    }, [onSubmit, closeModal]);
    const title = typeof defaultTitle === "string"
        ? defaultTitle
        : typeof renderTitle === "function"
            ? renderTitle(openContentRef.current)
            : undefined;
    return React.useMemo(() => ({
        content: (React.createElement(ConfirmationModal, { title: title, open: open, onCancel: handleCancel, onSubmit: handleSubmit, ...props }, renderContent(openContentRef.current))),
        openModal,
        closeModal,
    }), [
        title,
        closeModal,
        openModal,
        handleCancel,
        handleSubmit,
        renderContent,
        open,
        JSON.stringify(props),
    ]);
}
export const ConfirmationModal = ({ open, title = " ", actions, onSubmit, onCancel, children, submitActionTheme, cancelActionTheme, allowDismissal, submitButtonText = "Continue", cancelButtonText = "Cancel", submitButtonDisabled = false, dataTestId, allowHoyKeys = true, ...props }) => {
    const handleSubmit = useRefCallback(() => {
        if (open && allowHoyKeys)
            onSubmit?.();
    }, [open, onSubmit, allowHoyKeys]);
    useHotkeys("Enter", handleSubmit);
    const handleCancel = useRefCallback(() => {
        if (open && allowHoyKeys)
            onCancel?.();
    }, [open, onCancel, allowHoyKeys]);
    useHotkeys("Esc", handleCancel);
    return (React.createElement(Modal, { ...props, isOpen: open, onClose: onCancel, title: title, allowDismissal: allowDismissal, dataTestId: dataTestId, content: React.createElement(GridContainer, null,
            React.createElement(ContentContainer, null, children),
            React.createElement(ModalActionContainer, null, actions ? (actions) : (React.createElement(React.Fragment, null,
                React.createElement(Button, { title: submitButtonText, theme: submitActionTheme, onClick: onSubmit, disabled: submitButtonDisabled, dataTestId: testId("submit-modal") }, submitButtonText),
                React.createElement(Button, { title: cancelButtonText, theme: cancelActionTheme, onClick: onCancel, dataTestId: testId("cancel-btn") }, cancelButtonText))))) }));
};
