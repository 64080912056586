import React from "react";
export function createErrorBoundary(defaultFallbackComponent) {
    class ErrorBoundaryClass extends React.Component {
        state = { error: null };
        constructor(props) {
            super(props);
            this.state = {
                error: !!props.hasError
                    ? new Error(typeof props.hasError === "string"
                        ? props.hasError
                        : "Sorry, something has gone wrong.")
                    : null,
            };
        }
        resetError = (...args) => {
            this.setState({ error: null });
            this.props.onReset?.(...args);
        };
        static getDerivedStateFromError(error) {
            return { error };
        }
        componentDidCatch(error, info) {
            this.props.onError?.(error, info);
        }
        render() {
            const { FallbackComponent: fc = defaultFallbackComponent, fallbackProps = {}, children, } = this.props;
            const FallbackComponent = fc;
            const { error } = this.state;
            if (!!error)
                return (React.createElement(FallbackComponent, { error: error, resetError: this.resetError, ...fallbackProps }));
            return children;
        }
    }
    return ErrorBoundaryClass;
}
