export const CELL_SIZE = 15;
export const BREAK_POINTS_MAP_V2 = {
    lg: 12,
    md: 10,
    sm: 6,
    xs: 4,
    xxs: 2,
};
export const FIXED_BREAK_POINTS_MAP_V2 = {
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 290,
};
export const BREAK_POINTS_FALLBACK_MAP_V2 = {
    lg: "min",
    md: "min",
    sm: "min",
    xs: "max",
    xxs: "max",
};
export const BREAK_POINTS_OPTIONS = [
    { label: "12", value: 12 },
    { label: "10", value: 10 },
    { label: "6", value: 6 },
    { label: "4", value: 4 },
    { label: "2", value: 2 },
];
export const BREAK_POINT_KEYS_V2 = Object.keys(BREAK_POINTS_MAP_V2);
export function BREAK_POINTS_V2(fixedKey) {
    if (!fixedKey)
        return FIXED_BREAK_POINTS_MAP_V2;
    // Fix the breakpoints so cant resize automatically.
    // Needs all breakpoint keys or will remove the key
    const fixedValue = BREAK_POINTS_MAP_V2[fixedKey];
    return BREAK_POINT_KEYS_V2.reduce((acc, key) => {
        if (BREAK_POINTS_MAP_V2[key] > fixedValue) {
            acc[key] = Number.MAX_SAFE_INTEGER;
        }
        else if (BREAK_POINTS_MAP_V2[key] < fixedValue) {
            acc[key] = -1;
        }
        else {
            acc[key] = 100;
        }
        return acc;
    }, {});
}
export const WIDGET_WIDTH = 16; // rem
export const EDIT_MENU_WIDTH = 280; // px
export const DEFAULT_CUSTOM_ORDER_CONFIG = {
    name: "Custom Order Ticket",
    version: 5,
    config: {
        main: {
            widgets: [
                {
                    id: "instrumentAndStrategyPanel_web",
                    type: "instrumentPanel",
                    config: {
                        defaultValue: null,
                        key: "InstrumentSelectPanel",
                    },
                    properties: {},
                },
                {
                    id: "marketSecurityInfoPanel_web",
                    type: "marketSecurityInfoPanel",
                    config: {
                        defaultValue: null,
                        key: "MarketSecurityInfoPanel",
                    },
                    properties: {},
                },
                {
                    id: "positionDetailsPanel_web",
                    type: "positionDetailsPanel",
                    config: {
                        defaultValue: null,
                        key: "PositionDetailsPanel",
                    },
                    properties: {},
                },
                {
                    id: "orderDetailsPanel_web",
                    type: "orderDetailsPanel",
                    config: {
                        defaultValue: null,
                        key: "OrderDetailsPanel",
                    },
                    properties: {},
                },
                {
                    id: "executionMethodPanel_web",
                    type: "executionMethodPanel",
                    config: {
                        defaultValue: null,
                        key: "ExecutionMethodPanel",
                    },
                    properties: {},
                },
                {
                    id: "allocationsPanel_web",
                    type: "allocationsPanel",
                    config: {
                        defaultValue: null,
                        key: "AllocationPanel",
                    },
                    properties: {},
                },
                {
                    id: "generalInfoPanel_web",
                    type: "generalInfoPanel",
                    config: {
                        defaultValue: null,
                        key: "GeneralInfoPanel",
                    },
                    properties: {},
                },
                {
                    id: "assignmentPanel_web",
                    type: "assignmentPanel",
                    config: {
                        defaultValue: null,
                        key: "AssignmentPanel",
                    },
                    properties: {},
                },
            ],
            placement: {
                lg: [
                    {
                        i: "instrumentAndStrategyPanel_web",
                        x: 1,
                        y: 0,
                        h: 17,
                        w: 5,
                        minH: 7,
                        minW: 2,
                        maxH: 24,
                        maxW: 12,
                    },
                    {
                        i: "marketSecurityInfoPanel_web",
                        x: 6,
                        y: 0,
                        h: 17,
                        w: 5,
                        minH: 3,
                        minW: 2,
                        maxH: 18,
                        maxW: 12,
                    },
                    {
                        i: "positionDetailsPanel_web",
                        x: 1,
                        y: 17,
                        h: 18,
                        w: 10,
                        minH: 18,
                        minW: 2,
                        maxH: 23,
                        maxW: 12,
                    },
                    {
                        i: "orderDetailsPanel_web",
                        x: 1,
                        y: 35,
                        h: 18,
                        w: 10,
                        minH: 15,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "executionMethodPanel_web",
                        x: 1,
                        y: 53,
                        h: 26,
                        w: 10,
                        minH: 26,
                        minW: 2,
                        maxH: 70,
                        maxW: 12,
                    },
                    {
                        i: "allocationsPanel_web",
                        x: 1,
                        y: 115,
                        h: 26,
                        w: 10,
                        minH: 20,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "generalInfoPanel_web",
                        x: 1,
                        y: 79,
                        h: 19,
                        w: 10,
                        minH: 14,
                        minW: 2,
                        maxH: 90,
                        maxW: 12,
                    },
                    {
                        i: "assignmentPanel_web",
                        x: 1,
                        y: 98,
                        h: 17,
                        w: 10,
                        minH: 11,
                        minW: 2,
                        maxH: 25,
                        maxW: 12,
                    },
                ],
                md: [
                    {
                        i: "instrumentAndStrategyPanel_web",
                        x: 0,
                        y: 0,
                        h: 17,
                        w: 5,
                        minH: 7,
                        minW: 2,
                        maxH: 24,
                        maxW: 12,
                    },
                    {
                        i: "marketSecurityInfoPanel_web",
                        x: 5,
                        y: 0,
                        h: 17,
                        w: 5,
                        minH: 3,
                        minW: 2,
                        maxH: 18,
                        maxW: 12,
                    },
                    {
                        i: "positionDetailsPanel_web",
                        x: 0,
                        y: 17,
                        h: 18,
                        w: 10,
                        minH: 18,
                        minW: 2,
                        maxH: 23,
                        maxW: 12,
                    },
                    {
                        i: "orderDetailsPanel_web",
                        x: 0,
                        y: 35,
                        h: 18,
                        w: 10,
                        minH: 15,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "executionMethodPanel_web",
                        x: 0,
                        y: 53,
                        h: 26,
                        w: 10,
                        minH: 26,
                        minW: 2,
                        maxH: 70,
                        maxW: 12,
                    },
                    {
                        i: "allocationsPanel_web",
                        x: 0,
                        y: 115,
                        h: 26,
                        w: 10,
                        minH: 20,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "generalInfoPanel_web",
                        x: 0,
                        y: 79,
                        h: 19,
                        w: 10,
                        minH: 14,
                        minW: 2,
                        maxH: 90,
                        maxW: 12,
                    },
                    {
                        i: "assignmentPanel_web",
                        x: 0,
                        y: 98,
                        h: 17,
                        w: 10,
                        minH: 11,
                        minW: 2,
                        maxH: 25,
                        maxW: 12,
                    },
                ],
                sm: [
                    {
                        i: "instrumentAndStrategyPanel_web",
                        x: 0,
                        y: 0,
                        h: 13,
                        w: 6,
                        minH: 7,
                        minW: 2,
                        maxH: 24,
                        maxW: 12,
                    },
                    {
                        i: "marketSecurityInfoPanel_web",
                        x: 0,
                        y: 13,
                        h: 17,
                        w: 6,
                        minH: 3,
                        minW: 2,
                        maxH: 18,
                        maxW: 12,
                    },
                    {
                        i: "positionDetailsPanel_web",
                        x: 0,
                        y: 30,
                        h: 18,
                        w: 6,
                        minH: 18,
                        minW: 2,
                        maxH: 23,
                        maxW: 12,
                    },
                    {
                        i: "orderDetailsPanel_web",
                        x: 0,
                        y: 48,
                        h: 18,
                        w: 6,
                        minH: 15,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "executionMethodPanel_web",
                        x: 0,
                        y: 66,
                        h: 26,
                        w: 6,
                        minH: 26,
                        minW: 2,
                        maxH: 70,
                        maxW: 12,
                    },
                    {
                        i: "allocationsPanel_web",
                        x: 0,
                        y: 128,
                        h: 26,
                        w: 6,
                        minH: 20,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "generalInfoPanel_web",
                        x: 0,
                        y: 92,
                        h: 19,
                        w: 6,
                        minH: 14,
                        minW: 2,
                        maxH: 90,
                        maxW: 12,
                    },
                    {
                        i: "assignmentPanel_web",
                        x: 0,
                        y: 111,
                        h: 17,
                        w: 6,
                        minH: 11,
                        minW: 2,
                        maxH: 25,
                        maxW: 12,
                    },
                ],
                xs: [
                    {
                        i: "instrumentAndStrategyPanel_web",
                        x: 0,
                        y: 0,
                        h: 17,
                        w: 4,
                        minH: 7,
                        minW: 2,
                        maxH: 24,
                        maxW: 12,
                    },
                    {
                        i: "marketSecurityInfoPanel_web",
                        x: 0,
                        y: 17,
                        h: 17,
                        w: 4,
                        minH: 3,
                        minW: 2,
                        maxH: 18,
                        maxW: 12,
                    },
                    {
                        i: "positionDetailsPanel_web",
                        x: 0,
                        y: 34,
                        h: 18,
                        w: 4,
                        minH: 18,
                        minW: 2,
                        maxH: 23,
                        maxW: 12,
                    },
                    {
                        i: "orderDetailsPanel_web",
                        x: 0,
                        y: 52,
                        h: 18,
                        w: 4,
                        minH: 15,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "executionMethodPanel_web",
                        x: 0,
                        y: 70,
                        h: 26,
                        w: 4,
                        minH: 26,
                        minW: 2,
                        maxH: 70,
                        maxW: 12,
                    },
                    {
                        i: "allocationsPanel_web",
                        x: 0,
                        y: 132,
                        h: 26,
                        w: 4,
                        minH: 20,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "generalInfoPanel_web",
                        x: 0,
                        y: 96,
                        h: 19,
                        w: 4,
                        minH: 14,
                        minW: 2,
                        maxH: 90,
                        maxW: 12,
                    },
                    {
                        i: "assignmentPanel_web",
                        x: 0,
                        y: 115,
                        h: 17,
                        w: 4,
                        minH: 11,
                        minW: 2,
                        maxH: 25,
                        maxW: 12,
                    },
                ],
                xxs: [
                    {
                        w: 2,
                        h: 17,
                        x: 0,
                        y: 0,
                        i: "instrumentAndStrategyPanel_web",
                        minW: 2,
                        maxW: 12,
                        minH: 7,
                        maxH: 24,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 2,
                        h: 17,
                        x: 0,
                        y: 17,
                        i: "marketSecurityInfoPanel_web",
                        minW: 2,
                        maxW: 12,
                        minH: 3,
                        maxH: 18,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 2,
                        h: 18,
                        x: 0,
                        y: 34,
                        i: "positionDetailsPanel_web",
                        minW: 2,
                        maxW: 12,
                        minH: 18,
                        maxH: 23,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 2,
                        h: 18,
                        x: 0,
                        y: 52,
                        i: "orderDetailsPanel_web",
                        minW: 2,
                        maxW: 12,
                        minH: 15,
                        maxH: 42,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 2,
                        h: 26,
                        x: 0,
                        y: 70,
                        i: "executionMethodPanel_web",
                        minW: 2,
                        maxW: 12,
                        minH: 26,
                        maxH: 70,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 2,
                        h: 26,
                        x: 0,
                        y: 132,
                        i: "allocationsPanel_web",
                        minW: 2,
                        maxW: 12,
                        minH: 20,
                        maxH: 42,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 2,
                        h: 19,
                        x: 0,
                        y: 96,
                        i: "generalInfoPanel_web",
                        minW: 2,
                        maxW: 12,
                        minH: 14,
                        maxH: 90,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 2,
                        h: 17,
                        x: 0,
                        y: 115,
                        i: "assignmentPanel_web",
                        minW: 2,
                        maxW: 12,
                        minH: 11,
                        maxH: 25,
                        moved: false,
                        static: false,
                    },
                ],
            },
        },
    },
    settings: {
        compactType: "vertical",
        isLayoutLocked: true,
        orientation: "portrait",
    },
    configVersion: 2,
    datasources: [],
};
export const DEFAULT_CUSTOM_ORDER_MOBILE_CONFIG = {
    name: "Custom Order Ticket",
    version: 3,
    config: {
        main: {
            widgets: [
                {
                    id: "instrumentAndStrategyPanel_mobile",
                    type: "instrumentPanel",
                    config: {
                        defaultValue: null,
                        key: "InstrumentSelectPanel",
                    },
                    properties: {},
                },
                {
                    id: "marketInfoPanel_mobile",
                    type: "marketInfoPanel",
                    config: {
                        defaultValue: null,
                        key: "MarketInfoPanel",
                    },
                    properties: {},
                },
                {
                    id: "orderDetailsPanel_mobile",
                    type: "orderDetailsPanel",
                    config: {
                        defaultValue: null,
                        key: "OrderDetailsPanel",
                    },
                    properties: {},
                },
                {
                    id: "executionMethodPanel_mobile",
                    type: "executionMethodPanel",
                    config: {
                        defaultValue: null,
                        key: "ExecutionMethodPanel",
                    },
                    properties: {},
                },
                {
                    id: "allocationsPanel_mobile",
                    type: "allocationsPanel",
                    config: {
                        defaultValue: null,
                        key: "AllocationPanel",
                    },
                    properties: {},
                },
                {
                    id: "generalInfoPanel_mobile",
                    type: "generalInfoPanel",
                    config: {
                        defaultValue: null,
                        key: "GeneralInfoPanel",
                    },
                    properties: {},
                },
                {
                    id: "assignmentPanel_mobile",
                    type: "assignmentPanel",
                    config: {
                        defaultValue: null,
                        key: "AssignmentPanel",
                    },
                    properties: {},
                },
            ],
            placement: {
                xxs: [
                    {
                        i: "instrumentAndStrategyPanel_mobile",
                        x: 0,
                        y: 0,
                        h: 7,
                        w: 2,
                        minH: 7,
                        minW: 2,
                        maxH: 24,
                        maxW: 12,
                    },
                    {
                        i: "marketInfoPanel_mobile",
                        x: 0,
                        y: 7,
                        h: 3,
                        w: 2,
                        minH: 3,
                        minW: 2,
                        maxH: 18,
                        maxW: 12,
                    },
                    {
                        i: "orderDetailsPanel_mobile",
                        x: 0,
                        y: 10,
                        h: 15,
                        w: 2,
                        minH: 15,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "executionMethodPanel_mobile",
                        x: 0,
                        y: 25,
                        h: 27,
                        w: 2,
                        minH: 26,
                        minW: 2,
                        maxH: 70,
                        maxW: 12,
                    },
                    {
                        i: "allocationsPanel_mobile",
                        x: 0,
                        y: 77,
                        h: 20,
                        w: 2,
                        minH: 20,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "generalInfoPanel_mobile",
                        x: 0,
                        y: 97,
                        h: 14,
                        w: 2,
                        minH: 14,
                        minW: 2,
                        maxH: 90,
                        maxW: 12,
                    },
                    {
                        i: "assignmentPanel_mobile",
                        x: 0,
                        y: 111,
                        h: 11,
                        w: 2,
                        minH: 11,
                        minW: 2,
                        maxH: 25,
                        maxW: 12,
                    },
                ],
                xs: [
                    {
                        i: "instrumentAndStrategyPanel_mobile",
                        x: 0,
                        y: 0,
                        h: 7,
                        w: 4,
                        minH: 7,
                        minW: 2,
                        maxH: 24,
                        maxW: 12,
                    },
                    {
                        i: "marketInfoPanel_mobile",
                        x: 0,
                        y: 7,
                        h: 3,
                        w: 4,
                        minH: 3,
                        minW: 2,
                        maxH: 18,
                        maxW: 12,
                    },
                    {
                        i: "orderDetailsPanel_mobile",
                        x: 0,
                        y: 10,
                        h: 15,
                        w: 4,
                        minH: 15,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "executionMethodPanel_mobile",
                        x: 0,
                        y: 25,
                        h: 27,
                        w: 4,
                        minH: 26,
                        minW: 2,
                        maxH: 70,
                        maxW: 12,
                    },
                    {
                        i: "allocationsPanel_mobile",
                        x: 0,
                        y: 72,
                        h: 20,
                        w: 4,
                        minH: 20,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "generalInfoPanel_mobile",
                        x: 0,
                        y: 92,
                        h: 14,
                        w: 4,
                        minH: 14,
                        minW: 2,
                        maxH: 90,
                        maxW: 12,
                    },
                    {
                        i: "assignmentPanel_mobile",
                        x: 0,
                        y: 106,
                        h: 11,
                        w: 4,
                        minH: 11,
                        minW: 2,
                        maxH: 25,
                        maxW: 12,
                    },
                ],
                sm: [
                    {
                        i: "instrumentAndStrategyPanel_mobile",
                        x: 1,
                        y: 0,
                        h: 7,
                        w: 4,
                        minH: 7,
                        minW: 2,
                        maxH: 24,
                        maxW: 12,
                    },
                    {
                        i: "marketInfoPanel_mobile",
                        x: 1,
                        y: 7,
                        h: 3,
                        w: 4,
                        minH: 3,
                        minW: 2,
                        maxH: 18,
                        maxW: 12,
                    },
                    {
                        i: "orderDetailsPanel_mobile",
                        x: 1,
                        y: 10,
                        h: 15,
                        w: 4,
                        minH: 15,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "executionMethodPanel_mobile",
                        x: 1,
                        y: 25,
                        h: 27,
                        w: 4,
                        minH: 26,
                        minW: 2,
                        maxH: 70,
                        maxW: 12,
                    },
                    {
                        i: "allocationsPanel_mobile",
                        x: 1,
                        y: 72,
                        h: 20,
                        w: 4,
                        minH: 20,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "generalInfoPanel_mobile",
                        x: 1,
                        y: 92,
                        h: 14,
                        w: 4,
                        minH: 14,
                        minW: 2,
                        maxH: 90,
                        maxW: 12,
                    },
                    {
                        i: "assignmentPanel_mobile",
                        x: 1,
                        y: 106,
                        h: 11,
                        w: 4,
                        minH: 11,
                        minW: 2,
                        maxH: 25,
                        maxW: 12,
                    },
                ],
                md: [
                    {
                        w: 8,
                        h: 7,
                        x: 1,
                        y: 0,
                        i: "instrumentAndStrategyPanel_mobile",
                        minW: 2,
                        maxW: 12,
                        minH: 7,
                        maxH: 24,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 8,
                        h: 3,
                        x: 1,
                        y: 7,
                        i: "marketInfoPanel_mobile",
                        minW: 2,
                        maxW: 12,
                        minH: 3,
                        maxH: 18,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 8,
                        h: 15,
                        x: 1,
                        y: 10,
                        i: "orderDetailsPanel_mobile",
                        minW: 2,
                        maxW: 12,
                        minH: 15,
                        maxH: 42,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 8,
                        h: 27,
                        x: 1,
                        y: 25,
                        i: "executionMethodPanel_mobile",
                        minW: 2,
                        maxW: 12,
                        minH: 26,
                        maxH: 70,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 8,
                        h: 20,
                        x: 1,
                        y: 64,
                        i: "allocationsPanel_mobile",
                        minW: 2,
                        maxW: 12,
                        minH: 20,
                        maxH: 42,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 8,
                        h: 14,
                        x: 1,
                        y: 84,
                        i: "generalInfoPanel_mobile",
                        minW: 2,
                        maxW: 12,
                        minH: 14,
                        maxH: 90,
                        moved: false,
                        static: false,
                    },
                    {
                        w: 8,
                        h: 11,
                        x: 1,
                        y: 98,
                        i: "assignmentPanel_mobile",
                        minW: 2,
                        maxW: 12,
                        minH: 11,
                        maxH: 25,
                        moved: false,
                        static: false,
                    },
                ],
                lg: [
                    {
                        i: "instrumentAndStrategyPanel_mobile",
                        x: 2,
                        y: 0,
                        h: 7,
                        w: 8,
                        minH: 7,
                        minW: 2,
                        maxH: 24,
                        maxW: 12,
                    },
                    {
                        i: "marketInfoPanel_mobile",
                        x: 2,
                        y: 7,
                        h: 3,
                        w: 8,
                        minH: 3,
                        minW: 2,
                        maxH: 18,
                        maxW: 12,
                    },
                    {
                        i: "orderDetailsPanel_mobile",
                        x: 2,
                        y: 10,
                        h: 15,
                        w: 8,
                        minH: 15,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "executionMethodPanel_mobile",
                        x: 2,
                        y: 25,
                        h: 27,
                        w: 8,
                        minH: 26,
                        minW: 2,
                        maxH: 70,
                        maxW: 12,
                    },
                    {
                        i: "allocationsPanel_mobile",
                        x: 2,
                        y: 64,
                        h: 20,
                        w: 8,
                        minH: 20,
                        minW: 2,
                        maxH: 42,
                        maxW: 12,
                    },
                    {
                        i: "generalInfoPanel_mobile",
                        x: 2,
                        y: 84,
                        h: 14,
                        w: 8,
                        minH: 14,
                        minW: 2,
                        maxH: 90,
                        maxW: 12,
                    },
                    {
                        i: "assignmentPanel_mobile",
                        x: 2,
                        y: 98,
                        h: 11,
                        w: 8,
                        minH: 11,
                        minW: 2,
                        maxH: 25,
                        maxW: 12,
                    },
                ],
            },
        },
    },
    settings: {
        compactType: "vertical",
        isLayoutLocked: true,
        orientation: "portrait",
    },
    configVersion: 2,
    datasources: [],
};
export const DASHBOARD_ORIENTATION_OPTIONS = [
    { label: "Portrait", value: "portrait" },
    { label: "Landscape", value: "landscape" },
];
