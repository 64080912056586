import { createGlobalStyle, css } from "../utils/styledComponents";
import { getCss } from "./utils";
export const SCROLL_BAR_WIDTH = 10;
export const SCROLL_BAR_WIDTH_AFTER_BORDER = SCROLL_BAR_WIDTH - 2;
export const GlobalStyles = createGlobalStyle `
  :root {
    ${({ theme }) => getCss(theme)}
  }

  body {
    margin: 0;
    font-family: var(--default-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
    background-color: var(--background-color-2);
    color: var(--text-normal);
    position: absolute;
    overflow: hidden;
    height: 100vh;
    /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    /* Use vh as a fallback for browsers that do not support Custom Properties */
    width: calc(var(--vw, 1vw) * 100);
  }

  html {
    line-height: 1.5;
    height: 100vh;
    /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    /* Use vh as a fallback for browsers that do not support Custom Properties */
    width: calc(var(--vw, 1vw) * 100);
    margin: 0;
  }

  #root {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--header-font);
    font-weight: normal;
    margin: 0;
  }

  :focus {
    outline: none;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  input[type="range"]::-moz-focus-outer {
    border: 0;
  }

  /* #region scrollbar */
  *::-webkit-scrollbar {
    height: ${SCROLL_BAR_WIDTH_AFTER_BORDER}px;
    width: ${SCROLL_BAR_WIDTH_AFTER_BORDER}px;
  }

  *::-webkit-scrollbar-thumb {
    border: ${SCROLL_BAR_WIDTH_AFTER_BORDER / 2}px solid transparent;
    border-radius: ${SCROLL_BAR_WIDTH_AFTER_BORDER}px;
    background-color: var(--background-accent);
  }

  *::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* #endregion */

  .negativeCell {
    color: var(--danger);
  }

  .grid-highlighted-row {
    background-color: var(--primary-hover) !important
  }

  /* Corner triangles for grids */
  .errorCell::after,
  .warningCell::after {
    content: "";
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    right: 0px;
    z-index: 2;
  }

  .errorCell::after {
    border-right-color: var(--danger);
  }

  .warningCell::after {
    border-right-color: var(--warning);
  }

  .errorText {
    color: var(--danger);
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .flexlayout__tab > [id^=tab-portfolio] {
    position: relative;
    overflow: hidden;
  }

  .ag-react-container {
    height: inherit;
  }

  .ag-status-bar {
    line-height: 1 !important;
  }

  .ag-details-row {
    padding: 0px;
    padding-left: var(--spacing-xl);
  }

  .ag-cell-data-changed {
    background-color: var(--primary-hover) !important;
  }

  .ag-set-filter {
  margin-right: 3px;
  }

  .ag-column-select-list {
  margin-right: 3px;
  margin-top: 3px;
  }

  .ag-menu-option-icon [data-icon="file-excel"] [data-icon="file-csv"]{
    height: 16px;
    width: 16px;
  }

  [data-reach-popover] {
    z-index: 2000;
  }

  .warning-toast {
    background-color: var(--warning);
    color: black;
  }

  .error-toast {
    background-color: var(--danger);
    color: black;
  }

  .success-toast {
    background-color: var(--success);
  }

  .info-toast {
    background-color: var(--info);
  }

  .default-toast {
    color: black;
  }

  /* #region RC Menu Overrides */

  .rc-menu-vertical,
  .rc-menu-vertical-left,
  .rc-menu-vertical-right,
  .rc-menu-inline {
    padding: 4px 0 !important;
    max-width: 100vw;
  }

  .rc-menu {
    margin-top: 0;
  }

  .rc-menu,
  .rc-menu-submenu-popup,
  .rc-menu-submenu>.rc-menu {
    background-color: var(--background-color-0) !important;
    border-color: var(--border) !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2) !important;
    min-width: 160px;
  }

  .rc-menu-dashboard {
    background: var(--background-color-2) !important;
    border: 0.5px solid var(--primary) !important;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5) !important;
    border-radius: 4px;
    margin-top: 5px;
    min-width: 65px !important;
  }

  .rc-menu-dashboard-listView {
    background-color: var(--background-color-1) !important;
    border-color: var(--border) !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5) !important;
    border-radius: 5px;
    margin-top: 5px;
    min-width: 160px !important;
  }

  .rc-menu-item,
  .rc-menu-submenu-title {
    padding: var(--spacing-s) 0.5rem var(--spacing-s) 8px !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
  }

  .rc-menu-submenu-popup {
    z-index: 100;
  }

  .rc-menu-item {
    color: var(--text-normal);
    cursor: pointer;
  }

  .rc-menu-item-selected,
  .rc-menu-submenu-selected {
    background-color: var(--primary) !important;
    color: black;
  }

  .rc-menu-item-active,
  .rc-menu-submenu-active,
  .rc-menu-submenu-active>.rc-menu-submenu-title {
    background-color: var(--primary-hover) !important;
  }

  .rc-menu-item-divider {
    background-color: var(--border) !important;
  }

  /* #endregion */


  /* #region flex layout overrides */

  .flexlayout__layout {
    --font-family: var(--default-font);
    font-family: var(--default-font);
  }

  .flexlayout__layout .flexlayout__tabset .flexlayout__tabset_tabbar_outer {
    background-color: var(--background-color-0);
    border-bottom-width: 0px;
    font-size: var(--default-font-size);
  }

  .layout-tab-set-strip {
    height: var(--tab-container-height) !important;
  }

  .flexlayout__layout .flexlayout__tabset .flexlayout__tabset_tabbar_outer.flexlayout__tabset-selected {
    background-image: none;
    height: var(--tab-container-height) !important;
  }

  .flexlayout__layout .flexlayout__tabset .flexlayout__tab_button.flexlayout__tab_button--selected {
    background-color: var(--background-color-2);
    color: var(--text-normal);
  }

  .flexlayout__layout .flexlayout__tab {
    background-color: var(--background-color-2);
    color: var(--text-normal);
  }

  .flexlayout__layout .flexlayout__tab_button {
    max-width: 300px;
    box-shadow: none;
  }

  .flexlayout__layout .flexlayout__tab_button:hover {
    background-color: var(--background-accent);
    color: var(--text-normal);
  }

  .flexlayout__layout .flexlayout__tab_button .flexlayout__tab_button_content {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }


  /* #endregion */

  .material-icons {
    font-family: 'Material Icons' !important;
  }

  /* #region color picker */

  .color-picker-portal {
    border-radius: 0px !important;
    border-width: 0px !important;
    box-shadow: none !important;
  }

  .sketch-picker {
    background-color: var(--background-color-0) !important;
  }

  .sketch-picker input {
    background-color: var(--input-background) !important;
    border: 1px solid var(--border) !important;
    color: var(--text-normal);
    box-shadow: none !important;
    border-radius: 4px;
  }

  .sketch-picker input+label {
    color: var(--text-normal) !important;
  }

  .sketch-picker div.flexbox-fix:last-of-type {
    border-color: var(--background-color-2) !important;
  }

  /* #endregion */

  .react-resizable-handle {
    z-index: 50;
  }

  .react-grid-item > .react-resizable-handle::after {
    border-right: 2px solid var(--text-normal);
    border-bottom: 2px solid var(--text-normal);
  }

  .react-grid-layout.layout {
    height: 100%;
    width: 100%;
  }

  /* #region React Datepicker */

  .react-datepicker {
    font-family: var(--default-font);
    font-size: 0.75rem;
    border: 1px solid var(--border);
    border-radius: 4px;
  }

  .react-datepicker-popper {
    z-index: var(--calendar-z);
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: var(--primary);
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: var(--primary-hover);
  }

  .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: var(--primary);
  }

  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: var(--primary-hover);
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 4px;
    background-color: var(--primary);
  }

  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: var(--primary-hover);
  }

  .react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: var(--primary);
  }

  .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--primary-hover);
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: var(--primary);
  }

  .react-datepicker__close-icon::after {
    background-color: var(--primary);
  }

  ${({ noCharting = false }) => noCharting
    ? ""
    : css `
          .highcharts-tooltip-container {
            z-index: 200 !important;
          }

          .highcharts-axis-title {
            fill: var(--text-normal) !important;
          }

          .highcharts-data-label text,
          .highcharts-xaxis-labels text,
          .highcharts-yaxis-labels text,
          .highcharts-legend-item text {
            font-family: var(--default-font);
            font-style: normal !important;
            font-weight: normal !important;
            text-align: center;
            fill: var(--text-normal) !important;
          }

          .highcharts-text-outline {
            fill: var(--text-normal) !important;
            stroke: var(--text-normal) !important;
            stroke-width: 0.5px;
          }
        `}

  .reactour__popover {
    padding: var(--spacing-xxl) !important;
  }

  .reactour__close-button {
    top: var(--spacing-xl) !important;
    right: var(--spacing-xl) !important;
  }

  .resizing .widget-theme-grid,
  .resizing .ag-theme-webapp {
    display: none;
  }

  .ag-theme-webapp {
    ${({ theme }) => getCss(theme, true)}
  }
`;
