import { useRefCallback } from "@enfusion-ui/hooks";
// import { isValidDate } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { isBefore } from "date-fns";
import { debounce } from "lodash";
import * as React from "react";
import DatePicker from "react-datepicker";
import { IconButton } from "../../control/IconButton";
import { TextInput } from "../TextInput";
import { DEFAULT_PLACEHOLDER, DEFAULT_PORTAL_ID } from "./constants";
import { ErrorIcon } from "./ErrorIcon";
const START_AFTER_END_ERROR = "The start date cannot be after the end date";
const InputContainer = styled.div `
  display: flex;
  align-items: center;
`;
const Separator = styled.div `
  padding: 0 var(--spacing);
  margin: 0.5rem;
`;
const IconContainer = styled.div `
  display: flex;
`;
const ClearIcon = styled(IconButton).attrs({ icon: faTimes }) `
  padding: 0;
`;
export function DateRangePicker({ className, onChange, startName, endName, startLabel, endLabel, defaultValue = [null, null], placeholder = DEFAULT_PLACEHOLDER, errors, portalId = DEFAULT_PORTAL_ID, minWidth, hideLabel = false, ...rest }) {
    const [error, setError] = React.useState(null);
    const [dateRange, setDateRange] = React.useState([
        null,
        null,
    ]);
    const handleDefaultChange = useRefCallback(debounce((newDefaultValue) => {
        setDateRange([
            newDefaultValue?.[0] ?? null,
            newDefaultValue?.[1] ?? null,
        ]);
    }, 200), []);
    React.useEffect(() => {
        handleDefaultChange(defaultValue);
    }, [defaultValue.map((i) => i?.toString()).join("-")]);
    const handleChange = useRefCallback((newRange) => {
        setDateRange(newRange);
        const endDate = newRange[1];
        const startDate = newRange[0];
        if (endDate && startDate && isBefore(endDate, startDate)) {
            setError(START_AFTER_END_ERROR);
        }
        else {
            onChange([startDate, endDate]);
            setError(null);
        }
    }, []);
    const handleStartRangeChange = useRefCallback((startDate) => {
        handleChange([startDate, dateRange[1]]);
    }, [dateRange]);
    const handleEndRangeChange = useRefCallback((endDate) => {
        handleChange([dateRange[0], endDate]);
    }, [dateRange]);
    return (React.createElement(InputContainer, { className: className },
        React.createElement("div", null,
            React.createElement(DatePicker, { customInput: React.createElement(TextInput, { name: startName, label: startLabel, errors: errors, minWidth: minWidth, hideLabel: hideLabel, icon: React.createElement(IconContainer, null,
                        React.createElement(ErrorIcon, { error: error }),
                        React.createElement(ClearIcon, { name: "clearStartDate", onClick: () => handleStartRangeChange(null) })) }), name: startName, selected: dateRange[0], selectsStart: true, startDate: dateRange[0], endDate: dateRange[1], onChange: handleStartRangeChange, placeholderText: placeholder, portalId: portalId, ...rest })),
        React.createElement(Separator, null, "\u2013"),
        React.createElement("div", null,
            React.createElement(DatePicker, { customInput: React.createElement(TextInput, { name: endName, label: endLabel, errors: errors, minWidth: minWidth, hideLabel: hideLabel, icon: React.createElement(IconContainer, null,
                        React.createElement(ErrorIcon, { error: error, align: "right" }),
                        React.createElement(ClearIcon, { name: "clearEndDate", onClick: () => handleEndRangeChange(null) })) }), name: endName, selected: dateRange[1], selectsEnd: true, startDate: dateRange[0], endDate: dateRange[1], onChange: handleEndRangeChange, placeholderText: placeholder, portalId: portalId, ...rest }))));
}
