import { ReconStatusTypeMap } from "@enfusion-ui/types";
import { differenceInMinutes, isAfter, parse } from "date-fns";
import { last } from "lodash";
import { convertUTCTimeToTime } from "./date";
const STATUS = [
    "Success",
    "Failed",
    "Running",
    "Queued",
    "TimedOut",
    "Subscribed",
];
export const RunningNodes = [
    ReconStatusTypeMap.Running,
    ReconStatusTypeMap.Queued,
    ReconStatusTypeMap.Subscribed,
];
export const getNodeStatus = (reconConfig) => {
    if (!reconConfig)
        return ReconStatusTypeMap.NeverRun;
    const { lastRunJobStatus, expectedSlaTime } = reconConfig;
    if (STATUS.includes(lastRunJobStatus))
        return ReconStatusTypeMap[lastRunJobStatus];
    if (!expectedSlaTime)
        return ReconStatusTypeMap.NeverRun;
    const currentTime = new Date();
    const slaTime = parse(convertUTCTimeToTime(expectedSlaTime), "HH:mm:ss", new Date());
    const diffMinutes = Math.abs(differenceInMinutes(currentTime, slaTime));
    const isAfterCurrentTime = isAfter(currentTime, slaTime);
    if (isAfterCurrentTime)
        return ReconStatusTypeMap.Overdue;
    if (diffMinutes <= 30)
        return ReconStatusTypeMap.CloseToOverdue;
    return ReconStatusTypeMap.NeverRun;
};
const extractLastSegment = (decodedName) => {
    const lastSegment = last(decodedName.split("/"));
    if (lastSegment) {
        return lastSegment.replace(/\.[^/.]+$/, "");
    }
    return decodedName;
};
export const extractReportName = (path) => {
    try {
        const urlParams = new URL(path).searchParams;
        const nameValue = urlParams.get("name");
        if (nameValue) {
            const decodedName = decodeURIComponent(nameValue);
            return extractLastSegment(decodedName);
        }
    }
    catch (e) {
        return extractLastSegment(path);
    }
    return path;
};
