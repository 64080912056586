export function addDbMethods(entity) {
    return new Proxy(entity, {
        get: (target, key) => {
            switch (key) {
                case "fetchAll":
                    return async () => target.toArray();
                case "fetchByKey":
                    return async (key, value) => target.where({ [key]: value }).toArray();
                case "fetchOne":
                    return async (key) => target.where(key).first();
                case "fetchWithFilter":
                    return async (predicate) => target.filter(predicate).toArray();
                case "upsert":
                    return async (updateRecord, id = null) => {
                        if (id !== null) {
                            await target.update(id, updateRecord);
                            return id;
                        }
                        return await target.add(updateRecord);
                    };
                default:
                    return target[key];
            }
        },
    });
}
