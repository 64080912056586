/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback } from "@enfusion-ui/hooks";
import { styled } from "@enfusion-ui/web-core";
import { kebabCase } from "lodash";
import * as React from "react";
import { components, } from "react-select";
import { createControlled } from "../forms";
import { Checkbox } from "./Checkbox";
import { getOptionValue, Select } from "./Select";
export const ValuesWrapper = styled.div `
  position: absolute;
  top: 25%;
  left: 10px;
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;
function getDisplayValues(values, showValuesOnSelection = false) {
    return values
        .map(({ value, label }) => (showValuesOnSelection ? value : label))
        .join(", ");
}
const ValueContainer = ({ children, ...props }) => {
    const placeholder = children[0];
    const searchInput = children[1];
    const showValuesOnSelection = props.selectProps?.controlShouldRenderValue ?? false;
    const displayValues = getDisplayValues(props.getValue(), showValuesOnSelection);
    const showDisplayValues = props.selectProps.inputValue ? false : true;
    return (React.createElement(components.ValueContainer, { ...props },
        displayValues.length === 0 ? (placeholder) : showDisplayValues ? (React.createElement(ValuesWrapper, { title: displayValues }, displayValues)) : null,
        searchInput));
};
const Option = (props) => {
    return (React.createElement(components.Option, { ...props },
        React.createElement("div", { style: { display: "flex", columnGap: "var(--spacing-l)" } },
            React.createElement(Checkbox, { noContainer: true, checked: props.isSelected, iconSize: "1x", iconStyle: {} }),
            React.createElement("label", null, props.label))));
};
export function MultiSelectCore({ components, onChange, ...props }) {
    return (React.createElement(Select, { isMulti: true, components: { Option, ValueContainer, ...components }, closeMenuOnSelect: false, hideSelectedOptions: false, minWidth: 150, onChange: onChange, controlShouldRenderValue: false, ...props }));
}
export function MultiSelect({ value: values, onChange, options, nullValue, labelGetter, ...props }) {
    const [value, setStored] = React.useState([]);
    React.useEffect(() => {
        setStored(!!values
            ? getOptionValue(options, values, true, props.creatable, labelGetter)
            : null);
    }, [values, options, labelGetter]);
    const handleChange = useRefCallback((results) => {
        setStored(results);
        const newValues = results === null ? null : results.map((opt) => opt?.value);
        onChange?.(newValues, results);
    }, [onChange, nullValue]);
    return (React.createElement(MultiSelectCore, { ...props, value: value, options: options, onChange: handleChange }));
}
export function createControlledMultiSelect() {
    return createControlled()((MultiSelect), (props, state) => ({
        ...props,
        invalid: state.invalid,
        dataTestId: `select-${kebabCase(props.name)}`,
    }), { defaultWatch: true });
}
export const ControlledMultiSelect = createControlledMultiSelect();
export const ControlledMultiSelectNumber = createControlledMultiSelect();
